import { getLanguageFromPath } from '../config/routes';

export const getCanonicalUrl = (path: string): string => {
  const language = getLanguageFromPath(path);
  const baseUrl = 'https://www.brasizecalculatorpro.com';
  
  // For non-English languages, add the language prefix
  if (language !== 'en') {
    return `${baseUrl}/${language}${path}`;
  }
  
  // For English, use the path as is
  return `${baseUrl}${path}`;
}; 