import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import type { BreastShapeTranslations } from '../types/shapeTypes';
import { useTranslation } from 'react-i18next';
import { getCanonicalUrl } from '../utils/seo';

const useTranslatedData = () => {
  const { t, ready } = useTranslation('breastShape', { useSuspense: false });

  // Cast t to a function that accepts a string key
  const translate = t as unknown as (key: string) => any;

  const getTranslation = <T extends keyof BreastShapeTranslations>(key: T): BreastShapeTranslations[T] => {
    if (!ready) {
      return {} as BreastShapeTranslations[T];
    }
    return translate(key);
  };

  return {
    ready,
    metaData: getTranslation('meta'),
    pageTitle: getTranslation('pageTitle'),
    pageDescription: getTranslation('pageDescription'),
    tabs: getTranslation('tabs'),
    shapes: getTranslation('shapes'),
    identification: getTranslation('identification'),
    timeline: getTranslation('timeline'),
    fittingTips: getTranslation('fittingTips')
  };
};

const getImagePath = (shapeId: string) => {
  const shapeMap: { [key: string]: string } = {
    round: 'rounded',
    teardrop: 'tear-drop',
    eastwest: 'east-west',
    athletic: 'athletic',
    bellshape: 'bell',
    closeset: 'close-set',
    relaxed: 'relaxed',
    sideset: 'side-set',
    asymmetrical: 'asymmetrical',
    fundamental: 'fundamental'
  };
  const imagePath = `/images/${shapeMap[shapeId] || shapeId}-breast-shape.webp`;
  return imagePath;
};

const ImageWithFallback: React.FC<{ src: string; alt: string; className: string }> = ({ src, alt, className }) => {
  const [error, setError] = useState(false);

  if (error) {
    return (
      <div className={`${className} bg-purple-50 flex items-center justify-center`}>
        <span className="text-purple-400 text-4xl">🖼️</span>
      </div>
    );
  }

  return (
    <img
      src={src}
      alt={alt}
      className={className}
      loading="lazy"
      onError={() => setError(true)}
    />
  );
};

export default function BreastShapeGuide() {
  const location = useLocation();
  const [expandedSection, setExpandedSection] = useState<string>('');
  const [activeTab, setActiveTab] = useState<keyof BreastShapeTranslations['tabs']>('shapes');
  const [openShapes, setOpenShapes] = useState<string[]>([]);
  const [activeStep, setActiveStep] = useState(1);
  const {
    ready,
    metaData,
    pageTitle,
    pageDescription,
    tabs,
    shapes,
    identification,
    timeline,
    fittingTips
  } = useTranslatedData();

  if (!ready) {
    return <div>Loading...</div>;
  }

  const toggleShape = (id: string) => {
    if (openShapes.includes(id)) {
      setOpenShapes(openShapes.filter((shape) => shape !== id));
    } else {
      setOpenShapes([...openShapes, id]);
    }
  };

  const renderShapes = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="space-y-8"
    >
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
        {Object.entries(shapes).map(([shapeId, shape]) => (
                <div
            key={shapeId}
                  className="bg-white rounded-xl shadow overflow-hidden transition-all duration-300 hover:shadow-md"
                >
                  <div className="p-6">
                      <div className="mb-4 flex justify-center">
                        <div className="w-48 h-48 overflow-hidden rounded-lg">
                          <ImageWithFallback
                            src={getImagePath(shapeId)}
                            alt={`${shape.title} breast shape illustration`}
                            className="w-full h-full object-contain bg-white"
                          />
                        </div>
                      </div>
                    <div className="border-b border-gray-100 pb-4">
                      <button
                  onClick={() => toggleShape(shapeId)}
                        className="w-full flex justify-between items-center"
                      >
                  <h3 className="text-xl font-semibold text-purple-900">
                    {shape.title}
                  </h3>
                  {openShapes.includes(shapeId) ? (
                          <ChevronUp className="w-5 h-5 text-purple-500" />
                        ) : (
                          <ChevronDown className="w-5 h-5 text-purple-500" />
                        )}
                      </button>
                      
                <p className="text-gray-600 mt-3 leading-relaxed">
                  {shape.description}
                </p>
                    </div>

              {openShapes.includes(shapeId) && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="mt-4 space-y-6"
                      >
                        <div className="bg-purple-50 rounded-lg p-4">
                    <h4 className="font-medium text-purple-900 mb-3">Impact</h4>
                    <p className="text-gray-600 leading-relaxed">
                      {shape.impact}
                    </p>
                        </div>

                        <div>
                          <h4 className="font-medium text-xl text-purple-900 mb-3">Fit Considerations</h4>
                          <ul className="space-y-2">
                      {shape.fitConsiderations.map((consideration, index) => (
                        <li key={index} className="flex text-gray-600">
                                <span className="text-purple-500 mr-2">•</span>
                                <span>{consideration}</span>
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div>
                          <h4 className="font-medium text-xl text-purple-900 mb-3">Sizing Tips</h4>
                          <ul className="space-y-2">
                      {shape.sizingTips.map((tip, index) => (
                        <li key={index} className="flex text-gray-600">
                                <span className="text-purple-500 mr-2">•</span>
                                <span>{tip}</span>
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div className="bg-purple-50 rounded-lg p-4">
                          <h4 className="font-medium text-xl text-purple-900 mb-3">Common Issues</h4>
                    {shape.fitIssues.commonProblems.map((problem, index) => (
                            <div key={index} className="mb-4 last:mb-0">
                              <p className="font-medium text-purple-800 mb-2">{problem.issue}</p>
                              <div className="pl-4 border-l-2 border-purple-200">
                                <p className="text-gray-600 leading-relaxed mb-2">{problem.explanation}</p>
                                <p className="text-gray-600 leading-relaxed">{problem.solutions}</p>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div>
                          <h4 className="font-medium text-xl text-purple-900 mb-3">Preventative Measures</h4>
                          <ul className="space-y-2">
                      {shape.fitIssues.preventativeMeasures.map((measure, index) => (
                        <li key={index} className="flex text-gray-600">
                                <span className="text-purple-500 mr-2">•</span>
                                <span>{measure}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </motion.div>
                    )}
                  </div>
                </div>
              ))}
            </div>

      {/* Identification Guide Section */}
            <div className="max-w-4xl mx-auto mb-12">
              <div className="bg-white rounded-xl shadow-lg p-8">
          <h2 className="text-2xl font-semibold text-purple-900 mb-6">
            {identification.title}
          </h2>
                <p className="text-gray-600 mb-8">
            {identification.description}
                </p>

                {/* Step Navigation Buttons */}
                <div className="flex justify-center gap-4 mb-8">
            {identification.steps.map((step) => (
                    <button
                      key={step.step}
                      onClick={() => setActiveStep(step.step)}
                      className={`
                        flex-1 max-w-[200px] p-4 rounded-lg transition-all duration-300
                        ${activeStep === step.step 
                          ? 'bg-purple-600 text-white shadow-lg transform scale-105' 
                          : 'bg-purple-50 text-purple-900 hover:bg-purple-100'}
                      `}
                    >
                <div className="text-lg font-semibold mb-1">
                  Step {step.step}
                </div>
                      <div className={`text-sm ${activeStep === step.step ? 'text-purple-100' : 'text-purple-700'}`}>
                        {step.title}
                      </div>
                    </button>
                  ))}
                </div>

                {/* Active Step Content */}
          {identification.steps.map((step) => (
                  <motion.div
                    key={step.step}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ 
                      opacity: activeStep === step.step ? 1 : 0,
                      y: activeStep === step.step ? 0 : 20,
                      display: activeStep === step.step ? 'block' : 'none'
                    }}
                    transition={{ duration: 0.3 }}
                  >
                    <div className="bg-purple-50 rounded-lg p-6">
                      <div className="flex items-center mb-4">
                        <div className="w-12 h-12 bg-purple-600 rounded-full flex items-center justify-center text-white font-bold text-xl mr-4">
                          {step.step}
                        </div>
                        <div>
                          <h3 className="text-xl font-semibold text-purple-900">{step.title}</h3>
                          <p className="text-purple-700">{step.description}</p>
                        </div>
                      </div>

                <p className="text-gray-600 mb-6">{step.mainText}</p>

                      <div className="grid md:grid-cols-2 gap-6">
                        <div>
                    <h4 className="font-semibold text-purple-900 mb-3">Key Points</h4>
                          <ul className="space-y-2">
                      {step.keyPoints.map((point, idx) => (
                              <li key={idx} className="flex items-start space-x-2">
                                <div className="w-6 h-6 rounded-full bg-purple-200 flex-shrink-0 flex items-center justify-center text-purple-700 font-medium mt-0.5">
                                  {idx + 1}
                                </div>
                                <span className="text-gray-600">{point}</span>
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div>
                          <h4 className="font-semibold text-purple-900 mb-3">What to Look For</h4>
                          <ul className="space-y-2">
                      {step.whatToLookFor.map((item, idx) => (
                              <li key={idx} className="flex items-center text-gray-600">
                                <span className="text-purple-500 mr-2">•</span>
                                <span>{item}</span>
                              </li>
                            ))}
                          </ul>

                          <div className="mt-6">
                            <h4 className="font-semibold text-purple-900 mb-3">Pro Tips</h4>
                            <ul className="space-y-2">
                        {step.tips.map((tip, idx) => (
                                <li key={idx} className="flex items-center text-gray-600">
                                  <span className="text-yellow-500 mr-2">💡</span>
                                  <span>{tip}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                ))}

                {/* Navigation Arrows */}
                <div className="flex justify-between mt-6">
                  <button
                    onClick={() => setActiveStep(prev => Math.max(1, prev - 1))}
                    className={`px-4 py-2 rounded-lg transition-all duration-300 ${
                      activeStep === 1 ? 'invisible' : 'bg-purple-100 text-purple-700 hover:bg-purple-200'
                    }`}
                  >
              Previous Step
                  </button>
                  <button
                    onClick={() => setActiveStep(prev => Math.min(3, prev + 1))}
                    className={`px-4 py-2 rounded-lg transition-all duration-300 ${
                      activeStep === 3 ? 'invisible' : 'bg-purple-100 text-purple-700 hover:bg-purple-200'
                    }`}
                  >
              Next Step
                  </button>
                </div>
              </div>
            </div>

      {/* Timeline Section */}
            <div className="max-w-4xl mx-auto mb-12">
              <div className="bg-white rounded-xl shadow-lg p-8 mb-8">
          <h2 className="text-2xl font-semibold text-purple-900 mb-6">
            {timeline.title}
          </h2>
                <p className="text-gray-600 mb-8">
            {timeline.description}
                </p>
                
                <div className="relative">
                  {/* Timeline line */}
                  <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-0.5 bg-purple-200"></div>
                  
                  {/* Timeline items */}
            {timeline.stages.map((item, index) => (
                    <div key={item.stage} className={`relative flex items-center mb-8 ${
                      index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'
                    }`}>
                      {/* Content */}
                      <motion.div 
                        initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                        className={`w-5/12 ${index % 2 === 0 ? 'pr-8' : 'pl-8'}`}
                      >
                        <div className="bg-purple-50 rounded-lg p-6 hover:shadow-md transition-shadow duration-300">
                          <div className="text-2xl mb-2">{item.icon}</div>
                          <h3 className={`text-xl font-semibold text-purple-900 mb-2 ${index % 2 === 0 ? 'text-right' : 'text-left'}`}>{item.title}</h3>
                          <p className={`text-gray-600 mb-4 ${index % 2 === 0 ? 'text-right' : 'text-left'}`}>{item.description}</p>
                          <ul className="space-y-2">
                            {item.changes.map((change, idx) => (
                              <li key={idx} className="flex text-gray-600 items-start">
                                <span className="text-purple-500 mr-2 flex-shrink-0">•</span>
                                <span className="text-left">{change}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </motion.div>
                      
                      {/* Timeline point */}
                      <div className="absolute left-1/2 transform -translate-x-1/2 w-4 h-4 bg-purple-500 rounded-full">
                        <div className="absolute w-8 h-8 bg-purple-200 rounded-full -m-2 animate-ping"></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
    </motion.div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <link rel="canonical" href={getCanonicalUrl(location.pathname)} />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
            {pageTitle}
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            {pageDescription}
          </p>
        </motion.div>

        {/* Tab Navigation */}
        <div className="mb-8">
          <div className="border-b border-gray-200">
            <nav className="flex justify-center" aria-label="Tabs">
              <div className="flex w-full max-w-2xl">
                {(Object.keys(tabs) as Array<keyof typeof tabs>).map((tabId) => (
                  <button
                    key={tabId}
                    onClick={() => setActiveTab(tabId)}
                    className={`
                      flex-1 py-3 px-2 border-b-2 font-medium text-sm transition-colors duration-200
                      text-center leading-tight min-h-[56px] flex items-center justify-center
                      ${activeTab === tabId
                        ? 'border-purple-600 text-purple-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                      }
                    `}
                  >
                    <span className="block px-1">{tabs[tabId]}</span>
                  </button>
                ))}
              </div>
            </nav>
          </div>
        </div>

        {/* Content */}
        {activeTab === 'shapes' && renderShapes()}
        {activeTab === 'tips' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="space-y-8 max-w-7xl mx-auto"
          >
            {/* General Guidelines */}
            <div className="bg-white rounded-xl shadow-lg p-8">
              <h2 className="text-2xl font-semibold text-purple-900 mb-4">
                {fittingTips.generalGuidelines.title}
              </h2>
              <p className="text-gray-600 mb-6">
                {fittingTips.generalGuidelines.description}
              </p>
              <ul className="space-y-3">
                {fittingTips.generalGuidelines.guidelines.map((guideline, index) => (
                  <li key={index} className="flex items-center text-gray-600">
                    <span className="text-purple-500 mr-2">•</span>
                    <span>{guideline}</span>
                  </li>
                ))}
              </ul>
            </div>

            {/* Shape Effects */}
            <div className="bg-white rounded-xl shadow-lg p-8">
              <h2 className="text-2xl font-semibold text-purple-900 mb-6">
                {fittingTips.shapeEffects.title}
              </h2>
              <div className="grid md:grid-cols-2 gap-6">
                {fittingTips.shapeEffects.sections.map((section, index) => (
                  <div key={index} className="bg-purple-50 rounded-lg p-6">
                    <h3 className="text-xl font-semibold text-purple-900 mb-3">
                      {section.title}
                    </h3>
                    <p className="text-gray-600">
                      {section.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            {/* Shape-Specific Considerations */}
            <div className="bg-white rounded-xl shadow-lg p-8">
              <h2 className="text-2xl font-semibold text-purple-900 mb-4">
                {fittingTips.shapeSpecific.title}
              </h2>
              <p className="text-gray-600 mb-6">
                {fittingTips.shapeSpecific.description}
              </p>
              <ul className="space-y-3 mb-8">
                {fittingTips.shapeSpecific.considerations.map((consideration, index) => (
                  <li key={index} className="flex items-center text-gray-600">
                    <span className="text-purple-500 mr-2">•</span>
                    <span>{consideration}</span>
                  </li>
                ))}
              </ul>

              {/* Gaping Solutions */}
              <div className="mb-8">
                <h3 className="text-xl font-semibold text-purple-900 mb-4">
                  {fittingTips.shapeSpecific.solutions.gaping.title}
                </h3>
                <div className="grid md:grid-cols-2 gap-4">
                  {fittingTips.shapeSpecific.solutions.gaping.types.map((type, index) => (
                    <div key={index} className="bg-purple-50 rounded-lg p-4">
                      <h4 className="font-medium text-purple-900 mb-2">{type.shape}</h4>
                      <p className="text-gray-600">{type.solution}</p>
                    </div>
                  ))}
                </div>
              </div>

              {/* Spillage Solutions */}
              <div>
                <h3 className="text-xl font-semibold text-purple-900 mb-4">
                  {fittingTips.shapeSpecific.solutions.spillage.title}
                </h3>
                <div className="grid md:grid-cols-2 gap-4">
                  {fittingTips.shapeSpecific.solutions.spillage.types.map((type, index) => (
                    <div key={index} className="bg-purple-50 rounded-lg p-4">
                      <h4 className="font-medium text-purple-900 mb-2">{type.shape}</h4>
                      <p className="text-gray-600">{type.solution}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
}
