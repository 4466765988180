import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getCanonicalUrl } from '../utils/seo';
import {
  bandSizes,
  cupSizes,
  convertBraSize,
  SizingSystem,
  getSystemDisplayName
} from '../data/sizingConverterData';

// Define the translation interface
interface SizeConverterTranslations {
  meta: {
    title: string;
    description: string;
  };
  hero: {
    title: string;
    subtitle: string;
  };
  form: {
    sizeSystem: {
      label: string;
      placeholder: string;
      options: Record<string, string>;
    };
    bandAndCup: {
      label: string;
      bandSize: {
        placeholder: string;
      };
      cupSize: {
        placeholder: string;
      };
    };
    results: {
      title: string;
      emptyState: {
        title: string;
        description: string;
      };
    };
  };
  about: {
    title: string;
    description: string;
  };
  considerations: {
    title: string;
    items: Array<{ text: string }>;
  };
  howItWorks: {
    title: string;
    brandVariations: {
      title: string;
      types: {
        standard: { label: string; description: string };
        fullCup: { label: string; description: string };
        sports: { label: string; description: string };
      };
    };
    bandConversion: {
      title: string;
      conversions: {
        usToUk: { label: string; description: string; example: string };
        usToAuNz: { label: string; description: string; example: string };
        usToIt: { label: string; description: string; example: string };
      };
    };
    cupConversion: {
      title: string;
      description: string;
      chart: {
        headers: Record<string, string>;
        rows: Array<Record<string, string>>;
      };
    };
  };
  tips: {
    title: string;
    items: Array<{ title: string; description: string }>;
  };
  sizeCharts: {
    title: string;
    bandSizeChart: {
      title: string;
      headers: Record<string, string>;
      rows: Array<Record<string, string>>;
    };
    cupSizeChart: {
      title: string;
      headers: Record<string, string>;
      rows: Array<Record<string, string>>;
    };
  };
  accuracy: {
    title: string;
    description: string;
  };
}

interface ConversionResult {
  system: SizingSystem;
  bandSize: number | null;
  cupSize: string | null;
}

const useTranslatedData = () => {
  const { t, ready } = useTranslation('sizeConverter', { useSuspense: false });

  // Cast t to a function that accepts a string key
  const translate = t as unknown as (key: string) => any;

  const getTranslation = <T extends keyof SizeConverterTranslations>(key: T): SizeConverterTranslations[T] => {
    const translation = translate(key);
    if (!translation && !ready) {
      return {} as SizeConverterTranslations[T];
    }
    return translation as SizeConverterTranslations[T];
  };

  return {
    metaData: getTranslation('meta'),
    heroData: getTranslation('hero'),
    formData: getTranslation('form'),
    aboutData: getTranslation('about'),
    considerationsData: getTranslation('considerations'),
    howItWorksData: getTranslation('howItWorks'),
    tipsData: getTranslation('tips'),
    sizeChartsData: getTranslation('sizeCharts'),
    accuracyData: getTranslation('accuracy'),
  };
};

const NewConverterPage: React.FC = () => {
  const location = useLocation();
  const {
    metaData,
    heroData,
    formData,
    aboutData,
    considerationsData,
    howItWorksData,
    tipsData,
    sizeChartsData,
    accuracyData
  } = useTranslatedData();

  const [selectedSystem, setSelectedSystem] = useState<SizingSystem>('US');
  const [bandSize, setBandSize] = useState<string>('');
  const [cupSize, setCupSize] = useState<string>('');
  const [conversions, setConversions] = useState<ConversionResult[]>([]);

  // Available sizing systems
  const sizingSystems: SizingSystem[] = ['US', 'UK', 'EU', 'FR_ES', 'IT', 'JP', 'AU_NZ'];

  // Update conversions when input changes
  useEffect(() => {
    if (bandSize && cupSize) {
      const results: ConversionResult[] = sizingSystems
        .filter(system => system !== selectedSystem)
        .map(system => {
          const result = convertBraSize(Number(bandSize), cupSize, selectedSystem, system);
          return {
            system,
            bandSize: result.bandSize,
            cupSize: result.cupSize
          };
        });
      setConversions(results);
    } else {
      setConversions([]);
    }
  }, [selectedSystem, bandSize, cupSize]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <link rel="canonical" href={getCanonicalUrl(location.pathname)} />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
            {heroData.title}
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            {heroData.subtitle}
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 gap-8">
          {/* Input Section - Left Side */}
          <div>
            <div className="space-y-6">
              <div>
                <label className="block text-gray-700 text-sm font-medium mb-2">
                  {formData.sizeSystem.label}
                </label>
                <select
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  value={selectedSystem}
                  onChange={(e) => setSelectedSystem(e.target.value as SizingSystem)}
                >
                  <option value="">{formData.sizeSystem.placeholder}</option>
                  {sizingSystems.map((system) => (
                    <option key={system} value={system}>
                      {formData.sizeSystem.options[system]}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-gray-700 text-sm font-medium mb-2">
                  {formData.bandAndCup.label}
                </label>
                <div className="grid grid-cols-2 gap-4">
                  <select
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    value={bandSize}
                    onChange={(e) => setBandSize(e.target.value)}
                  >
                    <option value="">{formData.bandAndCup.bandSize.placeholder}</option>
                    {bandSizes[selectedSystem].map((size) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                  <select
                    className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    value={cupSize}
                    onChange={(e) => setCupSize(e.target.value)}
                  >
                    <option value="">{formData.bandAndCup.cupSize.placeholder}</option>
                    {cupSizes[selectedSystem].map((size) => (
                      <option key={size} value={size}>
                        {size}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          {/* Output Section - Right Side */}
          <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-6">
            <h3 className="font-semibold mb-4 text-lg">{formData.results.title}</h3>
            {selectedSystem && bandSize && cupSize ? (
              <div className="grid grid-cols-2 gap-4">
                {conversions.map((conversion) => (
                  <div key={conversion.system} className="bg-white rounded-lg p-4 shadow-sm">
                    <div className="text-gray-800 mb-1">{getSystemDisplayName(conversion.system)}</div>
                    <div className="text-xl text-purple-600 font-bold">
                      {conversion.bandSize && conversion.cupSize ? `${conversion.bandSize}${conversion.cupSize}` : '-'}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center text-gray-500 py-12">
                <svg className="w-12 h-12 mx-auto mb-4 text-gray-400" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
                </svg>
                <p>{formData.results.emptyState.title}</p>
                <p className="text-sm mt-2">{formData.results.emptyState.description}</p>
              </div>
            )}
          </div>
        </div>

        {/* Information Section */}
        <div className="mt-12 bg-white rounded-xl shadow-lg overflow-hidden">
          <div className="bg-white p-8">
            <h2 className="text-2xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
              {aboutData.title}
            </h2>
          </div>

          <div className="p-8">
            <div className="max-w-3xl mx-auto">
              <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-xl p-6 mb-6">
                <div className="flex items-start">
                  <div className="w-12 h-12 bg-white rounded-full flex items-center justify-center mr-4 mt-1 flex-shrink-0">
                    <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <div>
                    <p className="text-gray-700 text-s mb-4">
                      {aboutData.description}
                    </p>
                  </div>
                </div>
              </div>

              <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-xl p-6">
                <h3 className="text-lg font-semibold text-purple-800 mb-4">{considerationsData.title}</h3>
                <div className="grid gap-4">
                  {considerationsData.items.map((item: { text: string }, index: number) => (
                    <div key={index} className="flex items-center bg-white/80 rounded-lg p-4">
                      <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center mr-4 flex-shrink-0">
                        <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={getConsiderationIcon(index)} />
                        </svg>
                      </div>
                      <p className="text-gray-700">{item.text}</p>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-6 bg-gradient-to-br from-purple-50 to-pink-50 rounded-xl p-6">
                <div className="flex items-center">
                  <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center mr-4 flex-shrink-0">
                    <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                    </svg>
                  </div>
                  <p className="text-gray-700">
                    {accuracyData.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* How It Works Section */}
        <div className="space-y-8 mt-12">
          {/* Main Explanation */}
          <div className="bg-white rounded-xl shadow-lg p-8">
            <h2 className="text-2xl font-bold mb-6 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
              {howItWorksData.title}
            </h2>
            
            <div className="grid md:grid-cols-2 gap-6">
              <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-6">
                <div className="flex items-center mb-4">
                  <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center mr-4">
                    <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <h3 className="text-lg font-semibold">{howItWorksData.brandVariations.title}</h3>
                </div>
                <div className="space-y-4">
                  {Object.entries(howItWorksData.brandVariations.types).map(([key, value]: [string, any]) => (
                    <div key={key} className="bg-white/80 rounded-lg p-4">
                      <div className="flex items-center">
                        <span className="text-purple-500 font-medium w-36">{value.label}</span>
                        <span className="text-gray-300 mx-6">|</span>
                        <span className="ml-2">{value.description}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-6">
                <div className="flex items-center mb-4">
                  <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center mr-4">
                    <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                    </svg>
                  </div>
                  <h3 className="text-lg font-semibold">{howItWorksData.bandConversion.title}</h3>
                </div>
                <div className="space-y-4">
                  {Object.entries(howItWorksData.bandConversion.conversions).map(([key, value]: [string, any]) => (
                    <div key={key} className="bg-white/80 rounded-lg p-4">
                      <div className="flex items-center">
                        <span className="text-purple-600 font-medium w-24">{value.label}</span>
                        <span className="text-gray-400 mx-4">|</span>
                        <span>{value.description} <span className="text-gray-500 ml-2">{value.example}</span></span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="mt-6 bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-6">
              <div className="flex items-center mb-4">
                <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center mr-4">
                  <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <h3 className="text-lg font-semibold">{howItWorksData.cupConversion.title}</h3>
              </div>
              <p className="text-sm text-gray-500 mb-4">
                {howItWorksData.cupConversion.description}
              </p>
              <div className="overflow-x-auto bg-white/80 rounded-lg">
                <table className="w-full text-sm">
                  <thead>
                    <tr className="border-b border-purple-100">
                      {Object.values(howItWorksData.cupConversion.chart.headers).map((header, index) => (
                        <th key={index} className="p-3 text-left font-medium text-purple-900">
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {howItWorksData.cupConversion.chart.rows.map((row: any, index: number) => (
                      <tr key={index} className="border-b">
                        {Object.values(row).map((value: any, cellIndex: number) => (
                          <td key={cellIndex} className="p-3">
                            {value}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="mt-6 bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-6">
              <div className="flex items-center mb-4">
                <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center mr-4">
                  <svg className="w-6 h-6 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
                <h3 className="text-lg font-semibold">{tipsData.title}</h3>
              </div>
              <div className="grid md:grid-cols-2 gap-4 text-sm">
                <ul className="space-y-2">
                  {tipsData.items.slice(0, 2).map((tip, index) => (
                    <li key={index} className="flex items-center">
                      <span className="w-2 h-2 bg-purple-400 rounded-full mr-2"></span>
                      {tip.title}
                    </li>
                  ))}
                </ul>
                <ul className="space-y-2">
                  {tipsData.items.slice(2, 4).map((tip, index) => (
                    <li key={index} className="flex items-center">
                      <span className="w-2 h-2 bg-purple-400 rounded-full mr-2"></span>
                      {tip.title}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* Detailed Size Charts */}
          <div className="bg-white rounded-xl shadow-lg p-8">
            <h2 className="text-2xl font-bold mb-2 text-center bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
              {sizeChartsData.title}
            </h2>
            <div className="max-w-4xl mx-auto p-6">
              {/* Band Size Chart */}
              <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-6 mb-8">
                <h3 className="text-lg font-semibold mb-4">{sizeChartsData.bandSizeChart.title}</h3>
                <div className="overflow-x-auto">
                  <table className="w-full text-sm">
                    <thead className="bg-white">
                      <tr>
                        {Object.values(sizeChartsData.bandSizeChart.headers).map((header, index) => (
                          <th key={index} className="p-2 text-left font-semibold border-b">
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {sizeChartsData.bandSizeChart.rows.map((row: any, index: number) => (
                        <tr key={index} className="border-b">
                          {Object.values(row).map((value: any, cellIndex: number) => (
                            <td key={cellIndex} className="p-2">
                              {value}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Cup Size Chart */}
              <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-6">
                <h3 className="text-lg font-semibold mb-4">{sizeChartsData.cupSizeChart.title}</h3>
                <div className="overflow-x-auto">
                  <table className="w-full text-sm">
                    <thead className="bg-white">
                      <tr>
                        {Object.values(sizeChartsData.cupSizeChart.headers).map((header, index) => (
                          <th key={index} className="p-2 text-left font-semibold border-b">
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {sizeChartsData.cupSizeChart.rows.map((row: any, index: number) => (
                        <tr key={index} className="border-b">
                          {Object.values(row).map((value: any, cellIndex: number) => (
                            <td key={cellIndex} className="p-2">
                              {value}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Helper function to get consideration icons
const getConsiderationIcon = (index: number): string => {
  const icons = [
    "M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2",
    "M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z",
    "M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122",
    "M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
  ];
  return icons[index] || icons[0];
};

export default NewConverterPage;