import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useTranslations } from '../hooks/useTranslations';
import { getCanonicalUrl } from '../utils/seo';
import type { LegalTranslations } from '../types/legalTypes';

const CookiePolicy: React.FC = () => {
  const location = useLocation();
  const { translateObject, ready, i18n } = useTranslations('legal');

  // Force load legal namespace
  React.useEffect(() => {
    if (!i18n.hasLoadedNamespace('legal')) {
      i18n.loadNamespaces(['legal']).catch((error: Error) => {
        console.error('Error loading legal namespace:', error);
      });
    }
  }, [i18n]);

  const defaultMeta: LegalTranslations['cookiePolicy']['meta'] = {
    title: '',
    description: ''
  };

  const defaultSections: LegalTranslations['cookiePolicy']['sections'] = {
    introduction: { title: '', content: '' },
    whatAreCookies: { title: '', content: '' },
    howWeUseCookies: { title: '', content: '' },
    typesOfCookies: { 
      title: '', 
      content: '',
      types: [
        { name: '', description: '' }
      ]
    },
    managingCookies: { 
      title: '', 
      content: '',
      browsers: [
        { name: '', url: '' }
      ]
    },
    thirdPartyCookies: { title: '', content: '' },
    updates: { title: '', content: '' }

  };

  const defaultCommon: LegalTranslations['common'] = {
    lastUpdated: '',
    tableOfContents: '',
    contactUs: {
      text: '',
      linkText: ''
    }
  };

  const metaData = translateObject('cookiePolicy.meta', defaultMeta) || defaultMeta;
  const sections = translateObject('cookiePolicy.sections', defaultSections) || defaultSections;
  const common = translateObject('common', defaultCommon) || defaultCommon;

  if (!ready) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-pulse text-purple-600 text-xl">Loading translations...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <Helmet>
        <title>{metaData?.title || 'Cookie Policy'}</title>
        <meta name="description" content={metaData?.description || ''} />
        <link rel="canonical" href={getCanonicalUrl(location.pathname)} />
      </Helmet>

      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white rounded-2xl shadow-sm p-8">
            <h1 className="text-3xl font-bold text-gray-900 mb-8">
              {String(sections?.title || 'Cookie Policy')}
            </h1>

          <div className="prose prose-purple max-w-none">
            {/* Table of Contents */}
            <div className="mb-8 p-4 bg-gray-50 rounded-lg">
              <h2 className="text-xl font-semibold mb-4">{common?.tableOfContents}</h2>
              <ul className="space-y-2">
                <li><a href="#introduction" className="text-purple-600 hover:text-purple-800">{sections?.introduction?.title}</a></li>
                <li><a href="#what-are-cookies" className="text-purple-600 hover:text-purple-800">{sections?.whatAreCookies?.title}</a></li>
                <li><a href="#types-of-cookies" className="text-purple-600 hover:text-purple-800">{sections?.typesOfCookies?.title}</a></li>
                <li><a href="#managing-cookies" className="text-purple-600 hover:text-purple-800">{sections?.managingCookies?.title}</a></li>
                <li><a href="#third-party-cookies" className="text-purple-600 hover:text-purple-800">{sections?.thirdPartyCookies?.title}</a></li>
                <li><a href="#updates" className="text-purple-600 hover:text-purple-800">{sections?.updates?.title}</a></li>
              </ul>
            </div>

            {/* Introduction */}
            <section id="introduction" className="mb-8">
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">{sections?.introduction?.title || ''}</h2>
              <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mb-4">
                <p className="text-blue-700">{sections?.introduction?.content || ''}</p>
              </div>
            </section>

            {/* What Are Cookies */}
            <section id="what-are-cookies" className="mb-8">
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">{sections?.whatAreCookies?.title || ''}</h2>
              <p className="text-gray-600 mb-4">{sections?.whatAreCookies?.content || ''}</p>
            </section>

            {/* Types of Cookies */}
            <section id="types-of-cookies" className="mb-8">
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">{sections?.typesOfCookies?.title || ''}</h2>
              <p className="text-gray-600 mb-4">{sections?.typesOfCookies?.content || ''}</p>
              {sections?.typesOfCookies?.types && sections?.typesOfCookies?.types?.length > 0 && (
                <div className="bg-gray-50 p-4 rounded-lg">
                  <ul className="space-y-4">
                    {sections?.typesOfCookies?.types?.map((type, index) => (
                      <li key={index} className="text-gray-700">
                        <strong className="text-gray-900">{type?.name || ''}:</strong> {type?.description || ''}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </section>

            {/* Managing Cookies */}
            <section id="managing-cookies" className="mb-8">
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">{sections?.managingCookies?.title || ''}</h2>
              <p className="text-gray-600 mb-4">{sections?.managingCookies?.content || ''}</p>
              {sections?.managingCookies?.browsers && sections?.managingCookies?.browsers?.length > 0 && (
                <div className="bg-gray-50 p-4 rounded-lg">
                  <ul className="space-y-3">
                    {sections?.managingCookies?.browsers?.map((browser, index) => (
                      <li key={index}>
                        <a 
                          href={browser?.url || '#'}
                          className="text-purple-600 hover:text-purple-800 flex items-center"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="mr-2">→</span>
                          {browser?.name || ''}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </section>

            {/* Third Party Cookies */}
            <section id="third-party-cookies" className="mb-8">
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">{sections?.thirdPartyCookies?.title || ''}</h2>
              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                <p className="text-yellow-700">{sections?.thirdPartyCookies?.content || ''}</p>
              </div>
            </section>

            {/* Updates */}
            <section id="updates" className="mb-8">
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">{sections?.updates?.title || ''}</h2>
              <p className="text-gray-600">{sections?.updates?.content || ''}</p>
            </section>

            {/* Footer */}
            <div className="mt-8 pt-8 border-t border-gray-200">
              <p className="text-sm text-gray-500">{common?.lastUpdated || ''}</p>
              <div className="mt-4">
                <p className="text-gray-600">
                  {common?.contactUs?.text}{' '}
                  <a href="/contact" className="text-purple-600 hover:text-purple-800">
                    {common?.contactUs?.linkText}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
