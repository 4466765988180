import React, { Suspense, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { useTranslations } from './hooks/useTranslations';
import { getLanguageFromPath, getLocalizedPath, SupportedLanguage } from './config/routes';
import { getCanonicalUrl } from './utils/seo';

// Pages
import Home from './pages/Home';
import NewBraSizeCalculator from './components/NewBraSizeCalculator';
import NewConverterPage from './pages/NewConverterPage';
import NewSisterSizes from './pages/NewSisterSizes';
import SizeCharts from './pages/SizeCharts';
import MeasuringGuide from './pages/MeasuringGuide';
import BraFittingGuide from './pages/BraFittingGuide';
import BreastShapeGuide from './pages/BreastShapeGuide';
import BraTypesGuide from './pages/BraTypesGuide';
import BraFitIssuesGuide from './pages/BraFitIssuesGuide';
import FAQ from './pages/FAQ';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import CookiePolicy from './pages/CookiePolicy';
import NotFound from './pages/NotFound';
import AboutUs from './pages/AboutUs';

// Components
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

function App() {
  const location = useLocation();
  const { i18n } = useTranslations('common');
  const navigate = useNavigate();
  
  useEffect(() => {
    const initializeLanguage = async () => {
      try {
        const pathLanguage = getLanguageFromPath(location.pathname);
        const browserLang = navigator.language.split('-')[0];
        const browserLanguage = browserLang as SupportedLanguage;
        const storedLanguage = localStorage.getItem('i18nextLng') as SupportedLanguage;
        
        // Priority: URL path > stored language > browser language > default (en)
        const targetLanguage = pathLanguage || storedLanguage || ((['it', 'es', 'fr', 'de', 'pl', 'nl', 'ja', 'sv', 'tr'].includes(browserLanguage)) ? browserLanguage : 'en');
        
        if (targetLanguage !== i18n.language) {
          // Load all necessary namespaces including legal
          await i18n.loadNamespaces(['common', 'routes', 'legal']);
          await i18n.changeLanguage(targetLanguage);
          
          document.documentElement.lang = targetLanguage;
          localStorage.setItem('i18nextLng', targetLanguage);
          
          const currentPath = location.pathname;
          const expectedPath = getLocalizedPath(currentPath, targetLanguage);
          
          if (currentPath !== expectedPath && expectedPath !== '/') {
            navigate(expectedPath, { replace: true });
          }
        }
      } catch (error) {
        console.error('Error initializing language:', error);
        if (i18n.language !== 'en') {
          await i18n.changeLanguage('en');
          document.documentElement.lang = 'en';
          localStorage.setItem('i18nextLng', 'en');
        }
      }
    };

    initializeLanguage();
  }, [location.pathname, i18n]);

  const renderWithLanguage = (Component: React.ComponentType) => (
    <Suspense fallback={
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-pulse text-purple-600 text-xl">Loading...</div>
      </div>
    }>
      <Component />
    </Suspense>
  );


  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <ScrollToTop />
      <main className="flex-grow">
        <Routes>
          {/* Home routes */}
          <Route path="/" element={renderWithLanguage(Home)} />
          <Route path="/ru" element={renderWithLanguage(Home)} />
          <Route path="/it" element={renderWithLanguage(Home)} />
          <Route path="/es" element={renderWithLanguage(Home)} />
          <Route path="/fr" element={renderWithLanguage(Home)} />
          <Route path="/de" element={renderWithLanguage(Home)} />
          <Route path="/pl" element={renderWithLanguage(Home)} />
          <Route path="/nl" element={renderWithLanguage(Home)} />
          <Route path="/ja" element={renderWithLanguage(Home)} />
          <Route path="/sv" element={renderWithLanguage(Home)} />
          <Route path="/tr" element={renderWithLanguage(Home)} />

                {/* Redirect calculator to home */}
                <Route path="/bra-size-calculator" element={<Navigate to="/" replace />} />
                <Route path="/ru/kalkulyator-razmera-byustgaltera" element={<Navigate to="/ru" replace />} />
                <Route path="/it/calcola-taglia-reggiseno" element={<Navigate to="/it" replace />} />


          <Route path="/es/calculadora-talla-sujetador" element={<Navigate to="/es" replace />} />
          <Route path="/fr/calculateur-taille-soutien-gorge" element={<Navigate to="/fr" replace />} />
          <Route path="/de/bh-groessenrechner" element={<Navigate to="/de" replace />} />
          <Route path="/pl/kalkulator-rozmiaru-biustonosza" element={<Navigate to="/pl" replace />} />
          <Route path="/nl/bh-maat-berekenen" element={<Navigate to="/nl" replace />} />
          <Route path="/ja/bura-saizu-keisan" element={<Navigate to="/ja" replace />} />
          <Route path="/sv/rakna-ut-bh-storlek" element={<Navigate to="/sv" replace />} />
          <Route path="/tr/sutyen-bedeni-hesaplama" element={<Navigate to="/tr" replace />} />

          {/* English routes */}
          <Route path="/bra-size-charts" element={renderWithLanguage(SizeCharts)} />
          <Route path="/bra-size-converter" element={renderWithLanguage(NewConverterPage)} />
          <Route path="/bra-sister-sizes" element={renderWithLanguage(NewSisterSizes)} />
          <Route path="/bra-size-measuring-guide" element={renderWithLanguage(MeasuringGuide)} />
          <Route path="/bra-fitting-guide" element={renderWithLanguage(BraFittingGuide)} />
          <Route path="/breast-shape-guide" element={renderWithLanguage(BreastShapeGuide)} />
          <Route path="/bra-types-guide" element={renderWithLanguage(BraTypesGuide)} />
          <Route path="/bra-fit-issues" element={renderWithLanguage(BraFitIssuesGuide)} />
          <Route path="/faq" element={renderWithLanguage(FAQ)} />
          <Route path="/contact" element={renderWithLanguage(Contact)} />
          <Route path="/privacy-policy" element={renderWithLanguage(PrivacyPolicy)} />
          <Route path="/terms-of-service" element={renderWithLanguage(TermsOfService)} />
          <Route path="/cookie-policy" element={renderWithLanguage(CookiePolicy)} />

          {/* Italian routes */}
          <Route path="/it/tabella-taglie-reggiseno" element={renderWithLanguage(SizeCharts)} />
          <Route path="/it/convertitore-taglia-reggiseno" element={renderWithLanguage(NewConverterPage)} />
          <Route path="/it/taglie-sorelle-reggiseno" element={renderWithLanguage(NewSisterSizes)} />
          <Route path="/it/guida-misure-reggiseno" element={renderWithLanguage(MeasuringGuide)} />
          <Route path="/it/guida-vestibilita-reggiseno" element={renderWithLanguage(BraFittingGuide)} />
          <Route path="/it/guida-forme-seno" element={renderWithLanguage(BreastShapeGuide)} />
          <Route path="/it/tipi-reggiseno" element={renderWithLanguage(BraTypesGuide)} />
          <Route path="/it/problemi-vestibilita-reggiseno" element={renderWithLanguage(BraFitIssuesGuide)} />
          <Route path="/it/domande-frequenti" element={renderWithLanguage(FAQ)} />
          <Route path="/it/contatti" element={renderWithLanguage(Contact)} />
          <Route path="/it/informativa-privacy" element={renderWithLanguage(PrivacyPolicy)} />
          <Route path="/it/termini-servizio" element={renderWithLanguage(TermsOfService)} />
          <Route path="/it/politica-cookie" element={renderWithLanguage(CookiePolicy)} />

          {/* Spanish routes */}
          <Route path="/es/tablas-tallas-sujetador" element={renderWithLanguage(SizeCharts)} />
          <Route path="/es/convertidor-talla-sujetador" element={renderWithLanguage(NewConverterPage)} />
          <Route path="/es/tallas-hermanas-sujetador" element={renderWithLanguage(NewSisterSizes)} />
          <Route path="/es/guia-medicion-talla-sujetador" element={renderWithLanguage(MeasuringGuide)} />
          <Route path="/es/guia-ajuste-sujetador" element={renderWithLanguage(BraFittingGuide)} />
          <Route path="/es/guia-formas-pecho" element={renderWithLanguage(BreastShapeGuide)} />
          <Route path="/es/guia-tipos-sujetador" element={renderWithLanguage(BraTypesGuide)} />
          <Route path="/es/problemas-ajuste-sujetador" element={renderWithLanguage(BraFitIssuesGuide)} />
          <Route path="/es/preguntas-frecuentes" element={renderWithLanguage(FAQ)} />
          <Route path="/es/contacto" element={renderWithLanguage(Contact)} />
          <Route path="/es/politica-privacidad" element={renderWithLanguage(PrivacyPolicy)} />
          <Route path="/es/terminos-servicio" element={renderWithLanguage(TermsOfService)} />
          <Route path="/es/politica-cookies" element={renderWithLanguage(CookiePolicy)} />

          {/* French routes */}
          <Route path="/fr/tableaux-tailles-soutien-gorge" element={renderWithLanguage(SizeCharts)} />
          <Route path="/fr/convertisseur-taille-soutien-gorge" element={renderWithLanguage(NewConverterPage)} />
          <Route path="/fr/tailles-soeurs-soutien-gorge" element={renderWithLanguage(NewSisterSizes)} />
          <Route path="/fr/guide-mesures-soutien-gorge" element={renderWithLanguage(MeasuringGuide)} />
          <Route path="/fr/guide-ajustement-soutien-gorge" element={renderWithLanguage(BraFittingGuide)} />
          <Route path="/fr/guide-formes-poitrine" element={renderWithLanguage(BreastShapeGuide)} />
          <Route path="/fr/guide-types-soutien-gorge" element={renderWithLanguage(BraTypesGuide)} />
          <Route path="/fr/problemes-ajustement-soutien-gorge" element={renderWithLanguage(BraFitIssuesGuide)} />
          <Route path="/fr/faq" element={renderWithLanguage(FAQ)} />
          <Route path="/fr/contact" element={renderWithLanguage(Contact)} />
          <Route path="/fr/politique-confidentialite" element={renderWithLanguage(PrivacyPolicy)} />
          <Route path="/fr/conditions-utilisation" element={renderWithLanguage(TermsOfService)} />
          <Route path="/fr/politique-cookies" element={renderWithLanguage(CookiePolicy)} />

          {/* German routes */}
          <Route path="/de/bh-groessentabellen" element={renderWithLanguage(SizeCharts)} />
          <Route path="/de/bh-groessen-umrechner" element={renderWithLanguage(NewConverterPage)} />
          <Route path="/de/schwester-bh-groessen" element={renderWithLanguage(NewSisterSizes)} />
          <Route path="/de/bh-messanleitung" element={renderWithLanguage(MeasuringGuide)} />
          <Route path="/de/bh-anpassungsleitfaden" element={renderWithLanguage(BraFittingGuide)} />
          <Route path="/de/brustformen-leitfaden" element={renderWithLanguage(BreastShapeGuide)} />
          <Route path="/de/bh-typen" element={renderWithLanguage(BraTypesGuide)} />
          <Route path="/de/haeufige-bh-passformprobleme" element={renderWithLanguage(BraFitIssuesGuide)} />
          <Route path="/de/faq" element={renderWithLanguage(FAQ)} />
          <Route path="/de/kontakt" element={renderWithLanguage(Contact)} />
          <Route path="/de/datenschutzerklaerung" element={renderWithLanguage(PrivacyPolicy)} />
          <Route path="/de/nutzungsbedingungen" element={renderWithLanguage(TermsOfService)} />
          <Route path="/de/cookie-richtlinie" element={renderWithLanguage(CookiePolicy)} />

          {/* Polish routes */}
          <Route path="/pl/tabele-rozmiarow-biustonoszy" element={renderWithLanguage(SizeCharts)} />
          <Route path="/pl/przelicznik-rozmiaru-biustonosza" element={renderWithLanguage(NewConverterPage)} />
          <Route path="/pl/rozmiary-siostrzane-biustonoszy" element={renderWithLanguage(NewSisterSizes)} />
          <Route path="/pl/przewodnik-mierzenia-biustonosza" element={renderWithLanguage(MeasuringGuide)} />
          <Route path="/pl/przewodnik-dopasowania-biustonosza" element={renderWithLanguage(BraFittingGuide)} />
          <Route path="/pl/przewodnik-ksztalt-biustu" element={renderWithLanguage(BreastShapeGuide)} />
          <Route path="/pl/rodzaje-biustonoszy" element={renderWithLanguage(BraTypesGuide)} />
          <Route path="/pl/problemy-dopasowania-biustonosza" element={renderWithLanguage(BraFitIssuesGuide)} />
          <Route path="/pl/faq" element={renderWithLanguage(FAQ)} />
          <Route path="/pl/kontakt" element={renderWithLanguage(Contact)} />
          <Route path="/pl/polityka-prywatnosci" element={renderWithLanguage(PrivacyPolicy)} />
          <Route path="/pl/warunki-korzystania" element={renderWithLanguage(TermsOfService)} />
          <Route path="/pl/polityka-cookies" element={renderWithLanguage(CookiePolicy)} />

          {/* Dutch routes */}
          <Route path="/nl/bh-maattabellen" element={renderWithLanguage(SizeCharts)} />
          <Route path="/nl/bh-maat-omrekenen" element={renderWithLanguage(NewConverterPage)} />
          <Route path="/nl/bh-zustermaten" element={renderWithLanguage(NewSisterSizes)} />
          <Route path="/nl/bh-meetgids" element={renderWithLanguage(MeasuringGuide)} />
          <Route path="/nl/bh-pasvormgids" element={renderWithLanguage(BraFittingGuide)} />
          <Route path="/nl/bh-borst-vorm-gids" element={renderWithLanguage(BreastShapeGuide)} />
          <Route path="/nl/bh-types" element={renderWithLanguage(BraTypesGuide)} />
          <Route path="/nl/bh-pasvorm-problemen" element={renderWithLanguage(BraFitIssuesGuide)} />
          <Route path="/nl/faq" element={renderWithLanguage(FAQ)} />
          <Route path="/nl/contact" element={renderWithLanguage(Contact)} />
          <Route path="/nl/privacybeleid" element={renderWithLanguage(PrivacyPolicy)} />
          <Route path="/nl/gebruiksvoorwaarden" element={renderWithLanguage(TermsOfService)} />
          <Route path="/nl/cookiebeleid" element={renderWithLanguage(CookiePolicy)} />

          {/* Japanese routes */}
          <Route path="/ja/bura-saizu-hyo" element={renderWithLanguage(SizeCharts)} />
          <Route path="/ja/bura-saizu-henkan" element={renderWithLanguage(NewConverterPage)} />
          <Route path="/ja/bura-shimai-saizu" element={renderWithLanguage(NewSisterSizes)} />
          <Route path="/ja/bura-saizu-hakari-kata" element={renderWithLanguage(MeasuringGuide)} />
          <Route path="/ja/bura-saizu-fitting" element={renderWithLanguage(BraFittingGuide)} />
          <Route path="/ja/mune-katachi-guide" element={renderWithLanguage(BreastShapeGuide)} />
          <Route path="/ja/bura-shurui-ichiran" element={renderWithLanguage(BraTypesGuide)} />
          <Route path="/ja/bura-fitting-mondai" element={renderWithLanguage(BraFitIssuesGuide)} />
          <Route path="/ja/yokuaru-shitsumon" element={renderWithLanguage(FAQ)} />
          <Route path="/ja/otoiawase" element={renderWithLanguage(Contact)} />
          <Route path="/ja/puraibashi-porishi" element={renderWithLanguage(PrivacyPolicy)} />
          <Route path="/ja/riyou-kiyaku" element={renderWithLanguage(TermsOfService)} />
          <Route path="/ja/kukki-porishi" element={renderWithLanguage(CookiePolicy)} />

          {/* Swedish routes */}
          <Route path="/sv/bh-storlek-tabell" element={renderWithLanguage(SizeCharts)} />
          <Route path="/sv/bh-storlek-omvandlare" element={renderWithLanguage(NewConverterPage)} />
          <Route path="/sv/systerstorlekar-bh" element={renderWithLanguage(NewSisterSizes)} />
          <Route path="/sv/mata-bh-storlek" element={renderWithLanguage(MeasuringGuide)} />
          <Route path="/sv/bh-storleksguide" element={renderWithLanguage(BraFittingGuide)} />
          <Route path="/sv/brostform-guide" element={renderWithLanguage(BreastShapeGuide)} />
          <Route path="/sv/bh-typer" element={renderWithLanguage(BraTypesGuide)} />
          <Route path="/sv/bh-passform" element={renderWithLanguage(BraFitIssuesGuide)} />
          <Route path="/sv/vanliga-fragor" element={renderWithLanguage(FAQ)} />
          <Route path="/sv/kontakt" element={renderWithLanguage(Contact)} />
                <Route path="/sv/integritetspolicy" element={renderWithLanguage(PrivacyPolicy)} />
                <Route path="/sv/anvandarvillkor" element={renderWithLanguage(TermsOfService)} />
                <Route path="/sv/cookie-policy" element={renderWithLanguage(CookiePolicy)} />

                {/* Turkish routes */}
                <Route path="/tr/sutyen-bedenleri-donusturucu" element={renderWithLanguage(NewConverterPage)} />
              <Route path="/tr/sutyen-bedenleri-kardes-olculer" element={renderWithLanguage(NewSisterSizes)} />
              <Route path="/tr/sutyen-olculeri-nasil-alinir" element={renderWithLanguage(MeasuringGuide)} />
              <Route path="/tr/sutyen-beden-tablosu" element={renderWithLanguage(SizeCharts)} />
              <Route path="/tr/sutyen-olculeri-rehberi" element={renderWithLanguage(BraFittingGuide)} />
              <Route path="/tr/gogus-sekilleri-rehberi" element={renderWithLanguage(BreastShapeGuide)} />
              <Route path="/tr/sutyen-cesitleri" element={renderWithLanguage(BraTypesGuide)} />
              <Route path="/tr/sutyen-olculeri-sorunlari" element={renderWithLanguage(BraFitIssuesGuide)} />
              <Route path="/tr/sikca-sorulan-sorular" element={renderWithLanguage(FAQ)} />
              <Route path="/tr/iletisim" element={renderWithLanguage(Contact)} />
              <Route path="/tr/gizlilik-politikasi" element={renderWithLanguage(PrivacyPolicy)} />
              <Route path="/tr/kullanim-kosullari" element={renderWithLanguage(TermsOfService)} />
              <Route path="/tr/cerez-politikasi" element={renderWithLanguage(CookiePolicy)} />

            {/* Russian routes */}
            <Route path="/ru/tablica-razmerov-byustgaltera" element={renderWithLanguage(SizeCharts)} />

            <Route path="/ru/kalkulyator-razmera-byustgaltera" element={renderWithLanguage(NewConverterPage)} />
            <Route path="/ru/sestrinskiye-razmery-byustgaltera" element={renderWithLanguage(NewSisterSizes)} />
            <Route path="/ru/izmerit-razmer-grudi" element={renderWithLanguage(MeasuringGuide)} />
            <Route path="/ru/opredelit-razmer-byustgaltera" element={renderWithLanguage(BraFittingGuide)} />
            <Route path="/ru/formy-grudi" element={renderWithLanguage(BreastShapeGuide)} />
            <Route path="/ru/tipy-byustgalterov" element={renderWithLanguage(BraTypesGuide)} />
            <Route path="/ru/problemy-posadki-byustgaltera" element={renderWithLanguage(BraFitIssuesGuide)} />
            <Route path="/ru/voprosy-i-otvety" element={renderWithLanguage(FAQ)} />
            <Route path="/ru/kontakty" element={renderWithLanguage(Contact)} />
            <Route path="/ru/politika-konfidencialnosti" element={renderWithLanguage(PrivacyPolicy)} />
            <Route path="/ru/usloviya-ispolzovaniya" element={renderWithLanguage(TermsOfService)} />
            <Route path="/ru/politika-cookies" element={renderWithLanguage(CookiePolicy)} />

            {/* About Us routes */}

          <Route path="/about-us" element={renderWithLanguage(AboutUs)} />
          <Route path="/ru/o-nas" element={renderWithLanguage(AboutUs)} />
          <Route path="/es/sobre-nosotros" element={renderWithLanguage(AboutUs)} />
          <Route path="/fr/a-propos" element={renderWithLanguage(AboutUs)} />
          <Route path="/de/ueber-uns" element={renderWithLanguage(AboutUs)} />
          <Route path="/pl/o-nas" element={renderWithLanguage(AboutUs)} />
          <Route path="/nl/over-ons" element={renderWithLanguage(AboutUs)} />
          <Route path="/ja/watashitachi-ni-tsuite" element={renderWithLanguage(AboutUs)} />
          <Route path="/it/chi-siamo" element={renderWithLanguage(AboutUs)} />
          <Route path="/sv/om-oss" element={renderWithLanguage(AboutUs)} />
          <Route path="/tr/hakkimizda" element={renderWithLanguage(AboutUs)} />

          {/* 404 route */}
          <Route path="*" element={renderWithLanguage(NotFound)} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
