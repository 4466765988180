import i18next from 'i18next';

interface RouteConfig {
  en: string;
  es: string;
  fr: string;
  de: string;
  pl: string;
  nl: string;
  ja: string;
  it: string;
  sv: string;
  tr: string;
  ru: string;
}

interface LegalRouteConfig {
  privacy: RouteConfig;
  terms: RouteConfig;
  cookies: RouteConfig;
}

interface Routes {
  home: RouteConfig;
  sizeConverter: RouteConfig;
  sizeCharts: RouteConfig;
  sisterSizes: RouteConfig;
  measuringGuide: RouteConfig;
  fittingGuide: RouteConfig;
  breastShapes: RouteConfig;
  braTypes: RouteConfig;
  fitIssues: RouteConfig;
  faq: RouteConfig;
  contact: RouteConfig;
  legal: LegalRouteConfig;
  about: RouteConfig;
}

export type SupportedLanguage = 'en' | 'es' | 'fr' | 'de' | 'pl' | 'nl' | 'ja' | 'it' | 'sv' | 'tr' | 'ru';

export const routes: Routes = {
  home: {
    en: '/',
    es: '/es',
    fr: '/fr',
    de: '/de',
    pl: '/pl',
    nl: '/nl',
    ja: '/ja',
    it: '/it',
    sv: '/sv',
    tr: '/tr',
    ru: '/ru'
  },
  sizeConverter: {
    en: '/bra-size-converter',
    es: '/es/convertidor-talla-sujetador',
    fr: '/fr/convertisseur-taille-soutien-gorge',
    de: '/de/bh-groessen-umrechner',
    pl: '/pl/przelicznik-rozmiaru-biustonosza',
    nl: '/nl/bh-maat-omrekenen',
    ja: '/ja/bura-saizu-henkan',
    it: '/it/convertitore-taglia-reggiseno',
    sv: '/sv/bh-storlek-omvandlare',
    tr: '/tr/sutyen-bedenleri-donusturucu',
    ru: '/ru/kalkulyator-razmera-byustgaltera'
  },
  sizeCharts: {
    en: '/bra-size-charts',
    es: '/es/tablas-tallas-sujetador',
    fr: '/fr/tableaux-tailles-soutien-gorge',
    de: '/de/bh-groessentabellen',
    pl: '/pl/tabele-rozmiarow-biustonoszy',
    nl: '/nl/bh-maattabellen',
    ja: '/ja/bura-saizu-hyo',
    it: '/it/tabella-taglie-reggiseno',
    sv: '/sv/bh-storlek-tabell',
    tr: '/tr/sutyen-beden-tablosu',
    ru: '/ru/tablica-razmerov-byustgaltera'
  },
  sisterSizes: {
    en: '/bra-sister-sizes',
    es: '/es/tallas-hermanas-sujetador',
    fr: '/fr/tailles-soeurs-soutien-gorge',
    de: '/de/schwester-bh-groessen',
    pl: '/pl/rozmiary-siostrzane-biustonoszy',
    nl: '/nl/bh-zustermaten',
    ja: '/ja/bura-shimai-saizu',
    it: '/it/taglie-sorelle-reggiseno',
    sv: '/sv/systerstorlekar-bh',
    tr: '/tr/sutyen-bedenleri-kardes-olculer',
    ru: '/ru/sestrinskiye-razmery-byustgaltera'
  },
  measuringGuide: {
    en: '/bra-size-measuring-guide',
    es: '/es/guia-medicion-talla-sujetador',
    fr: '/fr/guide-mesures-soutien-gorge',
    de: '/de/bh-messanleitung',
    pl: '/pl/przewodnik-mierzenia-biustonosza',
    nl: '/nl/bh-meetgids',
    ja: '/ja/bura-saizu-hakari-kata',
    it: '/it/guida-misure-reggiseno',
    sv: '/sv/mata-bh-storlek',
    tr: '/tr/sutyen-olculeri-nasil-alinir',
    ru: '/ru/izmerit-razmer-grudi'
  },
  fittingGuide: {
    en: '/bra-fitting-guide',
    es: '/es/guia-ajuste-sujetador',
    fr: '/fr/guide-ajustement-soutien-gorge',
    de: '/de/bh-anpassungsleitfaden',
    pl: '/pl/przewodnik-dopasowania-biustonosza',
    nl: '/nl/bh-pasvormgids',
    ja: '/ja/bura-saizu-fitting',
    it: '/it/guida-vestibilita-reggiseno',
    sv: '/sv/bh-storleksguide',
    tr: '/tr/sutyen-olculeri-rehberi',
    ru: '/ru/opredelit-razmer-byustgaltera'
  },
  breastShapes: {
    en: '/breast-shape-guide',
    es: '/es/guia-formas-pecho',
    fr: '/fr/guide-formes-poitrine',
    de: '/de/brustformen-leitfaden',
    pl: '/pl/przewodnik-ksztalt-biustu',
    nl: '/nl/bh-borst-vorm-gids',
    ja: '/ja/mune-katachi-guide',
    it: '/it/guida-forme-seno',
    sv: '/sv/brostform-guide',
    tr: '/tr/gogus-sekilleri-rehberi',
    ru: '/ru/formy-grudi'
  },
  braTypes: {
    en: '/bra-types-guide',
    es: '/es/guia-tipos-sujetador',
    fr: '/fr/guide-types-soutien-gorge',
    de: '/de/bh-typen',
    pl: '/pl/rodzaje-biustonoszy',
    nl: '/nl/bh-types',
    ja: '/ja/bura-shurui-ichiran',
    it: '/it/tipi-reggiseno',
    sv: '/sv/bh-typer',
    tr: '/tr/sutyen-cesitleri',
    ru: '/ru/tipy-byustgalterov'
  },
  fitIssues: {
    en: '/bra-fit-issues',
    es: '/es/problemas-ajuste-sujetador',
    fr: '/fr/problemes-ajustement-soutien-gorge',
    de: '/de/haeufige-bh-passformprobleme',
    pl: '/pl/problemy-dopasowania-biustonosza',
    nl: '/nl/bh-pasvorm-problemen',
    ja: '/ja/bura-fitting-mondai',
    it: '/it/problemi-vestibilita-reggiseno',
    sv: '/sv/bh-passform',
    tr: '/tr/sutyen-olculeri-sorunlari',
    ru: '/ru/problemy-posadki-byustgaltera'
  },
  faq: {
    en: '/faq',
    es: '/es/preguntas-frecuentes',
    fr: '/fr/faq',
    de: '/de/faq',
    pl: '/pl/faq',
    nl: '/nl/faq',
    ja: '/ja/yokuaru-shitsumon',
    it: '/it/domande-frequenti',
    sv: '/sv/vanliga-fragor',
    tr: '/tr/sikca-sorulan-sorular',
    ru: '/ru/voprosy-i-otvety'
  },
  contact: {
    en: '/contact',
    es: '/es/contacto',
    fr: '/fr/contact',
    de: '/de/kontakt',
    pl: '/pl/kontakt',
    nl: '/nl/contact',
    ja: '/ja/otoiawase',
    it: '/it/contatti',
    sv: '/sv/kontakt',
    tr: '/tr/iletisim',
    ru: '/ru/kontakty'
  },
  legal: {
    privacy: {
      en: '/privacy-policy',
      es: '/es/politica-privacidad',
      fr: '/fr/politique-confidentialite',
      de: '/de/datenschutzerklaerung',
      pl: '/pl/polityka-prywatnosci',
      nl: '/nl/privacybeleid',
      ja: '/ja/puraibashi-porishi',
      it: '/it/informativa-privacy',
      sv: '/sv/integritetspolicy',
      tr: '/tr/gizlilik-politikasi',
      ru: '/ru/politika-konfidencialnosti'
    },
    terms: {
      en: '/terms-of-service',
      es: '/es/terminos-servicio',
      fr: '/fr/conditions-utilisation',
      de: '/de/nutzungsbedingungen',
      pl: '/pl/warunki-korzystania',
      nl: '/nl/gebruiksvoorwaarden',
      ja: '/ja/riyou-kiyaku',
      it: '/it/termini-servizio',
      sv: '/sv/anvandarvillkor',
      tr: '/tr/kullanim-kosullari',
      ru: '/ru/usloviya-ispolzovaniya'
    },
    cookies: {
      en: '/cookie-policy',
      es: '/es/politica-cookies',
      fr: '/fr/politique-cookies',
      de: '/de/cookie-richtlinie',
      pl: '/pl/polityka-cookies',
      nl: '/nl/cookiebeleid',
      ja: '/ja/kukki-porishi',
      it: '/it/politica-cookie',
      sv: '/sv/cookie-policy',
      tr: '/tr/cerez-politikasi',
      ru: '/ru/politika-cookies'
    }
  },
  about: {
    en: '/about-us',
    es: '/es/sobre-nosotros',
    fr: '/fr/a-propos',
    de: '/de/ueber-uns',
    pl: '/pl/o-nas',
    nl: '/nl/over-ons',
    ja: '/ja/watashitachi-ni-tsuite',
    it: '/it/chi-siamo',
    sv: '/sv/om-oss',
    tr: '/tr/hakkimizda',
    ru: '/ru/o-nas'
  }
};

export const getLocalizedPath = (path: string, language: SupportedLanguage): string => {
  console.log('Getting localized path for:', { path, language });

  // First try to find an exact match in the routes
  const route = Object.entries(routes).find(([_, config]) => {
    if (typeof config === 'object' && 'en' in config) {
      return Object.values(config).includes(path);
    }
    return false;
  });

  if (route) {
    console.log('Found route match:', route);
    const config = route[1] as RouteConfig;
    return config[language];
  }

  // Check legal routes
  const legalRoutes = Object.entries(routes.legal).find(([_, config]) => 
    Object.values(config).includes(path)
  );

  if (legalRoutes) {
    console.log('Found legal route match:', legalRoutes);
    return legalRoutes[1][language];
  }

  // If no match found in routes, handle the language prefix
  if (language === 'en') {
    // For English, remove any language prefix if present
    const withoutPrefix = path.replace(/^\/(es|fr|de|pl|nl|ja|it|sv|tr|ru)\//, '');
    console.log('English path without prefix:', withoutPrefix);
    return withoutPrefix || '/';
  } else {
    // For other languages, ensure the correct prefix
    const withoutPrefix = path.replace(/^\/(es|fr|de|pl|nl|ja|it|sv|tr|ru)\//, '');
    // If it's just the root path or empty, return the language prefix
    if (!withoutPrefix || withoutPrefix === '/') {
      console.log('Root path with language prefix:', `/${language}`);
      return `/${language}`;
    }
    // Otherwise, add the language prefix to the path
    const localizedPath = `/${language}/${withoutPrefix}`;
    console.log('Localized path:', localizedPath);
    return localizedPath;
  }
};

export const isValidPath = (path: string): boolean => {
  return Object.values(routes).some(config => 
    Object.values(config).includes(path)
  );
};

// Add a helper function to get language from path
export const getLanguageFromPath = (path: string): SupportedLanguage => {
  const pathSegments = path.split('/').filter(Boolean);
  const firstSegment = pathSegments[0];
  
  // Check if the first segment is a supported language code
  if (firstSegment && ['es', 'fr', 'de', 'pl', 'nl', 'ja', 'it', 'sv', 'tr', 'ru'].includes(firstSegment)) {
    return firstSegment as SupportedLanguage;
  }
  
  // If no language prefix is found or it's not supported, default to English
  return 'en';
};

export function getRoute(key: keyof Routes): string {
  const language = i18next.language || 'en';
  const route = routes[key];
  
  if (!route) return '/';

  // Handle legal routes
  if (key === 'legal') {
    const legalRoute = route as LegalRouteConfig;
    return legalRoute.cookies[language as keyof RouteConfig] || legalRoute.cookies.en;
  }

  // Handle regular routes
  const regularRoute = route as RouteConfig;
  return regularRoute[language as keyof RouteConfig] || regularRoute.en;
} 