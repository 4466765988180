// i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import type { InitOptions } from 'i18next';
import type { I18nConfig } from '../types/i18n-types';

// English translations
import enCommon from './locales/en/common.json';
import enHome from './locales/en/home.json';
import enSizeConverter from './locales/en/sizeConverter.json';
import enConverter from './locales/en/converter.json';
import enCalculator from './locales/en/calculator.json';
import enFitIssues from './locales/en/fitIssues.json';
import enBraFitting from './locales/en/braFitting.json';
import enMeasuringGuide from './locales/en/measuringGuide.json';
import enSisterSizes from './locales/en/sisterSizes.json';
import enSizeCharts from './locales/en/sizeCharts.json';
import enBraTypes from './locales/en/braTypes.json';
import enBreastShape from './locales/en/breastShape.json';
import enBraFitIssues from './locales/en/braFitIssues.json';
import enFaq from './locales/en/faq.json';
import enContact from './locales/en/contact.json';
import enLegal from './locales/en/legal.json';
import enRoutes from './locales/en/routes.json';
import enAboutUs from './locales/en/aboutUs.json';

// Spanish translations
import esCommon from './locales/es/common.json';
import esHome from './locales/es/home.json';
import esSizeConverter from './locales/es/sizeConverter.json';
import esConverter from './locales/es/converter.json';
import esCalculator from './locales/es/calculator.json';
import esFitIssues from './locales/es/fitIssues.json';
import esBraFitting from './locales/es/braFitting.json';
import esMeasuringGuide from './locales/es/measuringGuide.json';
import esSisterSizes from './locales/es/sisterSizes.json';
import esSizeCharts from './locales/es/sizeCharts.json';
import esBraTypes from './locales/es/braTypes.json';
import esBreastShape from './locales/es/breastShape.json';
import esBraFitIssues from './locales/es/braFitIssues.json';
import esFaq from './locales/es/faq.json';
import esContact from './locales/es/contact.json';
import esLegal from './locales/es/legal.json';
import esRoutes from './locales/es/routes.json';
import esAboutUs from './locales/es/aboutUs.json';

// Italian translations
import itCommon from './locales/it/common.json';
import itHome from './locales/it/home.json';
import itSizeConverter from './locales/it/sizeConverter.json';
import itConverter from './locales/it/converter.json';
import itCalculator from './locales/it/calculator.json';
import itFitIssues from './locales/it/fitIssues.json';
import itBraFitting from './locales/it/braFitting.json';
import itMeasuringGuide from './locales/it/measuringGuide.json';
import itSisterSizes from './locales/it/sisterSizes.json';
import itSizeCharts from './locales/it/sizeCharts.json';
import itBraTypes from './locales/it/braTypes.json';
import itBreastShape from './locales/it/breastShape.json';
import itBraFitIssues from './locales/it/braFitIssues.json';
import itFaq from './locales/it/faq.json';
import itContact from './locales/it/contact.json';
import itLegal from './locales/it/legal.json';
import itRoutes from './locales/it/routes.json';
import itAboutUs from './locales/it/aboutUs.json';

// French translations
import frCommon from './locales/fr/common.json';
import frHome from './locales/fr/home.json';
import frSizeConverter from './locales/fr/sizeConverter.json';
import frConverter from './locales/fr/converter.json';
import frCalculator from './locales/fr/calculator.json';
import frFitIssues from './locales/fr/fitIssues.json';
import frBraFitting from './locales/fr/braFitting.json';
import frMeasuringGuide from './locales/fr/measuringGuide.json';
import frSisterSizes from './locales/fr/sisterSizes.json';
import frSizeCharts from './locales/fr/sizeCharts.json';
import frBraTypes from './locales/fr/braTypes.json';
import frBreastShape from './locales/fr/breastShape.json';
import frBraFitIssues from './locales/fr/braFitIssues.json';
import frFaq from './locales/fr/faq.json';
import frContact from './locales/fr/contact.json';
import frLegal from './locales/fr/legal.json';
import frRoutes from './locales/fr/routes.json';
import frAboutUs from './locales/fr/aboutUs.json';

// German translations
import deCommon from './locales/de/common.json';
import deHome from './locales/de/home.json';
import deSizeConverter from './locales/de/sizeConverter.json';
import deConverter from './locales/de/converter.json';
import deCalculator from './locales/de/calculator.json';
import deFitIssues from './locales/de/fitIssues.json';
import deBraFitting from './locales/de/braFitting.json';
import deMeasuringGuide from './locales/de/measuringGuide.json';
import deSisterSizes from './locales/de/sisterSizes.json';
import deSizeCharts from './locales/de/sizeCharts.json';
import deBraTypes from './locales/de/braTypes.json';
import deBreastShape from './locales/de/breastShape.json';
import deBraFitIssues from './locales/de/braFitIssues.json';
import deFaq from './locales/de/faq.json';
import deContact from './locales/de/contact.json';
import deLegal from './locales/de/legal.json';
import deRoutes from './locales/de/routes.json';
import deAboutUs from './locales/de/aboutUs.json';

// Polish translations
import plCommon from './locales/pl/common.json';
import plHome from './locales/pl/home.json';
import plSizeConverter from './locales/pl/sizeConverter.json';
import plConverter from './locales/pl/converter.json';
import plCalculator from './locales/pl/calculator.json';
import plFitIssues from './locales/pl/fitIssues.json';
import plBraFitting from './locales/pl/braFitting.json';
import plMeasuringGuide from './locales/pl/measuringGuide.json';
import plSisterSizes from './locales/pl/sisterSizes.json';
import plSizeCharts from './locales/pl/sizeCharts.json';
import plBraTypes from './locales/pl/braTypes.json';
import plBreastShape from './locales/pl/breastShape.json';
import plBraFitIssues from './locales/pl/braFitIssues.json';
import plFaq from './locales/pl/faq.json';
import plContact from './locales/pl/contact.json';
import plLegal from './locales/pl/legal.json';
import plRoutes from './locales/pl/routes.json';
import plAboutUs from './locales/pl/aboutUs.json';

// Dutch translations
import nlCommon from './locales/nl/common.json';
import nlHome from './locales/nl/home.json';
import nlSizeConverter from './locales/nl/sizeConverter.json';
import nlConverter from './locales/nl/converter.json';
import nlCalculator from './locales/nl/calculator.json';
import nlFitIssues from './locales/nl/fitIssues.json';
import nlBraFitting from './locales/nl/braFitting.json';
import nlMeasuringGuide from './locales/nl/measuringGuide.json';
import nlSisterSizes from './locales/nl/sisterSizes.json';
import nlSizeCharts from './locales/nl/sizeCharts.json';
import nlBraTypes from './locales/nl/braTypes.json';
import nlBreastShape from './locales/nl/breastShape.json';
import nlBraFitIssues from './locales/nl/braFitIssues.json';
import nlFaq from './locales/nl/faq.json';
import nlContact from './locales/nl/contact.json';
import nlLegal from './locales/nl/legal.json';
import nlRoutes from './locales/nl/routes.json';
import nlAboutUs from './locales/nl/aboutUs.json';

// Japanese translations
import jaCommon from './locales/ja/common.json';
import jaHome from './locales/ja/home.json';
import jaSizeConverter from './locales/ja/sizeConverter.json';
import jaConverter from './locales/ja/converter.json';
import jaCalculator from './locales/ja/calculator.json';
import jaFitIssues from './locales/ja/fitIssues.json';
import jaBraFitting from './locales/ja/braFitting.json';
import jaMeasuringGuide from './locales/ja/measuringGuide.json';
import jaSisterSizes from './locales/ja/sisterSizes.json';
import jaSizeCharts from './locales/ja/sizeCharts.json';
import jaBraTypes from './locales/ja/braTypes.json';
import jaBreastShape from './locales/ja/breastShape.json';
import jaBraFitIssues from './locales/ja/braFitIssues.json';
import jaFaq from './locales/ja/faq.json';
import jaContact from './locales/ja/contact.json';
import jaLegal from './locales/ja/legal.json';
import jaRoutes from './locales/ja/routes.json';
import jaAboutUs from './locales/ja/aboutUs.json';

// Swedish translations
import svCommon from './locales/sv/common.json';
import svHome from './locales/sv/home.json';
import svSizeConverter from './locales/sv/sizeConverter.json';
import svConverter from './locales/sv/converter.json';
import svCalculator from './locales/sv/calculator.json';
import svFitIssues from './locales/sv/fitIssues.json';
import svBraFitting from './locales/sv/braFitting.json';
import svMeasuringGuide from './locales/sv/measuringGuide.json';
import svSisterSizes from './locales/sv/sisterSizes.json';
import svSizeCharts from './locales/sv/sizeCharts.json';
import svBraTypes from './locales/sv/braTypes.json';
import svBreastShape from './locales/sv/breastShape.json';
import svBraFitIssues from './locales/sv/braFitIssues.json';
import svFaq from './locales/sv/faq.json';
import svContact from './locales/sv/contact.json';
import svLegal from './locales/sv/legal.json';
import svRoutes from './locales/sv/routes.json';
import svAboutUs from './locales/sv/aboutUs.json';

// Turkish translations
import trCommon from './locales/tr/common.json';
import trHome from './locales/tr/home.json';
import trSizeConverter from './locales/tr/sizeConverter.json';
import trConverter from './locales/tr/converter.json';
import trCalculator from './locales/tr/calculator.json';
import trFitIssues from './locales/tr/fitIssues.json';
import trBraFitting from './locales/tr/braFitting.json';
import trMeasuringGuide from './locales/tr/measuringGuide.json';
import trSisterSizes from './locales/tr/sisterSizes.json';
import trSizeCharts from './locales/tr/sizeCharts.json';
import trBraTypes from './locales/tr/braTypes.json';
import trBreastShape from './locales/tr/breastShape.json';
import trBraFitIssues from './locales/tr/braFitIssues.json';
import trFaq from './locales/tr/faq.json';
import trContact from './locales/tr/contact.json';
import trLegal from './locales/tr/legal.json';
import trRoutes from './locales/tr/routes.json';
import trAboutUs from './locales/tr/aboutUs.json';

// Russian translations
import ruCommon from './locales/ru/common.json';
import ruHome from './locales/ru/home.json';
import ruSizeConverter from './locales/ru/sizeConverter.json';
import ruConverter from './locales/ru/converter.json';
import ruCalculator from './locales/ru/calculator.json';
import ruFitIssues from './locales/ru/fitIssues.json';
import ruBraFitting from './locales/ru/braFitting.json';
import ruMeasuringGuide from './locales/ru/measuringGuide.json';
import ruSisterSizes from './locales/ru/sisterSizes.json';
import ruSizeCharts from './locales/ru/sizeCharts.json';
import ruBraTypes from './locales/ru/braTypes.json';
import ruBreastShape from './locales/ru/breastShape.json';
import ruBraFitIssues from './locales/ru/braFitIssues.json';
import ruFaq from './locales/ru/faq.json';
import ruContact from './locales/ru/contact.json';
import ruLegal from './locales/ru/legal.json';
import ruRoutes from './locales/ru/routes.json';
import ruAboutUs from './locales/ru/aboutUs.json';

const resources = {
  en: {
    common: enCommon,
    home: enHome,
    sizeConverter: enSizeConverter,
    converter: enConverter,
    calculator: enCalculator,
    fitIssues: enFitIssues,
    braFitting: enBraFitting,
    measuringGuide: enMeasuringGuide,
    sisterSizes: enSisterSizes,
    sizeCharts: enSizeCharts,
    braTypes: enBraTypes,
    breastShape: enBreastShape,
    braFitIssues: enBraFitIssues,
    faq: enFaq,
    contact: enContact,
    legal: enLegal,
    routes: enRoutes,
    aboutUs: enAboutUs
  },
  es: {
    common: esCommon,
    home: esHome,
    sizeConverter: esSizeConverter,
    converter: esConverter,
    calculator: esCalculator,
    fitIssues: esFitIssues,
    braFitting: esBraFitting,
    measuringGuide: esMeasuringGuide,
    sisterSizes: esSisterSizes,
    sizeCharts: esSizeCharts,
    braTypes: esBraTypes,
    breastShape: esBreastShape,
    braFitIssues: esBraFitIssues,
    faq: esFaq,
    contact: esContact,
    legal: esLegal,
    routes: esRoutes,
    aboutUs: esAboutUs
  },
  it: {
    common: itCommon,
    home: itHome,
    sizeConverter: itSizeConverter,
    converter: itConverter,
    calculator: itCalculator,
    fitIssues: itFitIssues,
    braFitting: itBraFitting,
    measuringGuide: itMeasuringGuide,
    sisterSizes: itSisterSizes,
    sizeCharts: itSizeCharts,
    braTypes: itBraTypes,
    breastShape: itBreastShape,
    braFitIssues: itBraFitIssues,
    faq: itFaq,
    contact: itContact,
    legal: itLegal,
    routes: itRoutes,
    aboutUs: itAboutUs
  },
  fr: {
    common: frCommon,
    home: frHome,
    sizeConverter: frSizeConverter,
    converter: frConverter,
    calculator: frCalculator,
    fitIssues: frFitIssues,
    braFitting: frBraFitting,
    measuringGuide: frMeasuringGuide,
    sisterSizes: frSisterSizes,
    sizeCharts: frSizeCharts,
    braTypes: frBraTypes,
    breastShape: frBreastShape,
    braFitIssues: frBraFitIssues,
    faq: frFaq,
    contact: frContact,
    legal: frLegal,
    routes: frRoutes,
    aboutUs: frAboutUs
  },
  de: {
    common: deCommon,
    home: deHome,
    sizeConverter: deSizeConverter,
    converter: deConverter,
    calculator: deCalculator,
    fitIssues: deFitIssues,
    braFitting: deBraFitting,
    measuringGuide: deMeasuringGuide,
    sisterSizes: deSisterSizes,
    sizeCharts: deSizeCharts,
    braTypes: deBraTypes,
    breastShape: deBreastShape,
    braFitIssues: deBraFitIssues,
    faq: deFaq,
    contact: deContact,
    legal: deLegal,
    routes: deRoutes,
    aboutUs: deAboutUs
  },
  pl: {
    common: plCommon,
    home: plHome,
    sizeConverter: plSizeConverter,
    converter: plConverter,
    calculator: plCalculator,
    fitIssues: plFitIssues,
    braFitting: plBraFitting,
    measuringGuide: plMeasuringGuide,
    sisterSizes: plSisterSizes,
    sizeCharts: plSizeCharts,
    braTypes: plBraTypes,
    breastShape: plBreastShape,
    braFitIssues: plBraFitIssues,
    faq: plFaq,
    contact: plContact,
    legal: plLegal,
    routes: plRoutes,
    aboutUs: plAboutUs
  },
  nl: {
    common: nlCommon,
    home: nlHome,
    sizeConverter: nlSizeConverter,
    converter: nlConverter,
    calculator: nlCalculator,
    fitIssues: nlFitIssues,
    braFitting: nlBraFitting,
    measuringGuide: nlMeasuringGuide,
    sisterSizes: nlSisterSizes,
    sizeCharts: nlSizeCharts,
    braTypes: nlBraTypes,
    breastShape: nlBreastShape,
    braFitIssues: nlBraFitIssues,
    faq: nlFaq,
    contact: nlContact,
    legal: nlLegal,
    routes: nlRoutes,
    aboutUs: nlAboutUs
  },
  ja: {
    common: jaCommon,
    home: jaHome,
    sizeConverter: jaSizeConverter,
    converter: jaConverter,
    calculator: jaCalculator,
    fitIssues: jaFitIssues,
    braFitting: jaBraFitting,
    measuringGuide: jaMeasuringGuide,
    sisterSizes: jaSisterSizes,
    sizeCharts: jaSizeCharts,
    braTypes: jaBraTypes,
    breastShape: jaBreastShape,
    braFitIssues: jaBraFitIssues,
    faq: jaFaq,
    contact: jaContact,
    legal: jaLegal,
    routes: jaRoutes,
    aboutUs: jaAboutUs
  },
  sv: {
    common: svCommon,
    home: svHome,
    sizeConverter: svSizeConverter,
    converter: svConverter,
    calculator: svCalculator,
    fitIssues: svFitIssues,
    braFitting: svBraFitting,
    measuringGuide: svMeasuringGuide,
    sisterSizes: svSisterSizes,
    sizeCharts: svSizeCharts,
    braTypes: svBraTypes,
    breastShape: svBreastShape,
    braFitIssues: svBraFitIssues,
    faq: svFaq,
    contact: svContact,
    legal: svLegal,
    routes: svRoutes,
    aboutUs: svAboutUs
  },
  tr: {
    common: trCommon,
    home: trHome,
    sizeConverter: trSizeConverter,
    converter: trConverter,
    calculator: trCalculator,
    fitIssues: trFitIssues,
    braFitting: trBraFitting,
    measuringGuide: trMeasuringGuide,
    sisterSizes: trSisterSizes,
    sizeCharts: trSizeCharts,
    braTypes: trBraTypes,
    breastShape: trBreastShape,
    braFitIssues: trBraFitIssues,
    faq: trFaq,
    contact: trContact,
    legal: trLegal,
    routes: trRoutes,
    aboutUs: trAboutUs
  },
  ru: {
    common: ruCommon,
    home: ruHome,
    sizeConverter: ruSizeConverter,
    converter: ruConverter,
    calculator: ruCalculator,
    fitIssues: ruFitIssues,
    braFitting: ruBraFitting,
    measuringGuide: ruMeasuringGuide,
    sisterSizes: ruSisterSizes,
    sizeCharts: ruSizeCharts,
    braTypes: ruBraTypes,
    breastShape: ruBreastShape,
    braFitIssues: ruBraFitIssues,
    faq: ruFaq,
    contact: ruContact,
    legal: ruLegal,
    routes: ruRoutes,
    aboutUs: ruAboutUs
  }
};

const config: I18nConfig & InitOptions = {
  resources,
  fallbackLng: 'en',
  supportedLngs: ['en', 'es', 'it', 'fr', 'de', 'pl', 'nl', 'ja', 'sv', 'tr', 'ru'],
  ns: [
    'common',
    'legal',
    'home',
    'sizeConverter',
    'converter',
    'calculator',
    'fitIssues',
    'braFitting',
    'measuringGuide',
    'sisterSizes',
    'sizeCharts',
    'braTypes',
    'breastShape',
    'braFitIssues',
    'faq',
    'contact',
    'routes',
    'aboutUs'
  ],
  defaultNS: 'common',
  fallbackNS: 'common',
  interpolation: {
    escapeValue: false
  },
  detection: {
    order: ['path', 'localStorage', 'navigator'],
    lookupFromPathIndex: 0,
    caches: ['localStorage']
  },
  react: {
    useSuspense: false,
    bindI18n: 'languageChanged loaded',
    bindI18nStore: 'added removed',
    transEmptyNodeValue: '',
  },
  debug: process.env.NODE_ENV === 'development',
  load: 'currentOnly',
  preload: ['en', 'es', 'it', 'fr', 'de', 'pl', 'nl', 'ja', 'sv', 'tr', 'ru'],
  returnNull: false,
  returnEmptyString: false,
  returnObjects: true,
  saveMissing: true,
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(config);

export default i18n;