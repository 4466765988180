// useTranslations.ts
import { useTranslation } from 'react-i18next';
import type { UseTranslationResponse } from 'react-i18next';
import type { TranslationNamespace, TranslationOptions, TranslationResult } from '../types/i18n-types';

export const useTranslations = (namespace: TranslationNamespace): TranslationResult => {
  // Cast the hook result to avoid deep type instantiation
  // @ts-expect-error - Suppress type instantiation error
  const hookResult: UseTranslationResponse<TranslationNamespace> = useTranslation(namespace, {
    useSuspense: false,
    keyPrefix: undefined
  });

  const { t, i18n } = hookResult;
  const isReady = hookResult.ready && i18n.hasLoadedNamespace(namespace);

  const translate = (key: string, options?: TranslationOptions): string => {
    if (!isReady) {
      console.warn(`Translation not ready - ns: ${namespace}, key: ${key}`);
      return options?.defaultValue?.toString() || '';
    }

    try {
      const translatedValue = t(key, { defaultValue: '', ...(options || {}) }) as string;
      return translatedValue || options?.defaultValue?.toString() || '';
    } catch (error) {
      console.error(`Translation error for key "${key}" in namespace "${namespace}":`, error);
      return options?.defaultValue?.toString() || '';
    }
  };

  const translateObject = <T>(key: string, defaultValue: T): T => {
    if (!isReady) {
      return defaultValue;
    }

    try {
      const result = t(key, { returnObjects: true, defaultValue } as any) as T;
      return result || defaultValue;
    } catch (error) {
      console.error(`Translation error: ${error}`);
      return defaultValue;
    }
  };

  return {
    translate,
    translateObject,
    ready: isReady,
    i18n
  };
};