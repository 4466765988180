import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/card';
import { AlertCircle, CheckCircle2, ChevronRight, Ruler, Camera, ChevronDown } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import type braFittingTranslations from '../i18n/locales/en/braFitting.json';
import { getCanonicalUrl } from '../utils/seo';
import { motion } from 'framer-motion';

// Type your data using the JSON import
type BraFittingTranslations = typeof braFittingTranslations;

const useTranslatedData = () => {
  const { t, ready } = useTranslation('braFitting', { useSuspense: false });

  // Cast t to a function that accepts a string key
  const translate = t as (key: string) => any;

  const getTranslation = <T extends keyof BraFittingTranslations>(key: T): BraFittingTranslations[T] => {
    const translation = translate(key);
    if (!translation && !ready) {
      return {} as BraFittingTranslations[T];
    }
    return translation as BraFittingTranslations[T];
  };

  return {
    ready,
    metaData: getTranslation('meta'),
    heroData: getTranslation('hero'),
    quickFitCheckData: getTranslation('quickFitCheck'),
    measuringGuideData: getTranslation('measuringGuide'),
    guidelinesData: getTranslation('guidelines'),
    commonIssuesData: getTranslation('commonIssues'),
    professionalFittingData: getTranslation('professionalFitting')
  };
};

const BraFittingGuide: React.FC = () => {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState('');
  const [showMeasuringTips, setShowMeasuringTips] = useState(false);

  const {
    ready,
    metaData,
    heroData,
    quickFitCheckData,
    measuringGuideData,
    guidelinesData,
    commonIssuesData,
    professionalFittingData
  } = useTranslatedData();

  if (!ready || !metaData?.title || !heroData?.title) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-pulse text-purple-600 text-xl">Loading...</div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <meta name="keywords" content={metaData.keywords} />
        <link rel="canonical" href={getCanonicalUrl(location.pathname)} />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {/* Header Section */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center mb-12"
          >
            <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
              {heroData.title}
            </h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              {heroData.description}
            </p>
          </motion.div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-12">
            {/* Quick Fit Check Section - Now a sidebar */}
            <div className="lg:col-span-1">
              <Card className="sticky top-4 hover:shadow-lg transition-shadow">
                <CardHeader className="border-b">
                  <CardTitle className="flex items-center gap-2 text-lg">
                    <CheckCircle2 className="w-5 h-5 text-green-500" />
                    {quickFitCheckData.title}
                  </CardTitle>
                </CardHeader>
                <CardContent className="p-4">
                  <div className="space-y-4">
                    {quickFitCheckData.items.map((item, index) => (
                      <div key={index} className="bg-white rounded-lg p-4 border border-gray-100">
                        <div className="flex items-start gap-2 text-green-600 mb-2">
                          <CheckCircle2 className="w-4 h-4 mt-1 flex-shrink-0" />
                          <span className="font-medium text-sm">{item.good}</span>
                        </div>
                        <div className="flex items-start gap-2 text-red-600">
                          <AlertCircle className="w-4 h-4 mt-1 flex-shrink-0" />
                          <span className="text-sm">{item.bad}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </div>

            {/* Main Content Area */}
            <div className="lg:col-span-2 space-y-8">
              {/* Measuring Guide Section */}
              <Card className="hover:shadow-lg transition-shadow">
                <CardHeader 
                  className="cursor-pointer border-b"
                  onClick={() => setShowMeasuringTips(!showMeasuringTips)}
                >
                  <CardTitle className="flex items-center justify-between text-lg">
                    <div className="flex items-center gap-2">
                      <Camera className="w-5 h-5 text-blue-500" />
                      {measuringGuideData.title}
                    </div>
                    <ChevronDown 
                      className={`w-5 h-5 transition-transform ${showMeasuringTips ? 'rotate-180' : ''}`}
                    />
                  </CardTitle>
                </CardHeader>
                {showMeasuringTips && (
                  <CardContent className="p-6">
                    <div className="space-y-6">
                      <div className="border-l-4 border-blue-500 pl-4 py-2">
                        <h3 className="font-semibold mb-2 text-blue-700">{measuringGuideData.sections.bandSize.title}</h3>
                        <p className="text-gray-700">{measuringGuideData.sections.bandSize.description}</p>
                      </div>
                      <div className="border-l-4 border-pink-500 pl-4 py-2">
                        <h3 className="font-semibold mb-2 text-pink-700">{measuringGuideData.sections.cupSize.title}</h3>
                        <p className="text-gray-700">{measuringGuideData.sections.cupSize.description}</p>
                      </div>
                      <div className="mt-4 text-gray-600 text-right">
                        <Link to={measuringGuideData.links.detailedGuide.url} className="text-purple-600 hover:text-purple-800 inline-flex items-center">
                          <span className="leading-none">{measuringGuideData.links.detailedGuide.text}</span>
                          <ChevronRight className="w-4 h-4 ml-1 leading-none" />
                        </Link>
                      </div>
                    </div>
                  </CardContent>
                )}
              </Card>

              {/* Guidelines Sections */}
              <div className="space-y-4">
                {Object.entries(guidelinesData).map(([key, section]) => (
                  <Card key={key} className="hover:shadow-lg transition-shadow">
                    <CardHeader 
                      className={`cursor-pointer ${activeSection === key ? 'border-b' : ''}`}
                      onClick={() => setActiveSection(activeSection === key ? '' : key)}
                    >
                      <CardTitle className="flex items-center justify-between text-lg">
                        <div className="flex items-center gap-2">
                          <span className="text-xl">{section.icon}</span>
                          {section.title}
                        </div>
                        <ChevronDown 
                          className={`w-5 h-5 transition-transform ${activeSection === key ? 'rotate-180' : ''}`}
                        />
                      </CardTitle>
                    </CardHeader>
                    {activeSection === key && (
                      <CardContent className="p-6">
                        <ul className="space-y-3">
                          {section.items.map((item, index) => (
                            <li key={index} className="flex items-start gap-3 text-gray-700">
                              <ChevronRight className="w-4 h-4 mt-1 text-purple-500 flex-shrink-0" />
                              <span>{item}</span>
                            </li>
                          ))}
                        </ul>
                      </CardContent>
                    )}
                  </Card>
                ))}
              </div>

              {/* Common Issues Section */}
              <Card className="hover:shadow-lg transition-shadow">
                <CardHeader className="border-b">
                  <CardTitle className="flex items-center gap-2 text-lg">
                    <AlertCircle className="w-5 h-5 text-yellow-500" />
                    {commonIssuesData.title}
                  </CardTitle>
                </CardHeader>
                <CardContent className="p-6">
                  <div className="grid sm:grid-cols-2 gap-4">
                    {commonIssuesData.issues.map((issue, index) => (
                      <div key={index} className="bg-white rounded-lg p-4 border border-gray-100">
                        <h3 className="font-semibold mb-2 text-purple-700">{issue.title}</h3>
                        <p className="text-gray-700">{issue.solution}</p>
                      </div>
                    ))}
                  </div>
                  <div className="mt-4 text-gray-600 text-right">
                    <Link to={commonIssuesData.links.completeGuide.url} className="text-purple-600 hover:text-purple-800 inline-flex items-center">
                      <span className="leading-none">{commonIssuesData.links.completeGuide.text}</span>
                      <ChevronRight className="w-4 h-4 ml-1 leading-none" />
                    </Link>
                  </div>
                </CardContent>
              </Card>

              {/* When to Get Fitted Section */}
              <Card className="hover:shadow-lg transition-shadow">
                <CardHeader className="border-b">
                  <CardTitle className="flex items-center gap-2 text-lg">
                    <Ruler className="w-5 h-5 text-purple-500" />
                    {professionalFittingData.title}
                  </CardTitle>
                </CardHeader>
                <CardContent className="p-6">
                  <div className="grid sm:grid-cols-3 gap-4">
                    {professionalFittingData.occasions.map((item, index) => (
                      <div key={index} className="text-center p-4 border rounded-lg bg-white hover:border-purple-200 transition-colors">
                        <div className="text-2xl mb-2">{item.icon}</div>
                        <p className="text-gray-700">{item.timing}</p>
                      </div>
                    ))}
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BraFittingGuide;
