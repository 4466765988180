import { Measurements, SizeResult, SisterSize, InternationalSizes, SizeRecommendations } from '../types/braSizeTypes';
import fitIssues from '../data/fitIssues';
import { sizingData, SizingSystem } from '../data/sizingData';
import { generateConfidenceScore } from './confidenceCalculator';
import { FitIssue } from '../types/fitTypes';
import { convertSize, getLetterSize } from '../data/sizingSystems';

// Conversion utilities
export function cmToInches(cm: number): number {
  return cm / 2.54;
}

function calculateCupIndex(difference: number): number {
  if (difference < 1) return 0;
  if (difference > 8) return 8;
  return Math.floor(difference) - 1;
}

function findNearestBandSize(size: number, system: SizingSystem): number {
  const bandSizes = sizingData.validBandSizes[system];
  return bandSizes.reduce((prev: number, curr: number) => 
    Math.abs(curr - size) < Math.abs(prev - size) ? curr : prev
  );
}

function generateFitTips(selectedFitIssues: string[]): string[] {
  return selectedFitIssues.map(issueId => {
    const issue = fitIssues.find((fi: FitIssue) => fi.id === issueId);
    return issue?.solution || '';
  }).filter(tip => tip !== '');
}

function getUKDressSize(bandSize: number): string {
  const size = convertSize({ band: bandSize, cup: 'B' }, 'US', 'UK');
  return size ? size.band.toString() : 'N/A';
}

export function calculateEnhancedBraSize(
  measurements: Measurements,
  selectedFitIssues: string[] = []
): SizeResult {
  // Convert measurements to inches if in centimeters
  let { underbust, bust } = measurements;
  if (measurements.unit === 'centimeters') {
    underbust = underbust / 2.54;
    bust = bust / 2.54;
  }

  // Calculate band size
  let bandSize = Math.round(underbust);
  if (underbust < 33) {
    bandSize = Math.ceil(underbust / 2) * 2;
  } else {
    bandSize = Math.round(underbust / 2) * 2;
  }

  // Calculate cup size
  const cupDifference = bust - underbust;
  let cupIndex = calculateCupIndex(cupDifference);
  let cupSize = sizingData.cupSizes.US[cupIndex] || sizingData.cupSizes.US[sizingData.cupSizes.US.length - 1];

  // Adjust size based on fit issues
  if (selectedFitIssues?.length > 0) {
    selectedFitIssues.forEach(issueId => {
      const issue = fitIssues.find((fi: FitIssue) => fi.id === issueId);
      if (issue?.sizeImplication) {
        if (issue.sizeImplication.bandChange === 'up') {
          bandSize += 2;
        } else if (issue.sizeImplication.bandChange === 'down') {
          bandSize -= 2;
        }
        if (issue.sizeImplication.cupChange === 'up') {
          cupIndex = Math.min(cupIndex + 1, sizingData.cupSizes.US.length - 1);
          cupSize = sizingData.cupSizes.US[cupIndex];
        } else if (issue.sizeImplication.cupChange === 'down') {
          cupIndex = Math.max(0, cupIndex - 1);
          cupSize = sizingData.cupSizes.US[cupIndex];
        }
      }
    });
  }

  // Ensure band size is within valid range
  bandSize = Math.max(28, Math.min(60, bandSize));

  // Calculate sister sizes
  const sisterSizes: SisterSize[] = [
    { bandSize: (bandSize - 2).toString(), cupSize: sizingData.cupSizes.US[cupIndex + 1] },
    { bandSize: bandSize.toString(), cupSize },
    { bandSize: (bandSize + 2).toString(), cupSize: sizingData.cupSizes.US[cupIndex - 1] }
  ].filter(size => size.cupSize && 
    parseInt(size.bandSize) >= 28 && 
    parseInt(size.bandSize) <= 60
  );

  // Calculate international sizes using the conversion function
  const baseSize = { band: bandSize, cup: cupSize };
  const internationalSizes: InternationalSizes = {
    US: `${bandSize}${cupSize}`,
    UK: 'N/A',
    EU: 'N/A',
    FR_ES: 'N/A',
    IT: 'N/A',
    AU_NZ: 'N/A',
    JP: 'N/A',
    UK_DRESS: getUKDressSize(bandSize)
  };

  // Convert to other sizing systems
  const systems: SizingSystem[] = ['UK', 'EU', 'FR_ES', 'IT', 'AU_NZ', 'JP'];
  systems.forEach(system => {
    const converted = convertSize(baseSize, 'US', system);
    if (converted && converted.band && converted.cup) {
      internationalSizes[system] = `${converted.band}${converted.cup}`;
    }
  });

  // Generate fit tips based on selected issues
  const fitTips = generateFitTips(selectedFitIssues);

  // Calculate confidence score
  const confidenceScore = generateConfidenceScore(measurements);

  // Create recommendations object
  const recommendations: SizeRecommendations = {
    fitTips,
    bandAdjustment: selectedFitIssues?.some(id => ['bandRidesUp', 'bandTooTight'].includes(id)) || false,
    cupAdjustment: selectedFitIssues?.some(id => ['cupSpillage', 'gapsInCups'].includes(id)) || false
  };

  return {
    bandSize: bandSize.toString(),
    cupSize,
    sisterSizes,
    internationalSizes,
    confidence: confidenceScore.score,
    recommendations,
    letterSize: getLetterSize(bandSize, cupSize, 'US')
  };
}
