import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { getLocalizedPath } from '../config/routes';
import type { SupportedLanguage } from '../config/routes';

interface LanguageSwitcherProps {
  onLanguageChange: (language: string) => void;
}

const languages = {
  en: {
    name: 'English',
    flag: '🇺🇸',
    flagIcon: '/images/flags/us.svg'
  },
  it: {
    name: 'Italiano',
    flag: '🇮🇹',
    flagIcon: '/images/flags/it.svg'
  },
  ja: {
    name: '日本語',
    flag: '🇯🇵',
    flagIcon: '/images/flags/ja.svg'
  },
  es: {
    name: 'Español',
    flag: '🇪🇸',
    flagIcon: '/images/flags/es.svg'
  },
  fr: {
    name: 'Français',
    flag: '🇫🇷',
    flagIcon: '/images/flags/fr.svg'
  },
  de: {
    name: 'Deutsch',
    flag: '🇩🇪',
    flagIcon: '/images/flags/de.svg'
  },
  pl: {
    name: 'Polski',
    flag: '🇵🇱',
    flagIcon: '/images/flags/pl.svg'
  },
  nl: {
    name: 'Nederlands',
    flag: '🇳🇱',
    flagIcon: '/images/flags/nl.svg'
  },
  sv: {
    name: 'Svenska',
    flag: '🇸🇪',
    flagIcon: '/images/flags/sv.svg'
  },
  tr: {
    name: 'Türkçe',
    flag: '🇹🇷',
    flagIcon: '/images/flags/tr.svg'
  },
  ru: {
    name: 'Русский',
    flag: '🇷🇺',
    flagIcon: '/images/flags/ru.svg'
  }
};

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = ({ onLanguageChange }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLanguageSelect = async (lng: string) => {
    try {
      console.log('Switching language to:', lng);
      console.log('Current path:', location.pathname);
      
      // First ensure all namespaces are loaded for the new language
      await i18n.loadNamespaces([
        'common',
        'home',
        'sizeConverter',
        'converter',
        'calculator',
        'fitIssues',
        'braFitting',
        'measuringGuide',
        'sisterSizes',
        'sizeCharts',
        'braTypes',
        'breastShape',
        'braFitIssues',
        'faq',
        'contact',
        'legal',
        'routes',
        'aboutUs',
      ]);
      
      // Then change the language
      await i18n.changeLanguage(lng);
      localStorage.setItem('i18nextLng', lng);
      document.documentElement.lang = lng;
      
      // Get the new path for the current route in the new language
      const newPath = getLocalizedPath(location.pathname, lng as SupportedLanguage);
      console.log('New path:', newPath);
      
      // Call the onLanguageChange callback
      onLanguageChange(lng);
      
      // Navigate to the new path and force a page reload
      if (newPath !== location.pathname) {
        window.location.href = newPath;
      }
      setIsOpen(false);
    } catch (error) {
      console.error('Error switching language:', error);
    }
  };

  const currentLanguage = languages[i18n.language as keyof typeof languages] || languages.en;

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 px-3 py-2 text-sm font-medium text-gray-600 hover:text-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500 rounded-md"
        aria-expanded={isOpen}
        aria-haspopup="true"
      >
        <span className="md:hidden">{currentLanguage.flag}</span>
        <img 
          src={currentLanguage.flagIcon} 
          alt={`${currentLanguage.name} flag`} 
          className="hidden md:inline-block w-5 h-5"
        />
        <span>{currentLanguage.name}</span>
        <svg
          className={`h-5 w-5 transform ${isOpen ? 'rotate-180' : ''} transition-transform duration-200`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute left-0 md:right-0 md:left-auto mt-2 py-1 w-48 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-50">
          {Object.entries(languages).map(([code, { name, flag, flagIcon }]) => (
            <button
              key={code}
              onClick={() => handleLanguageSelect(code)}
              className={`
                w-full text-left px-4 py-2 text-sm flex items-center space-x-2
                ${code === i18n.language ? 'text-purple-600 bg-purple-50' : 'text-gray-700 hover:bg-gray-100'}
              `}
            >
              <span className="md:hidden">{flag}</span>
              <img 
                src={flagIcon} 
                alt={`${name} flag`} 
                className="hidden md:inline-block w-5 h-5"
              />
              <span>{name}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher; 