import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import type { 
  DetailedFitIssue,
  ShapeType, 
  BraFitIssuesTranslations
} from '../types/fitTypes';
import { useTranslations } from '../hooks/useTranslations';
import { getCanonicalUrl } from '../utils/seo';

interface TranslatedData {
  meta: { title: string; description: string; keywords: string };
  hero: { title: string; description: string };
  tabs: {
    overview: string;
    detailed: string;
    adjustments: string;
    shapes: string;
  };
  overview: {
    commonIssues: {
      title: string;
      description: string;
      issues: Array<{
        title: string;
        description: string;
      }>;
    };
    keyMeasurements: {
      title: string;
      relationships: {
        title: string;
        items: string[];
      };
      underbust: {
        title: string;
        items: string[];
      };
      bust: {
        title: string;
        items: string[];
      };
    };
  };
  adjustments: {
    bandSize: {
      tooLoose: {
        title: string;
        steps: string[];
      };
      tooTight: {
        title: string;
        steps: string[];
      };
    };
    cupSize: {
      gaping: {
        title: string;
        steps: string[];
      };
      overflowing: {
        title: string;
        steps: string[];
      };
    };
  };
  shapes: {
    fullOnTop: {
      title: string;
      characteristics: string[];
      solutions: string[];
    };
    fullOnBottom: {
      title: string;
      characteristics: string[];
      solutions: string[];
    };
    centerFull: {
      title: string;
      characteristics: string[];
      solutions: string[];
    };
    outerFull: {
      title: string;
      characteristics: string[];
      solutions: string[];
    };
  };
}

const useTranslatedData = () => {
  const braFitIssues = useTranslations('braFitIssues');
  const fitIssues = useTranslations('fitIssues');

  // Define default values
  const defaultDetailedIssue: DetailedFitIssue = {
    id: '',
    title: '',
    category: '', // Add this line
    causes: [],
    solutions: [],
    relatedMeasurements: [],
    recommendedTypes: []
  };
  
  const defaultDetailedIssues: DetailedFitIssue[] = [];

  const defaultMeta: TranslatedData['meta'] = {
    title: '',
    description: '',
    keywords: ''
  };

  const defaultHero: TranslatedData['hero'] = {
    title: '',
    description: ''
  };

  const defaultTabs: TranslatedData['tabs'] = {
    overview: '',
    detailed: '',
    adjustments: '',
    shapes: ''
  };

  const defaultOverview: TranslatedData['overview'] = {
    commonIssues: {
      title: '',
      description: '',
      issues: []
    },
    keyMeasurements: {
      title: '',
      relationships: { title: '', items: [] },
      underbust: { title: '', items: [] },
      bust: { title: '', items: [] }
    }
  };

  const defaultAdjustments: TranslatedData['adjustments'] = {
    bandSize: {
      tooLoose: { title: '', steps: [] },
      tooTight: { title: '', steps: [] }
    },
    cupSize: {
      gaping: { title: '', steps: [] },
      overflowing: { title: '', steps: [] }
    }
  };

  const defaultShapes: TranslatedData['shapes'] = {
    fullOnTop: { title: '', characteristics: [], solutions: [] },
    fullOnBottom: { title: '', characteristics: [], solutions: [] },
    centerFull: { title: '', characteristics: [], solutions: [] },
    outerFull: { title: '', characteristics: [], solutions: [] }
  };

  const getDetailedIssue = (category: string, issueKey: string): DetailedFitIssue | null => {
    if (!fitIssues.ready) return null;
    const issue = fitIssues.translateObject(
      `detailedIssues.${category}.${issueKey}`,
      defaultDetailedIssue
    );
    return issue || null;
  };

  const getAllDetailedIssues = (): DetailedFitIssue[] => {
    if (!fitIssues.ready) return [];
    const issues = fitIssues.translateObject('detailedFitIssues', defaultDetailedIssues);
    return issues || [];
  };

  return {
    ready: braFitIssues.ready && fitIssues.ready,
    translate: (key: string) => braFitIssues.translate(key),
    getDetailedIssue,
    getAllDetailedIssues,
    metaData: braFitIssues.translateObject('meta', defaultMeta),
    heroData: braFitIssues.translateObject('hero', defaultHero),
    tabs: braFitIssues.translateObject('tabs', defaultTabs),
    overview: braFitIssues.translateObject('overview', defaultOverview),
    adjustments: braFitIssues.translateObject('adjustments', defaultAdjustments),
    shapes: braFitIssues.translateObject('shapes', defaultShapes)
  };
};

const BraFitIssuesGuide: React.FC = () => {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState<keyof TranslatedData['tabs']>('overview');
  const {
    ready,
    translate,
    getAllDetailedIssues,
    metaData,
    heroData,
    tabs,
    overview,
    adjustments,
    shapes
  } = useTranslatedData();

  if (!ready || !overview || !adjustments || !shapes) {
    return <div>Loading...</div>;
  }

  const tabKeys: Array<keyof TranslatedData['tabs']> = ['overview', 'detailed', 'adjustments', 'shapes'];

  const renderOverview = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="space-y-8 max-w-7xl mx-auto"
    >
      <div className="bg-white rounded-xl shadow p-8 mb-8">
        <h2 className="text-2xl font-semibold text-purple-900 mb-4 text-center">{overview.commonIssues.title}</h2>
        <p className="text-gray-600 text-medium mx-auto mb-6 text-center">{overview.commonIssues.description}</p>
        <div className="grid md:grid-cols-3 gap-6">
          {overview.commonIssues.issues.map((issue, index) => (
            <div key={index} className="bg-purple-50 rounded-lg p-6">
              <h3 className="font-medium text-purple-900 mb-3">{issue.title}</h3>
              <p className="text-gray-600">{issue.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white rounded-xl shadow p-8">
        <h2 className="text-2xl font-semibold text-purple-900 mb-4 text-center">{overview.keyMeasurements.title}</h2>

        {/* Single column for "Relationships" */}
        <div className="mb-6">
          <div className="bg-purple-50 rounded-lg p-6">
            <h3 className="text-lg font-medium text-purple-900 mb-4">{overview.keyMeasurements.relationships.title}</h3>
            <ul className="space-y-3 text-gray-600">
              {overview.keyMeasurements.relationships.items.map((item, index) => (
                <li key={index} className="flex text-gray-600">
                  <span className="text-purple-500 mr-2">•</span>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Two-column layout for "Underbust" and "Bust" */}
        <div className="grid md:grid-cols-2 gap-6">
          <div className="bg-purple-50 rounded-lg p-6">
            <h3 className="text-lg font-medium text-purple-900 mb-4">{overview.keyMeasurements.underbust.title}</h3>
            <ul className="space-y-3">
              {overview.keyMeasurements.underbust.items.map((item, index) => (
                <li key={index} className="flex text-gray-600">
                  <span className="text-purple-500 mr-2">•</span>
                  {item}
                </li>
              ))}
            </ul>
          </div>

          <div className="bg-purple-50 rounded-lg p-6">
            <h3 className="text-lg font-medium text-purple-900 mb-4">{overview.keyMeasurements.bust.title}</h3>
            <ul className="space-y-3">
              {overview.keyMeasurements.bust.items.map((item, index) => (
                <li key={index} className="flex text-gray-600">
                  <span className="text-purple-500 mr-2">•</span>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* Button to Calculator */}
      <div className="text-center mt-12">
        <button
          onClick={() => window.location.href = '/#calculator'}
          className="inline-flex items-center bg-white border border-purple-600 text-purple-600 text-lg font-semibold px-8 py-3 rounded-lg transition-colors group"
        >
          <span>Calculate Bra Size</span>
          <svg
            className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </motion.div>
  );

  const renderAdjustments = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="space-y-8 max-w-7xl mx-auto"
    >
      {/* Band Size Adjustments Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-white rounded-xl shadow-sm p-6">
          <h3 className="text-xl font-semibold text-purple-900 mb-4">{adjustments.bandSize.tooLoose.title}</h3>
          <div className="bg-purple-50 rounded-lg p-4 mb-4">
            <ul className="space-y-2">
              {adjustments.bandSize.tooLoose.steps.map((step, index) => (
                <li key={index} className="flex text-gray-600">
                  <span className="text-purple-500 mr-2">•</span>
                  {step}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-sm p-6">
          <h3 className="text-xl font-semibold text-purple-900 mb-4">{adjustments.bandSize.tooTight.title}</h3>
          <div className="bg-purple-50 rounded-lg p-4 mb-4">
            <ul className="space-y-2">
              {adjustments.bandSize.tooTight.steps.map((step, index) => (
                <li key={index} className="flex text-gray-600">
                  <span className="text-purple-500 mr-2">•</span>
                  {step}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* Cup Size Adjustments Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-white rounded-xl shadow-sm p-6">
          <h3 className="text-xl font-semibold text-purple-900 mb-4">{adjustments.cupSize.gaping.title}</h3>
          <div className="bg-purple-50 rounded-lg p-4 mb-4">
            <ul className="space-y-2">
              {adjustments.cupSize.gaping.steps.map((step, index) => (
                <li key={index} className="flex text-gray-600">
                  <span className="text-purple-500 mr-2">•</span>
                  {step}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-sm p-6">
          <h3 className="text-xl font-semibold text-purple-900 mb-4">{adjustments.cupSize.overflowing.title}</h3>
          <div className="bg-purple-50 rounded-lg p-4 mb-4">
            <ul className="space-y-2">
              {adjustments.cupSize.overflowing.steps.map((step, index) => (
                <li key={index} className="flex text-gray-600">
                  <span className="text-purple-500 mr-2">•</span>
                  {step}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* Button to Sister Sizes */}
      <div className="text-center mt-12">
        <button
          onClick={() => window.location.href = '/sister-sizes'}
          className="inline-flex items-center bg-white border border-purple-600 text-purple-600 text-lg font-semibold px-8 py-3 rounded-lg transition-colors group"
        >
          <span>Find Your Sister Sizes</span>
          <svg
            className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </motion.div>
  );

  const renderShapes = () => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="space-y-8"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-7xl mx-auto">
        {[
          {
            title: shapes.fullOnTop.title,
            characteristics: shapes.fullOnTop.characteristics,
            recommendedStyles: shapes.fullOnTop.solutions
          },
          {
            title: shapes.fullOnBottom.title,
            characteristics: shapes.fullOnBottom.characteristics,
            recommendedStyles: shapes.fullOnBottom.solutions
          },
          {
            title: shapes.centerFull.title,
            characteristics: shapes.centerFull.characteristics,
            recommendedStyles: shapes.centerFull.solutions
          },
          {
            title: shapes.outerFull.title,
            characteristics: shapes.outerFull.characteristics,
            recommendedStyles: shapes.outerFull.solutions
          }
        ].map((shape, idx) => (
          <motion.div
            key={idx}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: idx * 0.1 }}
            className="bg-white rounded-xl shadow-sm p-6"
          >
            <h3 className="text-xl font-semibold text-purple-900 mb-4">{shape.title}</h3>
            
            <div className="bg-purple-50 rounded-lg p-4 mb-4">
              <h4 className="font-medium text-purple-900 mb-2">Characteristics</h4>
              <ul className="space-y-2">
                {shape.characteristics.map((char, i) => (
                  <li key={i} className="flex text-gray-600">
                    <span className="text-purple-500 mr-2">•</span>
                    {char}
                  </li>
                ))}
              </ul>
            </div>

            <div className="bg-green-50 rounded-lg p-4">
              <h4 className="font-medium text-green-800 mb-2">Recommended Styles</h4>
              <ul className="space-y-2">
                {shape.recommendedStyles.map((style, i) => (
                  <li key={i} className="flex text-gray-600">
                    <span className="text-green-500 mr-2">•</span>
                    {style}
                  </li>
                ))}
              </ul>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Button to Breast Shape Guide */}
      <div className="text-center mt-12">
        <button
          onClick={() => window.location.href = '/breast-shape-guide'}
          className="inline-flex items-center bg-white border border-purple-600 text-purple-600 text-md font-semibold px-8 py-3 rounded-lg transition-colors group"
        >
          <span>Explore Breast Shape Guide</span>
          <svg
            className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </motion.div>
  );

  const renderDetailedIssues = () => {
    const allIssues = getAllDetailedIssues();

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="space-y-8"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-7xl mx-auto">
          {allIssues.map((issue) => (
            <div key={issue.id} className="bg-white rounded-xl shadow-sm p-6">
                  <h3 className="text-xl font-semibold text-purple-900 mb-4">
                {issue.title}
                  </h3>
                  
                  {/* Causes Card */}
                  <div className="bg-purple-50 rounded-lg p-4 mb-4">
                <h4 className="font-medium text-purple-900 mb-2">Common Causes</h4>
                    <ul className="space-y-2">
                  {issue.causes.map((cause: string, index: number) => (
                        <li key={index} className="flex text-gray-600">
                          <span className="text-purple-500 mr-2">•</span>
                          {cause}
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* Solutions Card */}
                  <div className="bg-green-50 rounded-lg p-4 mb-4">
                <h4 className="font-medium text-green-800 mb-2">{translate('detailed.labels.solutions')}</h4>
                    <ul className="space-y-2">
                  {issue.solutions.map((solution: string, index: number) => (
                        <li key={index} className="flex text-gray-600">
                          <span className="text-green-500 mr-2">•</span>
                          {solution}
                        </li>
                      ))}
                    </ul>
                  </div>

                  {/* Related Measurements */}
              {issue.relatedMeasurements.length > 0 && (
                    <div className="bg-gray-50 rounded-lg p-4 mb-4">
                  <h4 className="font-medium text-gray-500 mb-4">Related Measurements</h4>
                      <div className="flex flex-wrap gap-2">
                    {issue.relatedMeasurements.map((measurement: string, index: number) => (
                          <span
                            key={index}
                            className="inline-flex items-center px-3 py-1 rounded-full text-md font-medium bg-blue-100 text-blue-800"
                          >
                            {measurement}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}

              {/* Recommended Types */}
              {issue.recommendedTypes.length > 0 && (
                    <div className="bg-gray-50 rounded-lg p-4 mb-4">
                  <h4 className="font-medium text-gray-500 mb-4">Recommended Bra Types</h4>
                      <div className="flex flex-wrap gap-2">
                    {issue.recommendedTypes.map((style: string, index: number) => (
                          <span
                            key={index}
                            className="inline-flex items-center px-3 py-1 rounded-full text-md font-medium bg-green-100 text-green-800"
                          >
                            {style}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
          ))}
        </div>

        {/* Button to Calculator */}
        <div className="text-center mt-12">
          <button
            onClick={() => window.location.href = '/#calculator'}
            className="inline-flex items-center bg-white border border-purple-600 text-purple-600 text-lg font-semibold px-8 py-3 rounded-lg transition-colors group"
          >
            <span>Bra Size Calculator</span>
            <svg
              className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </motion.div>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <meta name="keywords" content={metaData.keywords} />
        <link rel="canonical" href={getCanonicalUrl(location.pathname)} />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
            {heroData.title}
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            {heroData.description}
          </p>
        </motion.div>

        {/* Tab Navigation */}
        <div className="mb-8">
          <div className="border-b border-gray-200">
            <nav className="flex justify-center" aria-label="Tabs">
              <div className="flex w-full max-w-2xl">
                {tabKeys.map((key) => (
                  <button
                    key={key}
                    onClick={() => setSelectedTab(key)}
                    className={`
                      flex-1 py-3 px-2 border-b-2 font-medium text-sm transition-colors duration-200
                      text-center leading-tight min-h-[56px] flex items-center justify-center
                      ${selectedTab === key
                        ? 'border-purple-600 text-purple-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                      }
                    `}
                  >
                    <span className="block px-1">{tabs[key]}</span>
                  </button>
                ))}
              </div>
            </nav>
          </div>
        </div>

        {/* Tab Content */}
        {selectedTab === 'overview' && renderOverview()}
        {selectedTab === 'detailed' && renderDetailedIssues()}
        {selectedTab === 'adjustments' && renderAdjustments()}
        {selectedTab === 'shapes' && renderShapes()}
      </div>
    </div>
  );
};

export default BraFitIssuesGuide;
