import React from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import QuickConverter from '../components/QuickConverter';
import NewBraSizeCalculator from '../components/NewBraSizeCalculator';
import type homeTranslations from '../i18n/locales/en/home.json';
import { getCanonicalUrl } from '../utils/seo';
import { routes, type SupportedLanguage } from '../config/routes';

// Type your data using the JSON import
type HomeTranslations = typeof homeTranslations;

// Add type definitions for the map parameters
type StatType = { percentage: number; label: string; detail: string };
type ItemType = { icon: string; title: string; description: string };
type FeatureType = { title: string; description: string };

// Define types for our map parameters
type MapParams = {
  stat: { percentage: number; label: string; detail: string };
  item: { icon: string; title: string; description: string };
  step: string;
  tip: string;
  feature: { title: string; description: string };
  index: number;
};

// Define the structure of our translation data
interface TranslationData {
  ready: boolean;
  wrongSizeData: {
    title: string;
    subtitle: string;
    statistics: StatType[];
    consequences: {
      title: string;
      items: ItemType[];
    };
    cta: string;
    source: string;
  };
  metaData: {
    title: string;
    description: string;
    keywords: string;
    schema: {
      name: string;
      description: string;
      category: string;
    };
  };
  heroData: {
    title: string;
    subtitle: string;
    stats: {
      womenHelped: { value: string; label: string };
      accuracyRate: { value: string; label: string };
      sizeOptions: { value: string; label: string };
    };
  };
  calculatorData: {
    title: string;
    description: string;
  };
  measuringGuideData: {
    title: string;
    steps: {
      underbust: { title: string; description: string };
      bust: { title: string; description: string };
      calculate: { title: string; description: string };
    };
    viewGuideButton: string;
  };
  howToUseData: {
    title: string;
    simpleSteps: {
      title: string;
      steps: string[];
    };
    proTips: {
      title: string;
      tips: string[];
    };
  };
  sizeTagsData: {
    title: string;
    commonIssues: {
      title: string;
      items: string[];
    };
    solutions: {
      title: string;
      items: string[];
    };
    moreOptions: string;
  };
  aboutData: {
    title: string;
    features: FeatureType[];
    upgradeButton: string;
  };
}

const defaultData: TranslationData = {
  ready: false,
  wrongSizeData: {
    title: '',
    subtitle: '',
    statistics: [],
    consequences: {
      title: '',
      items: []
    },
    cta: '',
    source: ''
  },
  metaData: {
    title: '',
    description: '',
    keywords: '',
    schema: {
      name: '',
      description: '',
      category: ''
    }
  },
  heroData: {
    title: '',
    subtitle: '',
    stats: {
      womenHelped: { value: '', label: '' },
      accuracyRate: { value: '', label: '' },
      sizeOptions: { value: '', label: '' }
    }
  },
  calculatorData: {
    title: '',
    description: ''
  },
  measuringGuideData: {
    title: '',
    steps: {
      underbust: { title: '', description: '' },
      bust: { title: '', description: '' },
      calculate: { title: '', description: '' }
    },
    viewGuideButton: ''
  },
  howToUseData: {
    title: '',
    simpleSteps: {
      title: '',
      steps: []
    },
    proTips: {
      title: '',
      tips: []
    }
  },
  sizeTagsData: {
    title: '',
    commonIssues: {
      title: '',
      items: []
    },
    solutions: {
      title: '',
      items: []
    },
    moreOptions: ''
  },
  aboutData: {
    title: '',
    features: [],
    upgradeButton: ''
  }
};

export const useTranslatedData = (): TranslationData => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { t, ready, i18n } = useTranslation('home', { useSuspense: false }) as { t: (key: string, options?: { returnObjects: boolean }) => any; ready: boolean; i18n: any };

  // Ensure the home namespace is loaded
  React.useEffect(() => {
    if (!i18n.hasLoadedNamespace('home')) {
      i18n.loadNamespaces('home');
    }
  }, [i18n]);

  if (!ready || !i18n.hasLoadedNamespace('home')) {
    return defaultData;
  }

  try {
    const data: TranslationData = {
      ready: true,
      wrongSizeData: t('wrongSizeImpact', { returnObjects: true }) || defaultData.wrongSizeData,
      metaData: t('meta', { returnObjects: true }) || defaultData.metaData,
      heroData: t('hero', { returnObjects: true }) || defaultData.heroData,
      calculatorData: t('calculator', { returnObjects: true }) || defaultData.calculatorData,
      measuringGuideData: t('measuringGuide', { returnObjects: true }) || defaultData.measuringGuideData,
      howToUseData: t('howToUse', { returnObjects: true }) || defaultData.howToUseData,
      sizeTagsData: t('sizeTags', { returnObjects: true }) || defaultData.sizeTagsData,
      aboutData: t('about', { returnObjects: true }) || defaultData.aboutData,
    };

    return data;
  } catch (error) {
    console.error('Failed to load translations:', error);
    return defaultData;
  }
};

export const WrongSizeImpact: React.FC = () => {
  const { wrongSizeData } = useTranslatedData();

  if (!wrongSizeData?.title) {
    return null;
  }

  return (
    <div className="py-16 bg-purple-50 rounded-xl max-w-5xl mx-auto px-6">
      <div className="max-w-4xl mx-auto">
        <h2 className="text-3xl font-bold text-center text-purple-800 mb-6">
          {wrongSizeData.title}
        </h2>
        
        <p className="text-gray-600 text-center mb-12">
          {wrongSizeData.subtitle}
        </p>

        {/* Statistics Cards */}
        <div className="grid md:grid-cols-3 gap-6 mb-16">
          {wrongSizeData.statistics.map((stat: StatType, index: number) => (
            <div key={index} className="bg-white rounded-xl shadow-sm p-6 text-center">
              <div className="text-4xl font-bold text-purple-600 mb-2">
                {stat.percentage}%
              </div>
              <div className="font-semibold mb-2">{stat.label}</div>
              <div className="text-sm text-gray-600">{stat.detail}</div>
            </div>
          ))}
        </div>

        {/* Consequences Grid */}
        <h3 className="text-2xl font-bold text-center text-purple-800 mb-8">
          {wrongSizeData.consequences.title}
        </h3>
        
        <div className="grid md:grid-cols-2 gap-6">
          {wrongSizeData.consequences.items.map((item: ItemType, index: number) => (
            <div key={index} className="bg-white rounded-lg p-6 flex items-start">
              <div className="text-3xl mr-4">{item.icon}</div>
              <div>
                <h4 className="font-semibold mb-1">{item.title}</h4>
                <p className="text-gray-600">{item.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* CTA Section */}
        <div className="text-center mt-12">
          <button 
            onClick={() => {
              const calculatorSection = document.getElementById('enhanced-calculator');
              if (calculatorSection) {
                calculatorSection.scrollIntoView({ behavior: 'smooth' });
              }
            }}
            className="inline-flex items-center bg-purple-600 text-white px-8 py-3 rounded-lg hover:bg-purple-700 transition-colors group"
          >
            <span>{wrongSizeData.cta}</span>
            <svg 
              className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
          <p className="text-sm italic text-gray-400 mt-6">
            {wrongSizeData.source}
          </p>
        </div>
      </div>
    </div>
  );
};

export const Home: React.FC = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation(['home', 'routes']);
  const data = useTranslatedData();

  const getRoute = (key: keyof typeof routes) => {
    const currentLang = i18n.language as SupportedLanguage;
    const route = routes[key];
    if ('privacy' in route) {
      // This is a LegalRouteConfig
      return Object.values(route)[0][currentLang];
    }
    return route[currentLang];
  };

  return (
    <>
      <Helmet>
        <title>{data.metaData.title}</title>
        <meta name="description" content={data.metaData.description} />
        <meta name="keywords" content={data.metaData.keywords} />
        <link rel="canonical" href={`${window.location.origin}${i18n.language === 'en' ? '/' : `/${i18n.language}`}`} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebApplication",
            "name": data.metaData.schema.name,
            "description": data.metaData.schema.description,
            "applicationCategory": data.metaData.schema.category,
          })}
        </script>
      </Helmet>

      {/* Hero Section */}
      <section className="bg-gradient-to-b from-purple-50 to-white py-6 md:py-12 px-4">
        <div className="max-w-5xl mx-auto px-6">
          <div className="text-center py-8 md:py-16">
            <h1 className="text-5xl md:text-6xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600 px-4">
              {data.heroData.title}
            </h1>
            <p className="text-lg md:text-xl text-gray-600 mb-8 max-w-2xl mx-auto px-4">
              {data.heroData.subtitle}
            </p>
            <div className="flex flex-row justify-center mt-8 md:mt-12 gap-4 md:gap-8">
              <div className="bg-gradient-to-b from-purple-50 to-pink-50 rounded-xl px-6 py-4 border border-purple-100">
                <div className="text-2xl md:text-4xl font-bold text-purple-600 text-center">
                  {data.heroData.stats.womenHelped.value}
                </div>
                <div className="text-sm md:text-base text-gray-600 font-medium text-center">
                  {data.heroData.stats.womenHelped.label}
                </div>
              </div>
              <div className="bg-gradient-to-b from-purple-50 to-pink-50 rounded-xl px-6 py-4 border border-purple-100">
                <div className="text-2xl md:text-4xl font-bold text-purple-600 text-center">
                  {data.heroData.stats.accuracyRate.value}
                </div>
                <div className="text-sm md:text-base text-gray-600 font-medium text-center">
                  {data.heroData.stats.accuracyRate.label}
                </div>
              </div>
              <div className="bg-gradient-to-b from-purple-50 to-pink-50 rounded-xl px-6 py-4 border border-purple-100">
                <div className="text-2xl md:text-4xl font-bold text-purple-600 text-center">
                  {data.heroData.stats.sizeOptions.value}
                </div>
                <div className="text-sm md:text-base text-gray-600 font-medium text-center">
                  {data.heroData.stats.sizeOptions.label}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Quick Calculator Section */}
      <section id="calculator" className="bg-gradient-to-br from-purple-50 via-pink-50 to-purple-50 py-16 px-4 shadow-inner">
        <div className="max-w-5xl mx-auto px-6">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold mb-4 bg-clip-text text-purple-800">
              {data.calculatorData.title}
            </h2>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">
              {data.calculatorData.description}
            </p>
          </div>
          <div className="max-w-4xl mx-auto bg-white rounded-2xl shadow-lg p-8">
            <NewBraSizeCalculator />
          </div>
        </div>
      </section>

      {/* Size Tags Section */}
      <section className="py-16 px-4">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold text-center text-purple-800 mb-12">
            {data.sizeTagsData.title}
          </h2>
          <div className="grid md:grid-cols-2 gap-6">
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h3 className="text-lg font-semibold mb-3 text-purple-600">{data.sizeTagsData.commonIssues.title}</h3>
              <ul className="space-y-4">
                {data.sizeTagsData.commonIssues.items.map((item: string, index: number) => (
                  <li key={index} className="flex items-center">
                    <div className="flex-shrink-0 w-6 h-6 mr-3">
                      <svg className="w-6 h-6 text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                      </svg>
                    </div>
                    <p className="text-gray-700">{item}</p>
                  </li>
                ))}
              </ul>
            </div>
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h3 className="text-lg font-semibold mb-3 text-purple-600">{data.sizeTagsData.solutions.title}</h3>
              <ul className="space-y-4">
                {data.sizeTagsData.solutions.items.map((item: string, index: number) => (
                  <li key={index} className="flex items-center">
                    <div className="flex-shrink-0 w-6 h-6 mr-3">
                      <svg className="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <p className="text-gray-700">{item}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="text-center mt-8">
            <Link 
              to={getRoute('sisterSizes')}
              className="text-purple-600 hover:text-purple-800 font-medium"
            >
              {data.sizeTagsData.moreOptions}
            </Link>
          </div>
        </div>
      </section>

      {/* Measuring Guide Section */}
      <section className="bg-purple-50 py-16 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-purple-800 mb-12">
            {data.measuringGuideData.title}
          </h2>
          <div className="grid md:grid-cols-3 gap-8 mb-12">
            <div className="text-center">
              <div className="relative w-48 h-48 mx-auto mb-4">
                <img 
                  src="/images/underbust-measurement.png" 
                  alt={data.measuringGuideData.steps.underbust.title}
                  className="w-full h-full object-contain"
                />
                <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center absolute top-0 right-0">
                  <span className="text-xl font-bold text-purple-500">1</span>
                </div>
              </div>
              <h3 className="font-medium mb-2">{data.measuringGuideData.steps.underbust.title}</h3>
              <p className="text-sm text-gray-600">
                {data.measuringGuideData.steps.underbust.description}
              </p>
            </div>
            <div className="text-center">
              <div className="relative w-48 h-48 mx-auto mb-4">
                <img 
                  src="/images/bust-measurement.png" 
                  alt={data.measuringGuideData.steps.bust.title}
                  className="w-full h-full object-contain"
                />
                <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center absolute top-0 right-0">
                  <span className="text-xl font-bold text-purple-500">2</span>
                </div>
              </div>
              <h3 className="font-medium mb-2">{data.measuringGuideData.steps.bust.title}</h3>
              <p className="text-sm text-gray-600">
                {data.measuringGuideData.steps.bust.description}
              </p>
            </div>
            <div className="text-center">
              <div className="relative w-48 h-48 mx-auto mb-4">
                <img 
                  src="/images/calculator.png" 
                  alt={data.measuringGuideData.steps.calculate.title}
                  className="w-full h-full object-contain"
                />
                <div className="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center absolute top-0 right-0">
                  <span className="text-xl font-bold text-purple-500">3</span>
                </div>
              </div>
              <h3 className="font-medium mb-2">{data.measuringGuideData.steps.calculate.title}</h3>
              <p className="text-sm text-gray-600">
                {data.measuringGuideData.steps.calculate.description}
              </p>
            </div>
          </div>
          <Link 
            to={getRoute('measuringGuide')}
            className="inline-block bg-purple-600 text-white px-8 py-3 rounded-lg hover:bg-purple-700 transition-colors"
          >
            {data.measuringGuideData.viewGuideButton}
          </Link>
        </div>
      </section>

      {/* Quick Converter Section */}
      <div className="mt-16 mb-16">
        <QuickConverter />
      </div>


      {/* Wrong Size Impact Section */}
      <WrongSizeImpact />

      {/* How to Use Section */}
      <section className="mt-16 mb-16">
        <h2 className="text-3xl font-bold text-center mb-8 text-purple-800">{data.howToUseData.title}</h2>
        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto px-6">
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <h3 className="text-xl font-semibold mb-4 text-purple-600">{data.howToUseData.simpleSteps.title}</h3>
            <ol className="space-y-4">
              {data.howToUseData.simpleSteps.steps.map((step: string, index: number) => (
                <li key={index} className="flex items-center">
                  <div className="flex-shrink-0 w-8 h-8 bg-purple-100 rounded-full flex items-center justify-center text-purple-600 font-semibold mr-3">
                    {index + 1}
                  </div>
                  <p className="text-gray-700">{step}</p>
                </li>
              ))}
            </ol>
          </div>
          <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
            <h3 className="text-xl font-semibold mb-4 text-purple-600">{data.howToUseData.proTips.title}</h3>
            <ul className="space-y-4">
              {data.howToUseData.proTips.tips.map((tip: string, index: number) => (
                <li key={index} className="flex items-center">
                  <div className="flex-shrink-0 w-6 h-6 mr-3">
                    <svg className="w-6 h-6 text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <p className="text-gray-700">{tip}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section className="bg-purple-50 rounded-xl p-8 mb-16 max-w-5xl mx-auto">
        <div className="max-w-4xl mx-auto px-6">
          <h2 className="text-3xl font-bold text-center text-purple-800 mb-10">{data.aboutData.title}</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {data.aboutData.features.map((feature: FeatureType, index: number) => (
              <div key={index} className="text-center">
                <div className="w-16 h-16 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                  <svg className="w-8 h-8 text-purple-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                  </svg>
                </div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
          <div className="text-center mt-8">
            <button className="inline-flex items-center bg-purple-600 text-white px-8 py-3 rounded-lg hover:bg-purple-700 transition-colors group">
              <span>{data.aboutData.upgradeButton}</span>
              <svg className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;