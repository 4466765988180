import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslations } from '../hooks/useTranslations';
import { getCanonicalUrl } from '../utils/seo';
import { motion } from 'framer-motion';

interface Tool {
  icon: string;
  item: string;
  detail: string;
}

interface Tip {
  tip: string;
  detail: string;
}

interface Mistake {
  title: string;
  description: string;
}

interface MetaData {
  title: string;
  description: string;
}

const defaultTools: Tool[] = [];
const defaultTips: Tip[] = [];
const defaultMistakes: Mistake[] = [];
const defaultMetaData: MetaData = {
  title: '',
  description: ''
};

const MeasuringGuide: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { translate, translateObject, ready } = useTranslations('measuringGuide');

  if (!ready) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-pulse text-purple-600 text-xl">Loading translations...</div>
      </div>
    );
  }

  const metaData = translateObject<MetaData>('meta', defaultMetaData);
  const tools = translateObject<Tool[]>('requirements.items', defaultTools);
  const tips = translateObject<Tip[]>('proTips.tips', defaultTips);
  const mistakes = translateObject<Mistake[]>('commonMistakes.mistakes', defaultMistakes);

  const renderTool = (tool: Tool, index: number) => (
    <div key={index} className="flex items-start p-4 bg-gray-50 rounded-xl">
      <span className="text-2xl mr-4">{tool.icon}</span>
      <div>
        <h3 className="font-medium">{tool.item}</h3>
        <p className="text-sm text-gray-600">{tool.detail}</p>
      </div>
    </div>
  );

  const renderTip = (tip: Tip, index: number) => (
    <div key={index} className="bg-white/80 rounded-xl p-4">
      <h3 className="font-medium mb-1">{tip.tip}</h3>
      <p className="text-sm text-gray-600">{tip.detail}</p>
    </div>
  );

  const renderMistake = (mistake: Mistake, index: number) => (
    <div key={index} className="bg-white p-6 rounded-lg shadow-md border border-gray-200 hover:shadow-lg transition-shadow">
      <h3 className="font-semibold text-gray-800 mb-3">{mistake.title}</h3>
      <p className="text-gray-600 leading-relaxed">{mistake.description}</p>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <link rel="canonical" href={getCanonicalUrl(location.pathname)} />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center mb-12"
          >
            <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
              {translate('title')}
            </h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              {translate('subtitle')}
            </p>
          </motion.div>

          {/* Content */}
          <div className="grid grid-cols-1 gap-8">
            {/* Steps Section */}
            <div className="bg-white rounded-2xl shadow-lg p-6">
              <h2 className="text-2xl font-bold mb-6 flex items-center">
                <span className="text-purple-600 mr-3">{translate('requirements.icon')}</span>
                {translate('requirements.title')}
              </h2>
              <div className="grid md:grid-cols-2 gap-6">
                {tools.map(renderTool)}
              </div>
            </div>

            {/* Tips Section */}
            <div className="bg-white rounded-2xl shadow-lg p-6">
              <h2 className="text-2xl font-bold mb-6 flex items-center">
                <span className="mr-3">{translate('proTips.icon')}</span>
                {translate('proTips.title')}
              </h2>
              <div className="grid md:grid-cols-2 gap-6">
                {tips.map(renderTip)}
              </div>
            </div>

            {/* Common Mistakes Section */}
            <div className="mb-12 mt-12">
              <h2 className="text-2xl font-bold mb-6 text-purple-800 text-center">{translate('commonMistakes.title')}</h2>
              <div className="grid gap-4 md:grid-cols-2">
                {mistakes.map(renderMistake)}
              </div>
            </div>

            {/* Button to Calculator */}
            <div className="text-center mt-12">
              <button
                onClick={() => navigate('/')}
                className="inline-flex items-center bg-white border border-purple-600 text-purple-600 text-lg font-semibold px-8 py-3 rounded-lg transition-colors group"
              >
                <span>{translate('calculator.buttonText')}</span>
                <svg
                  className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MeasuringGuide;
