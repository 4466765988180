import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslations } from '../hooks/useTranslations';
import { getCanonicalUrl } from '../utils/seo';

const Contact: React.FC = () => {
  const { translate } = useTranslations('contact');
  
  const defaultOptions = [
    { value: '', label: 'Select a subject' },
    { value: 'measurement-help', label: 'Help with Measurements' },
    { value: 'calculator-issue', label: 'Calculator Issues' },
    { value: 'size-recommendation', label: 'Size Recommendation' },
    { value: 'other', label: 'Other' }
  ];

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
    alert(translate('form.submit.success'));
  };

  return (
    <>
      <Helmet>
        <title>{translate('meta.title')}</title>
        <meta name="description" content={translate('meta.description')} />
        <meta name="keywords" content={translate('meta.keywords')} />
        <link rel="canonical" href={getCanonicalUrl('/contact')} />
      </Helmet>
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-3xl mx-auto">
            <h1 className="text-4xl font-bold text-center mb-8">{translate('title')}</h1>
          
            <div className="bg-white rounded-xl shadow-lg p-8 mb-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
              <div>

                <h2 className="text-xl font-semibold mb-4">{translate('getInTouch.title')}</h2>
                <p className="text-gray-600 mb-4">{translate('getInTouch.description')}</p>
                <div className="space-y-4">
                    <div className="flex items-start">
                    <svg className="w-6 h-6 text-purple-600 mt-1 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                    <div>
                        <h3 className="font-medium">{translate('getInTouch.contact.email.label')}</h3>
                        {/* Email removed for security */}
                    </div>
                    </div>
                  <div className="flex items-start">
                    <svg className="w-6 h-6 text-purple-600 mt-1 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <div>
                        <h3 className="font-medium">{translate('getInTouch.contact.responseTime.label')}</h3>
                        <p className="text-gray-600">{translate('getInTouch.contact.responseTime.value')}</p>
                    </div>
                  </div>
                </div>
              </div>
              
              <div>
                <h2 className="text-xl font-semibold mb-4">{translate('faq.title')}</h2>
                <div className="space-y-4">
                  <div>
                    <h3 className="font-medium">{translate('faq.questions.0.question')}</h3>
                    <p className="text-gray-600">{translate('faq.questions.0.answer')}</p>
                  </div>
                  <div>
                    <h3 className="font-medium">{translate('faq.questions.1.question')}</h3>
                    <p className="text-gray-600">{translate('faq.questions.1.answer')}</p>
                  </div>
                </div>
              </div>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <p className="text-sm text-gray-500 mb-4">
                {translate('form.required')} <span className="text-red-500">*</span>
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                    {translate('form.fields.name.label')} <span className="text-red-500">*</span>
                      </label>
                      <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    placeholder={translate('form.fields.name.placeholder')}
                  />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                    {translate('form.fields.email.label')} <span className="text-red-500">*</span>
                      </label>
                      <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    placeholder={translate('form.fields.email.placeholder')}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
                    {translate('form.fields.subject.label')} <span className="text-red-500">*</span>
                  </label>
                  <select
                    id="subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    >
                    {defaultOptions.map((option: { value: string; label: string }) => (
                      <option key={option.value} value={option.value}>
                      {option.label}
                      </option>
                    ))}
                </select>
              </div>

              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                    {translate('form.fields.message.label')} <span className="text-red-500">*</span>
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    rows={4}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    placeholder={translate('form.fields.message.placeholder')}
                />
              </div>

              <div className="text-right">
                <button
                  type="submit"
                  className="inline-flex items-center px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 transition-colors"
                >
                    {translate('form.submit.text')}
                  <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                  </svg>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;

