import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslations } from '../hooks/useTranslations';

const NotFound: React.FC = () => {
  const { translate } = useTranslations('common');

  return (
    <div className="min-h-[60vh] flex flex-col items-center justify-center px-4 py-16">
      <h1 className="text-4xl font-bold mb-4">{translate('404.title', { defaultValue: '404 - Page Not Found' })}</h1>
      <p className="text-lg mb-8 text-center">
        {translate('404.description', { defaultValue: 'The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.' })}
      </p>
      <Link
        to="/"
        className="bg-primary text-white px-6 py-3 rounded-lg hover:bg-primary-dark transition-colors"
      >
        {translate('404.backHome', { defaultValue: 'Back to Home' })}
      </Link>
    </div>
  );
};

export default NotFound; 