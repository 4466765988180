import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { cupSizes, bandSizes, SizingSystem, sizeConversions } from '../data/sizingConverterData';
import type converterTranslations from '../i18n/locales/en/converter.json';
import { routes } from '../config/routes';

type ConverterTranslations = typeof converterTranslations;

interface TranslatedData {
  ready: boolean;
  title: string;
  description: string;
  form: {
    sizeSystem: {
      label: string;
      options: {
        US: string;
        UK: string;
        EU: string;
        FR_ES: string;
        IT: string;
        AU_NZ: string;
        JP: string;
      };
    };
    bandSize: {
      label: string;
      placeholder: string;
    };
    cupSize: {
      label: string;
      placeholder: string;
    };
  };
  results: {
    title: string;
    emptyState: {
      initial: string;
      incomplete: string;
    };
  };
  cta: {
    text: string;
    arrow: string;
  };
}

const defaultTranslations: TranslatedData = {
  ready: false,
  title: 'Quick Size Converter',
  description: 'Convert your bra size between different sizing systems',
  form: {
    sizeSystem: {
      label: 'Size System',
      options: {
        US: 'US',
        UK: 'UK',
        EU: 'EU',
        FR_ES: 'FR/ES',
        IT: 'IT',
        AU_NZ: 'AU/NZ',
        JP: 'JP'
      }
    },
    bandSize: {
      label: 'Band Size',
      placeholder: 'Select band size'
    },
    cupSize: {
      label: 'Cup Size',
      placeholder: 'Select cup size'
    }
  },
  results: {
    title: 'Size Conversions',
    emptyState: {
      initial: 'Select your size to see conversions',
      incomplete: 'Please select both band and cup size'
    }
  },
  cta: {
    text: 'View detailed size converter',
    arrow: '→'
  }
};

const useTranslatedData = (): TranslatedData => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { t, ready, i18n } = useTranslation('converter', { useSuspense: false }) as { t: (key: string, options?: any) => string; ready: boolean; i18n: any };

  // Ensure the converter namespace is loaded
  React.useEffect(() => {
    if (!i18n.hasLoadedNamespace('converter')) {
      i18n.loadNamespaces('converter');
    }
  }, [i18n]);

  if (!ready || !i18n.hasLoadedNamespace('converter')) {
    return defaultTranslations;
  }

  try {
    return {
      ready: true,
      title: t('quickConverter.title', { defaultValue: defaultTranslations.title }),
      description: t('quickConverter.description', { defaultValue: defaultTranslations.description }),
      form: {
        sizeSystem: {
          label: t('quickConverter.form.sizeSystem.label', { defaultValue: defaultTranslations.form.sizeSystem.label }),
          options: defaultTranslations.form.sizeSystem.options
        },
        bandSize: {
          label: t('quickConverter.form.bandSize.label', { defaultValue: defaultTranslations.form.bandSize.label }),
          placeholder: t('quickConverter.form.bandSize.placeholder', { defaultValue: defaultTranslations.form.bandSize.placeholder })
        },
        cupSize: {
          label: t('quickConverter.form.cupSize.label', { defaultValue: defaultTranslations.form.cupSize.label }),
          placeholder: t('quickConverter.form.cupSize.placeholder', { defaultValue: defaultTranslations.form.cupSize.placeholder })
        }
      },
      results: {
        title: t('quickConverter.results.title', { defaultValue: defaultTranslations.results.title }),
        emptyState: {
          initial: t('quickConverter.results.emptyState.initial', { defaultValue: defaultTranslations.results.emptyState.initial }),
          incomplete: t('quickConverter.results.emptyState.incomplete', { defaultValue: defaultTranslations.results.emptyState.incomplete })
        }
      },
      cta: {
        text: t('quickConverter.cta.text', { defaultValue: defaultTranslations.cta.text }),
        arrow: defaultTranslations.cta.arrow
      }
    };
  } catch (error) {
    console.error('Failed to load translations:', error);
    return defaultTranslations;
  }
};

const QuickConverter: React.FC = () => {
  const {
    ready,
    title,
    description,
    form,
    results,
    cta
  } = useTranslatedData();
  const { i18n } = useTranslation();

  const [currentSize, setCurrentSize] = useState({
    band: '',
    cup: '',
    system: 'US' as SizingSystem
  });

  const convertSize = (size: { band: string; cup: string; system: SizingSystem }) => {
    if (!size.band || !size.cup) return null;
    
    const bandSize = parseInt(size.band);
    if (isNaN(bandSize)) return null;

    const conversion = sizeConversions.find(conv => conv[size.system] === bandSize);
    if (!conversion) return null;

    const cupIndex = cupSizes[size.system].indexOf(size.cup);
    if (cupIndex === -1) return null;

    return Object.entries(conversion).reduce((acc, [system, band]) => {
      if (system === 'JP' && cupIndex > cupSizes.JP.length - 1) {
        acc[system] = 'N/A';
      } else {
        acc[system] = `${band}${cupSizes[system as SizingSystem][cupIndex]}`;
      }
      return acc;
    }, {} as Record<string, string>);
  };

  const conversions = currentSize.band && currentSize.cup ? convertSize(currentSize) : null;

  if (!ready) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-pulse text-purple-600 text-xl">Loading...</div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xl border border-gray-100 p-8 max-w-5xl mx-auto">
      <div className="text-center mb-8">
        <h2 className="text-2xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
          {title}
        </h2>
        <p className="text-gray-600">
          {description}
        </p>
      </div>
      
      <div className="grid md:grid-cols-3 gap-4 mb-8">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            {form.sizeSystem.label}
          </label>
          <select
            className="w-full p-3 border rounded-lg bg-white hover:border-purple-500 focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-colors"
            value={currentSize.system}
            onChange={(e) => setCurrentSize(prev => ({ ...prev, system: e.target.value as SizingSystem }))}
          >
            {Object.keys(bandSizes).map((system) => (
              <option key={system} value={system}>
                {form.sizeSystem.options[system as keyof typeof form.sizeSystem.options]}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            {form.bandSize.label}
          </label>
          <select
            className="w-full p-3 border rounded-lg bg-white hover:border-purple-500 focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-colors"
            value={currentSize.band}
            onChange={(e) => setCurrentSize(prev => ({ ...prev, band: e.target.value }))}
          >
            <option value="">{form.bandSize.placeholder}</option>
            {bandSizes[currentSize.system].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            {form.cupSize.label}
          </label>
          <select
            className="w-full p-3 border rounded-lg bg-white hover:border-purple-500 focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-colors"
            value={currentSize.cup}
            onChange={(e) => setCurrentSize(prev => ({ ...prev, cup: e.target.value }))}
          >
            <option value="">{form.cupSize.placeholder}</option>
            {cupSizes[currentSize.system].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>
      </div>

      {conversions ? (
        <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-6">
          <h3 className="font-semibold mb-4 text-lg">{results.title}</h3>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {Object.entries(conversions).map(([system, size]) => (
              <div key={system} className="bg-white rounded-lg p-4 shadow-sm">
                <div className="text-gray-800 mb-1">
                  {form.sizeSystem.options[system as keyof typeof form.sizeSystem.options]}
                </div>
                <div className="text-xl text-purple-600 font-bold">{size}</div>
              </div>
            ))}
          </div>
        </div>
      ) : currentSize.band || currentSize.cup ? (
        <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-6">
          <div className="text-center text-gray-500">
            <p>{results.emptyState.incomplete}</p>
          </div>
        </div>
      ) : (
        <div className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-lg p-6">
          <div className="text-center text-gray-500">
            <svg className="w-12 h-12 mx-auto mb-4 text-gray-400" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
            </svg>
            <p>{results.emptyState.initial}</p>
          </div>
        </div>
      )}

      <div className="mt-6 text-center">
        <Link
          to={routes.sizeConverter[i18n.language as keyof typeof routes.sizeConverter]}
          className="text-purple-600 hover:text-purple-700 font-medium"
        >
          {cta.text} {cta.arrow}
        </Link>
      </div>
    </div>
  );
};

export default QuickConverter;
