import React, { useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslations } from '../hooks/useTranslations';
import LanguageSwitcher from './LanguageSwitcher';
import { routes, getLocalizedPath, getLanguageFromPath, SupportedLanguage } from '../config/routes';

type RouteKey = 'home' | 'sizeConverter' | 'sizeCharts' | 'sisterSizes' | 
                'measuringGuide' | 'fittingGuide' | 'breastShapes' | 'braTypes' | 
                'fitIssues' | 'faq' | 'contact';

interface NavLink {
  key: 'home' | RouteKey;
  translationKey: string;
  icon: JSX.Element;
}

const Navbar: React.FC = () => {
  const { translate, i18n } = useTranslations('common');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const currentLanguage = getLanguageFromPath(location.pathname);

  const getRouteForLanguage = (routeKey: RouteKey | 'home', lang: SupportedLanguage): string => {
    const route = routes[routeKey];
    if (route && typeof route === 'object' && lang in route) {
      return route[lang];
    }
    return '/';
  };

  const getCalculatorPath = (lang: SupportedLanguage): string => {
    const homePath = getRouteForLanguage('home', lang);
    return `${homePath}#calculator`;
  };

  const navLinks: NavLink[] = useMemo(() => [
    { 
      key: 'home',
      translationKey: 'navbar.links.calculator.text',
      icon: (
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
        </svg>
      )
    },
    { 
      key: 'sizeConverter',
      translationKey: 'navbar.links.sizeConverter.text',
      icon: (
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
        </svg>
      )
    },
    { 
      key: 'sizeCharts',
      translationKey: 'navbar.links.sizeCharts.text',
      icon: (
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
        </svg>
      )
    },
    { 
      key: 'sisterSizes',
      translationKey: 'navbar.links.sisterSizes.text',
      icon: (
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
        </svg>
      )
    },
    { 
      key: 'measuringGuide',
      translationKey: 'navbar.links.measuringGuide.text',
      icon: (
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
        </svg>
      )
    },
    { 
      key: 'breastShapes',
      translationKey: 'navbar.links.breastShapes.text',
      icon: (
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
        </svg>
      )
    },
    { 
      key: 'braTypes',
      translationKey: 'navbar.links.braTypes.text',
      icon: (
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
        </svg>
      )
    },
    { 
      key: 'fitIssues',
      translationKey: 'navbar.links.fitIssues.text',
      icon: (
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      )
    }
  ], []);

  // Handle language change to update current URL
  const handleLanguageChange = (newLanguage: string) => {
    console.log('Navbar handling language change:', newLanguage);
    console.log('Current location:', location.pathname);
    
    const newPath = getLocalizedPath(location.pathname, newLanguage as SupportedLanguage);
    console.log('New path from Navbar:', newPath);
    
    // Only update if the path has changed
    if (newPath !== location.pathname) {
      i18n.changeLanguage(newLanguage);
      window.location.href = newPath;
    }
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          {/* Logo */}
          <div className="flex items-center">
            <Link to={getRouteForLanguage('home', currentLanguage)} className="flex items-center flex-shrink-0">
              <img 
                src="/images/bra-size-calculator-icon.png"
                alt={translate('header.logo.alt')}
                className="w-7 h-7 mr-2"
              />
              <span className="text-xl font-bold" style={{ color: '#b52eb3' }}>
                {translate('header.logo.title')}
              </span>
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden lg:flex lg:items-center lg:justify-end space-x-2">
            {navLinks.map((link) => (
              link.key === 'home' ? (
                <button
                  key={link.key}
                  onClick={() => {
                    if (location.pathname !== getRouteForLanguage('home', currentLanguage)) {
                      window.location.href = getCalculatorPath(currentLanguage);
                    } else {
                      setTimeout(() => {
                        const calculatorSection = document.getElementById('Enhanced Bra Size Calculator');
                        if (calculatorSection) {
                          calculatorSection.scrollIntoView({ behavior: 'smooth' });
                        }
                      }, 100);
                    }
                  }}
                  className="text-gray-600 hover:text-purple-600 px-2 py-1 text-sm font-medium capitalize tracking-wide transition-colors duration-200 whitespace-pre-line text-center min-w-[80px] h-14 flex items-center justify-center"
                >
                  {translate(link.translationKey)}
                </button>
              ) : (
                <Link
                  key={link.key}
                  to={getRouteForLanguage(link.key, currentLanguage)}
                  className="text-gray-600 hover:text-purple-600 px-2 py-1 text-sm font-medium capitalize tracking-wide transition-colors duration-200 whitespace-pre-line text-center min-w-[80px] h-14 flex items-center justify-center"
                >
                  {translate(link.translationKey)}
                </Link>
              )
            ))}
            <div className="ml-3 border-l pl-3">
              <LanguageSwitcher onLanguageChange={handleLanguageChange} />
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="flex items-center lg:hidden">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500"
              aria-expanded="false"
            >
              <span className="sr-only">{translate('header.menu.open')}</span>
              {/* Icon */}
              <svg
                className={`${isMobileMenuOpen ? 'hidden' : 'block'} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              {/* Close icon */}
              <svg
                className={`${isMobileMenuOpen ? 'block' : 'hidden'} h-6 w-6`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`${isMobileMenuOpen ? 'block' : 'hidden'} lg:hidden`}>
        <div className="px-2 pt-2 pb-3">
          <div className="space-y-1 mb-3">
            {navLinks.map((link) => (
              link.key === 'home' ? (
                <button
                  key={link.key}
                  onClick={() => {
                    if (location.pathname !== getRouteForLanguage('home', currentLanguage)) {
                      window.location.href = getCalculatorPath(currentLanguage);
                    } else {
                      setTimeout(() => {
                        const calculatorSection = document.getElementById('Enhanced Bra Size Calculator');
                        if (calculatorSection) {
                          calculatorSection.scrollIntoView({ behavior: 'smooth' });
                        }
                      }, 100);
                    }
                    setIsMobileMenuOpen(false);
                  }}
                  className="text-gray-600 hover:text-purple-600 block px-3 py-2 text-base font-medium border-l-4 border-transparent hover:border-purple-600 flex items-center capitalize tracking-wide w-full text-left"
                >
                  <span className="mr-3">{link.icon}</span>
                  {translate(link.translationKey).replace('\n', ' ')}
                </button>
              ) : (
                <Link
                  key={link.key}
                  to={getRouteForLanguage(link.key, currentLanguage)}
                  className="text-gray-600 hover:text-purple-600 block px-3 py-2 text-base font-medium border-l-4 border-transparent hover:border-purple-600 flex items-center capitalize tracking-wide"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  <span className="mr-3">{link.icon}</span>
                  {translate(link.translationKey).replace('\n', ' ')}
                </Link>
              )
            ))}
          </div>
          <div className="border-t border-gray-200 pt-3">
            <div className="w-full">
              <LanguageSwitcher onLanguageChange={(lang) => {
                handleLanguageChange(lang);
                setIsMobileMenuOpen(false);
              }} />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;