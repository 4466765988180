import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { getCanonicalUrl } from '../utils/seo';
import type { TFunction } from 'i18next';

interface ImpactStat {
  title: string;
  value: string;
}

interface TranslatedData {
  ready: boolean;
  metaData: {
    title: string;
    description: string;
  };
  siteName: string;
  title: string;
  mission: {
    title: string;
    description: string;
  };
  purpose: {
    title: string;
    points: string[];
  };
  impact: {
    title: string;
    description: string;
    stats: ImpactStat[];
  };
  commitment: {
    title: string;
    description: string;
  };
  future: {
    title: string;
    description: string;
  };
}

type SimpleTranslationFunction = (key: string, defaultValue?: string) => string;
type ArrayTranslationFunction = (key: string, options: { returnObjects: true, defaultValue: never[] }) => any[];

const useTranslatedData = (): TranslatedData => {
  const { t: aboutUsTRaw, ready: aboutReady } = useTranslation('aboutUs', { useSuspense: false });
  const { t: commonTRaw, ready: commonReady } = useTranslation('common', { useSuspense: false });
  const ready = aboutReady && commonReady;

  // Type assertions to simplify the translation function types
  const aboutUsT = aboutUsTRaw as SimpleTranslationFunction;
  const commonT = commonTRaw as SimpleTranslationFunction;
  const aboutUsArrayT = aboutUsTRaw as ArrayTranslationFunction;

  return React.useMemo(() => {
    if (!ready) {
      return {
        ready: false,
        metaData: { title: '', description: '' },
        siteName: '',
        title: '',
        mission: { title: '', description: '' },
        purpose: { title: '', points: [] },
        impact: { title: '', description: '', stats: [] },
        commitment: { title: '', description: '' },
        future: { title: '', description: '' }
      };
    }

    const purposePoints = aboutUsArrayT('purpose.points', { returnObjects: true, defaultValue: [] });
    const impactStats = aboutUsArrayT('impact.stats', { returnObjects: true, defaultValue: [] });

    return {
      ready,
      metaData: {
        title: aboutUsT('meta.title', ''),
        description: aboutUsT('meta.description', '')
      },
      siteName: commonT('siteName', ''),
      title: aboutUsT('title', ''),
      mission: {
        title: aboutUsT('mission.title', ''),
        description: aboutUsT('mission.description', '')
      },
      purpose: {
        title: aboutUsT('purpose.title', ''),
        points: purposePoints as string[]
      },
      impact: {
        title: aboutUsT('impact.title', ''),
        description: aboutUsT('impact.description', ''),
        stats: impactStats as ImpactStat[]
      },
      commitment: {
        title: aboutUsT('commitment.title', ''),
        description: aboutUsT('commitment.description', '')
      },
      future: {
        title: aboutUsT('future.title', ''),
        description: aboutUsT('future.description', '')
      }
    };
  }, [ready, aboutUsT, commonT, aboutUsArrayT]);
};

export default function AboutUs() {
  const location = useLocation();
  const {
    ready,
    metaData,
    siteName,
    title,
    mission,
    purpose,
    impact,
    commitment,
    future
  } = useTranslatedData();

  if (!ready) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-pulse text-purple-600 text-xl">Loading...</div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${metaData.title} | ${siteName}`}</title>
        <meta name="description" content={metaData.description} />
        <link rel="canonical" href={getCanonicalUrl(location.pathname)} />
      </Helmet>

      <div className="container mx-auto px-4 py-8 max-w-4xl">
        <h1 className="text-3xl font-bold text-purple-900 mb-8">
          {title}
        </h1>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-purple-800 mb-4">
            {mission.title}
          </h2>
          <p className="text-gray-700 leading-relaxed mb-4">
            {mission.description}
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-purple-800 mb-4">
            {purpose.title}
          </h2>
          <div className="space-y-4">
            {purpose.points.map((point, index) => (
              <div key={index} className="flex items-start">
                <span className="text-purple-500 mr-2">•</span>
                <p className="text-gray-700 leading-relaxed">{point}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-purple-800 mb-4">
            {impact.title}
          </h2>
          <p className="text-gray-700 leading-relaxed mb-4">
            {impact.description}
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
            {impact.stats.map((stat, index) => (
              <div key={index} className="bg-purple-50 p-6 rounded-lg text-center">
                <div className="text-2xl font-bold text-purple-900 mb-2">{stat.value}</div>
                <div className="text-purple-700">{stat.title}</div>
              </div>
            ))}
          </div>
        </section>

        <section className="mb-12">
          <h2 className="text-2xl font-semibold text-purple-800 mb-4">
            {commitment.title}
          </h2>
          <p className="text-gray-700 leading-relaxed">
            {commitment.description}
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold text-purple-800 mb-4">
            {future.title}
          </h2>
          <p className="text-gray-700 leading-relaxed">
            {future.description}
          </p>
        </section>
      </div>
    </>
  );
}