import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useTranslations } from '../hooks/useTranslations';
import { getCanonicalUrl } from '../utils/seo';
import type { LegalTranslations } from '../types/legalTypes';

const TermsOfService: React.FC = () => {
  const location = useLocation();
  const { translateObject, ready, i18n } = useTranslations('legal');

  // Force load legal namespace
  React.useEffect(() => {
    if (!i18n.hasLoadedNamespace('legal')) {
        i18n.loadNamespaces(['legal']).catch((error: Error) => {
        console.error('Error loading legal namespace:', error);
      });
    }
  }, [i18n]);

  const defaultMeta: LegalTranslations['termsOfService']['meta'] = {
    title: '',
    description: ''
  };

  const defaultSections: LegalTranslations['termsOfService']['sections'] = {
    introduction: { title: '', content: '' },
    agreement: {
      title: '',
      content: '',
      importance: 'high'
    },
    services: {
      title: '',
      content: '',
      items: []
    },
    intellectualProperty: {
      title: '',
      content: '',
      importance: 'medium'
    },
    userAccounts: {
      title: '',
      content: ''
    },
    prohibited: {
      title: '',
      content: '',
      items: []
    },
    disclaimer: {
      title: '',
      content: '',
      importance: 'high'
    },
    limitation: {
      title: '',
      content: ''
    }
  };


  const defaultCommon: LegalTranslations['common'] = {
    lastUpdated: '',
    tableOfContents: '',
    contactUs: {
      text: '',
      linkText: ''
    }
  };

  const metaData = translateObject('termsOfService.meta', defaultMeta) || defaultMeta;
  const sections = translateObject('termsOfService.sections', defaultSections) || defaultSections;
  const common = translateObject('common', defaultCommon) || defaultCommon;

  if (!ready) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-pulse text-purple-600 text-xl">Loading translations...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <Helmet>
      <title>{metaData?.title || 'Terms of Service'}</title>
      <meta name="description" content={metaData?.description || ''} />
      <link rel="canonical" href={getCanonicalUrl(location.pathname)} />
      </Helmet>

      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="bg-white rounded-2xl shadow-sm p-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">
          {translateObject('termsOfService.title', 'Terms of Service')}
        </h1>
        
        <div className="prose prose-purple max-w-none">
        {/* Table of Contents */}
        <div className="mb-8 p-4 bg-gray-50 rounded-lg">
          <h2 className="text-xl font-semibold mb-4">{common?.tableOfContents}</h2>
          <ul className="space-y-2">
          <li><a href="#agreement" className="text-purple-600 hover:text-purple-800">{sections?.agreement?.title}</a></li>
          <li><a href="#services" className="text-purple-600 hover:text-purple-800">{sections?.services?.title}</a></li>
          <li><a href="#intellectual-property" className="text-purple-600 hover:text-purple-800">{sections?.intellectualProperty?.title}</a></li>
          <li><a href="#user-accounts" className="text-purple-600 hover:text-purple-800">{sections?.userAccounts?.title}</a></li>
          <li><a href="#prohibited" className="text-purple-600 hover:text-purple-800">{sections?.prohibited?.title}</a></li>
          <li><a href="#disclaimer" className="text-purple-600 hover:text-purple-800">{sections?.disclaimer?.title}</a></li>
          <li><a href="#limitation" className="text-purple-600 hover:text-purple-800">{sections?.limitation?.title}</a></li>
          </ul>
        </div>

        {/* Agreement */}
        <section id="agreement" className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">{sections?.agreement?.title || ''}</h2>
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
          <p className="text-yellow-700">{sections?.agreement?.content || ''}</p>
          </div>
        </section>

        {/* Services */}
        <section id="services" className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">{sections?.services?.title || ''}</h2>
          <p className="text-gray-600 mb-4">{sections?.services?.content || ''}</p>
          {sections?.services?.items && sections?.services?.items?.length > 0 && (
          <div className="bg-gray-50 p-4 rounded-lg">
            <ul className="list-disc pl-5 space-y-2">
            {sections?.services?.items?.map((item, index) => (
              <li key={index} className="text-gray-700">{item || ''}</li>
            ))}
            </ul>
          </div>
          )}
        </section>

        {/* Intellectual Property */}
        <section id="intellectual-property" className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">{sections?.intellectualProperty?.title || ''}</h2>
          <div className="bg-blue-50 border-l-4 border-blue-400 p-4">
          <p className="text-blue-700">{sections?.intellectualProperty?.content || ''}</p>
          </div>
        </section>

        {/* User Accounts */}
        <section id="user-accounts" className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">{sections?.userAccounts?.title || ''}</h2>
          <p className="text-gray-600 mb-4">{sections?.userAccounts?.content || ''}</p>
        </section>

        {/* Prohibited Activities */}
        <section id="prohibited" className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">{sections?.prohibited?.title || ''}</h2>
          <p className="text-gray-600 mb-4">{sections?.prohibited?.content || ''}</p>
          {sections?.prohibited?.items && sections?.prohibited?.items?.length > 0 && (
          <div className="bg-red-50 border-l-4 border-red-400 p-4">
            <ul className="list-disc pl-5 space-y-2">
            {sections?.prohibited?.items?.map((item, index) => (
              <li key={index} className="text-red-700">{item || ''}</li>
            ))}
            </ul>
          </div>
          )}
        </section>

        {/* Disclaimer */}
        <section id="disclaimer" className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">{sections?.disclaimer?.title || ''}</h2>
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
          <p className="text-yellow-700">{sections?.disclaimer?.content || ''}</p>
          </div>
        </section>

        {/* Limitation */}
        <section id="limitation" className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">{sections?.limitation?.title || ''}</h2>
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
          <p className="text-yellow-700">{sections?.limitation?.content || ''}</p>
          </div>
        </section>

        {/* Footer */}
        <div className="mt-8 pt-8 border-t border-gray-200">
          <p className="text-sm text-gray-500">{common?.lastUpdated || ''}</p>
          <div className="mt-4">
          <p className="text-gray-600">
            {common?.contactUs?.text}{' '}
            <a href="/contact" className="text-purple-600 hover:text-purple-800">
            {common?.contactUs?.linkText}
            </a>
          </p>
          </div>
        </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default TermsOfService;
