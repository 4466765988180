import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import type braTypesTranslations from '../i18n/locales/en/braTypes.json';
import { getCanonicalUrl } from '../utils/seo';
import { motion } from 'framer-motion';

// Type your data using the JSON import
type BraTypesTranslations = typeof braTypesTranslations;

const useTranslatedData = () => {
  const { t, ready } = useTranslation('braTypes', { useSuspense: false });

  // Cast t to a function that accepts a string key
  const translate = t as (key: string) => any;

  const getTranslation = <T extends keyof BraTypesTranslations>(key: T): BraTypesTranslations[T] => {
    const translation = translate(key);
    if (!translation && !ready) {
      return {} as BraTypesTranslations[T];
    }
    return translation as BraTypesTranslations[T];
  };

  return {
    ready,
    metaData: getTranslation('meta'),
    heroData: getTranslation('hero'),
    braTypesData: getTranslation('braTypes'),
    scienceBehindSizingData: getTranslation('scienceBehindSizing'),
    understandingSizeData: getTranslation('understandingSize'),
    personalRangeData: getTranslation('personalRange'),
    choosingGuideData: getTranslation('choosingGuide'),
  };
};

const BraTypesGuide: React.FC = () => {
  const location = useLocation();
  const [expandedSection, setExpandedSection] = useState<string>('');
  const {
    ready,
    metaData,
    heroData,
    braTypesData,
    scienceBehindSizingData,
    understandingSizeData,
    personalRangeData,
    choosingGuideData,
  } = useTranslatedData();

  if (!ready || !metaData?.title || !heroData?.title) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-pulse text-purple-600 text-xl">Loading...</div>
      </div>
    );
  }

  const braTypeIds = [
    'plunge', 'pushup', 'underwired', 'fullcup', 'halfcup', 'triangle',
    'minimizer', 'sports', 'bralette', 'stickon', 'bandeau', 'corset',
    'racerback', 'lace', 'nursing', 'convertible', 'multiway'
  ] as const;

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <meta name="keywords" content={metaData.keywords} />
        <meta property="og:title" content={metaData.title} />
        <meta property="og:description" content={metaData.description} />
        <link rel="canonical" href={getCanonicalUrl(location.pathname)} />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
            {heroData.title}
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            {heroData.description}
          </p>
        </motion.div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {braTypeIds.map((braTypeId) => {
            const braType = braTypesData[braTypeId];
            if (!braType) return null;
            
            return (
              <div
                key={braTypeId}
              className="bg-white rounded-xl shadow overflow-hidden transition-all duration-300 hover:shadow-md"
            >
              <div className="p-6">
                <div
                  className="flex justify-between items-center cursor-pointer"
                      onClick={() => setExpandedSection(expandedSection === braTypeId ? '' : braTypeId)}
                    >
                      <h3 className="text-xl font-semibold text-purple-900">
                        {braType.title}
                      </h3>
                      {expandedSection === braTypeId ? (
                    <ChevronUp className="w-5 h-5 text-purple-600" />
                  ) : (
                    <ChevronDown className="w-5 h-5 text-purple-600" />
                  )}
                </div>

                <div
                  className={`mt-4 transition-all duration-300 ${
                        expandedSection === braTypeId ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
                  }`}
                >
                      <p className="text-gray-600 mb-4">
                        {braType.description}
                      </p>
                  
                  <div className="bg-purple-50 rounded-lg p-4 mb-4">
                        <h4 className="font-semibold text-purple-900 mb-2">
                          {scienceBehindSizingData.factors.construction.title}
                        </h4>
                        <p className="text-gray-600">
                          {braType.impact}
                        </p>
                  </div>

                  <div className="bg-gray-50 rounded-lg p-4">
                        <h4 className="font-semibold text-purple-900 mb-2">
                          {scienceBehindSizingData.factors.materials.title}
                        </h4>
                    <ul className="space-y-2">
                      {braType.sizingTips.map((tip, index) => (
                        <li key={index} className="text-gray-600 flex items-start">
                          <span className="text-purple-500 mr-2">•</span>
                          {tip}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            );
          })}
        </div>

        <div className="bg-white rounded-xl max-w-4xl mx-auto shadow-sm p-8 mb-12 mt-8">
          <h2 className="text-2xl font-semibold text-center text-purple-900 mb-6">
            {scienceBehindSizingData.title}
          </h2>
          <p className="text-gray-600 mb-6">
            {scienceBehindSizingData.description}
          </p>
          <div className="grid md:grid-cols-2 gap-6 mb-8">
          <div className="bg-white rounded-xl p-6 border border-purple-500">
              <h3 className="text-xl font-semibold text-purple-900 mb-4">
                {scienceBehindSizingData.factors.construction.title}
              </h3>
              <p className="text-gray-600">
                {scienceBehindSizingData.factors.construction.description}
              </p>
              </div>

              <div className="bg-white rounded-xl p-6 border border-purple-500">
              <h3 className="text-xl font-semibold text-purple-900 mb-4">
                {scienceBehindSizingData.factors.materials.title}
              </h3>
              <p className="text-gray-600">
                {scienceBehindSizingData.factors.materials.description}
              </p>
              </div>
          </div>

          <h3 className="text-xl font-semibold text-purple-900 mb-4 mt-12">
            {understandingSizeData.title}
          </h3>
          <div className="grid md:grid-cols-3 gap-6 mb-8">
            <div className="bg-purple-50 rounded-lg p-6">
              <h4 className="font-semibold text-purple-900 mb-3">
                {personalRangeData.considerations.bodyChanges.title}
              </h4>
              <p className="text-gray-600">
                {personalRangeData.considerations.bodyChanges.description}
              </p>
            </div>
            <div className="bg-purple-50 rounded-lg p-6">
              <h4 className="font-semibold text-purple-900 mb-3">
                {personalRangeData.considerations.activityLevel.title}
              </h4>
              <p className="text-gray-600">
                {personalRangeData.considerations.activityLevel.description}
              </p>
            </div>
            <div className="bg-purple-50 rounded-lg p-6">
              <h4 className="font-semibold text-purple-900 mb-3">
                {personalRangeData.considerations.comfort.title}
              </h4>
              <p className="text-gray-600">
                {personalRangeData.considerations.comfort.description}
              </p>
            </div>
          </div>

          <div className="bg-purple-100 rounded-lg p-6 mb-8">
            <h3 className="text-xl font-semibold text-purple-900 mb-4">
              {personalRangeData.title}
            </h3>
            <p className="text-gray-600">
              {personalRangeData.description}
            </p>
          </div>

<div className="bg-gray-100 rounded-xl p-6 mb-8">
            <h3 className="text-xl font-semibold text-purple-900 mb-4">
              {choosingGuideData.title}
            </h3>
            <p className="text-gray-600 mb-4">
              {choosingGuideData.description}
    </p>
  </div>
        </div>
            
        <div className="mt-12 max-w-4xl mx-auto bg-white rounded-xl shadow p-8">
          <h2 className="text-2xl font-semibold text-center text-purple-900 mb-4">
            {choosingGuideData.title}
          </h2>
          <p className="text-gray-600 mb-6">
            {choosingGuideData.description}
          </p>
          <div className="grid md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div className="bg-purple-50 rounded-lg p-4">
                <h3 className="font-semibold text-purple-800 mb-2">
                  {choosingGuideData.occasions.everyday.title}
                </h3>
                <p className="text-gray-600">
                  {choosingGuideData.occasions.everyday.description}
                </p>
              </div>
              <div className="bg-purple-50 rounded-lg p-4">
                <h3 className="font-semibold text-purple-800 mb-2">
                  {choosingGuideData.occasions.special.title}
                </h3>
                <p className="text-gray-600">
                  {choosingGuideData.occasions.special.description}
                </p>
              </div>
            </div>
            <div className="space-y-4">
              <div className="bg-purple-50 rounded-lg p-4">
                <h3 className="font-semibold text-purple-800 mb-2">
                  {choosingGuideData.occasions.active.title}
                </h3>
                <p className="text-gray-600">
                  {choosingGuideData.occasions.active.description}
                </p>
              </div>
              <div className="bg-purple-50 rounded-lg p-4">
                <h3 className="font-semibold text-purple-800 mb-2">
                  {choosingGuideData.occasions.comfort.title}
                </h3>
                <p className="text-gray-600">
                  {choosingGuideData.occasions.comfort.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BraTypesGuide;
