import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { AlertTriangle, AlertCircle, CheckCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getCanonicalUrl } from '../utils/seo';
import {
  SizingSystem,
  SizeType,
  bandSizes,
  cupSizes,
  calculateSisterSizes,
  convertSize
} from '../data/sizingSisterData';

// Define the translation interface
interface SisterSizesTranslations {
  meta: {
    title: string;
    description: string;
  };
  hero: {
    title: string;
    description: string;
  };
  understanding: {
    title: string;
    description: string;
    goodUses: {
      title: string;
      items: string[];
    };
    limitations: {
      title: string;
      items: string[];
    };
  };
  calculator: {
    title: string;
    form: {
      sizingSystem: {
        label: string;
        options: Record<string, string>;
      };
      bandSize: {
        label: string;
      };
      cupSize: {
        label: string;
      };
    };
  };
  results: {
    title: string;
    sizeTypes: {
      smaller: {
        description: string;
      };
      current: {
        description: string;
      };
      larger: {
        description: string;
      };
    };
  };
  internationalChart: {
    title: string;
    headers: {
      sizeSystem: string;
      smallerSister: string;
      yourSize: string;
      largerSister: string;
    };
    note: string;
  };
}

interface InternationalSizes {
  [key: string]: string;
}

type BraSize = {
  bandSize: string;
  cupSize: string;
  type: 'smaller' | 'current' | 'larger';
};

const useTranslatedData = () => {
  const { t, ready } = useTranslation('sisterSizes', { useSuspense: false });

  // Cast t to a function that accepts a string key
  const translate = t as unknown as (key: string) => any;

  const getTranslation = <T extends keyof SisterSizesTranslations>(key: T): SisterSizesTranslations[T] => {
    if (!ready) {
      return {} as SisterSizesTranslations[T];
    }
    return translate(key);
  };

  return {
    ready,
    metaData: getTranslation('meta'),
    heroData: getTranslation('hero'),
    understandingData: getTranslation('understanding'),
    calculatorData: getTranslation('calculator'),
    resultsData: getTranslation('results'),
    internationalChartData: getTranslation('internationalChart')
  };
};

const NewSisterSizes: React.FC = () => {
  const location = useLocation();
  const {
    metaData,
    heroData,
    understandingData,
    calculatorData,
    resultsData,
    internationalChartData
  } = useTranslatedData();

  const [selectedSystem, setSelectedSystem] = useState<SizingSystem>('US');
  const [bandSize, setBandSize] = useState<string>('');
  const [cupSize, setCupSize] = useState<string>('');
  const [sisterSizes, setSisterSizes] = useState<BraSize[]>([]);
  const [internationalSizes, setInternationalSizes] = useState<Record<SizeType, InternationalSizes | null>>({
    smaller: null,
    current: null,
    larger: null
  });

  // Initialize with first available sizes
  useEffect(() => {
    const firstBand = bandSizes[selectedSystem][0]?.toString() || '';
    const firstCup = cupSizes[selectedSystem][0] || '';
    setBandSize(firstBand);
    setCupSize(firstCup);
  }, [selectedSystem]);

  // Calculate sister sizes when inputs change
  useEffect(() => {
    if (bandSize && cupSize) {
      const sizes = calculateSisterSizes(Number(bandSize), cupSize, selectedSystem);
      setSisterSizes(sizes.map(size => ({
        ...size,
        bandSize: size.bandSize.toString()
      })));

      // Calculate international sizes
      const newInternationalSizes: Record<SizeType, InternationalSizes | null> = {
        smaller: null,
        current: null,
        larger: null
      };

      sizes.forEach(size => {
        const international: InternationalSizes = {
          US: convertSize(size.bandSize, size.cupSize, selectedSystem, 'US') || '-',
          UK: convertSize(size.bandSize, size.cupSize, selectedSystem, 'UK') || '-',
          EU: convertSize(size.bandSize, size.cupSize, selectedSystem, 'EU') || '-',
          FR_ES: convertSize(size.bandSize, size.cupSize, selectedSystem, 'FR_ES') || '-',
          IT: convertSize(size.bandSize, size.cupSize, selectedSystem, 'IT') || '-',
          JP: convertSize(size.bandSize, size.cupSize, selectedSystem, 'JP') || '-',
          AU_NZ: convertSize(size.bandSize, size.cupSize, selectedSystem, 'AU_NZ') || '-'
        };
        newInternationalSizes[size.type] = international;
      });

      setInternationalSizes(newInternationalSizes);
    }
  }, [bandSize, cupSize, selectedSystem]);

  // Helper function to format size display
  const formatSizeDisplay = (size: BraSize) => {
    return {
      size: `${size.bandSize}${size.cupSize}`,
      description: resultsData.sizeTypes[size.type].description
    };
  };

  // Helper function to get size type class
  const getSizeTypeClass = (type: SizeType) => {
    switch (type) {
      case 'smaller':
        return 'bg-blue-50 border-blue-100 text-s text-center';
      case 'current':
        return 'bg-purple-100 border-purple-300 text-s text-center';
      case 'larger':
        return 'bg-pink-50 border-pink-10 text-s text-center';
      default:
        return '';
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <link rel="canonical" href={getCanonicalUrl(location.pathname)} />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
            {heroData.title}
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            {heroData.description}
          </p>
        </motion.div>

        {/* Introduction Section */}
        <div className="bg-white rounded-xl shadow p-8 mb-8">
          <h2 className="text-2xl font-semibold text-purple-900 mb-4 text-center">{understandingData.title}</h2>
          <p className="text-gray-600 text-medium mx-auto mb-6 text-center">
            {understandingData.description}
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-green-50 p-6 rounded-lg">
              <h3 className="text-lg font-semibold mb-4 text-green-800 flex items-center gap-2">
                <CheckCircle className="w-5 h-5" />
                {understandingData.goodUses.title}
              </h3>
              <ul className="space-y-3">
                {understandingData.goodUses.items.map((item, index) => (
                  <li key={index} className="flex items-start gap-2">
                    <div className="mt-0 text-green-500">•</div>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="bg-red-50 p-6 rounded-lg">
              <h3 className="text-lg font-semibold mb-4 text-red-800 flex items-center gap-2">
                <AlertTriangle className="w-5 h-5" />
                {understandingData.limitations.title}
              </h3>
              <ul className="space-y-3">
                {understandingData.limitations.items.map((item, index) => (
                  <li key={index} className="flex items-start gap-2">
                    <div className="mt-0 text-red-500">•</div>
                    <span>{item}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Size Selection Form */}
        <div className="bg-white rounded-xl shadow p-8 mb-8">
          <h2 className="text-2xl font-semibold text-purple-900 mb-4">{calculatorData.title}</h2>
          <div className="grid grid-cols-1 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {calculatorData.form.sizingSystem.label}
              </label>
              <select
                value={selectedSystem}
                onChange={(e) => setSelectedSystem(e.target.value as SizingSystem)}
                className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
              >
                {Object.keys(bandSizes).map((system) => (
                  <option key={system} value={system}>
                    {calculatorData.form.sizingSystem.options[system as keyof typeof calculatorData.form.sizingSystem.options]}
                  </option>
                ))}
              </select>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {calculatorData.form.bandSize.label}
                </label>
                <select
                  value={bandSize}
                  onChange={(e) => setBandSize(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                >
                  {bandSizes[selectedSystem].map((size) => (
                    <option key={size} value={size}>{size}</option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {calculatorData.form.cupSize.label}
                </label>
                <select
                  value={cupSize}
                  onChange={(e) => setCupSize(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                >
                  {cupSizes[selectedSystem].map((size) => (
                    <option key={size} value={size}>{size}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* Sister Sizes Display */}
        {sisterSizes.length > 0 && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white rounded-xl shadow p-8 mb-8"
          >
            <h2 className="text-2xl font-semibold text-purple-900 mb-4">{resultsData.title}</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {sisterSizes.map((size, index) => {
                const { size: displaySize, description } = formatSizeDisplay(size);
                return (
                  <div
                    key={index}
                    className={`p-6 rounded-xl border-2 ${getSizeTypeClass(size.type)} transition-all duration-300 hover:shadow-md`}
                  >
                    <div className="text-center">
                      <h3 className="text-xl font-bold mb-2">{displaySize}</h3>
                      <p className="text-gray-600 text-sm">{description}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        )}

        {/* International Size Chart */}
        {sisterSizes.length > 0 && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white rounded-xl shadow p-8"
          >
            <h2 className="text-2xl font-semibold text-purple-900 mb-4">{internationalChartData.title}</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {internationalChartData.headers.sizeSystem}
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {internationalChartData.headers.smallerSister}
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {internationalChartData.headers.yourSize}
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {internationalChartData.headers.largerSister}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {Object.keys(internationalSizes.current || {}).map((system) => (
                    <tr key={system}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {system === 'FR_ES' ? calculatorData.form.sizingSystem.options.FR_ES : system}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {internationalSizes.smaller?.[system]}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {internationalSizes.current?.[system]}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {internationalSizes.larger?.[system]}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <p className="mt-4 text-sm text-gray-500">
              {internationalChartData.note}
            </p>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default NewSisterSizes;
