import React, { useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { getCanonicalUrl } from '../utils/seo';

interface FAQItem {
  category: string;
  question: string;
  answer?: React.ReactNode;
}

const HighlightedText: React.FC<{ text: string; searchQuery: string }> = ({ text, searchQuery }) => {
  if (!searchQuery.trim()) return <>{text}</>;

  const parts = text.split(new RegExp(`(${searchQuery})`, 'gi'));
  return (
    <>
      {parts.map((part, index) => 
        part.toLowerCase() === searchQuery.toLowerCase() ? (
          <mark key={index} className="bg-yellow-200 rounded px-1">{part}</mark>
        ) : (
          part
        )
      )}
    </>
  );
};

const FAQ: React.FC = () => {
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState('');
  const [activeQuestions, setActiveQuestions] = useState<string[]>([]);

  const faqItems: FAQItem[] = [
    // Basic Measurements & Sizing
    { 
      category: "Basic Measurements & Sizing", 
      question: "How do I measure my bra size correctly at home?",
      answer: (
        <p>
          To measure your bra size at home, you'll need a soft measuring tape and should measure while wearing an unpadded bra or no bra. 
          First, measure your band size by wrapping the tape snugly around your ribcage just under your breasts, keeping it parallel to the ground. 
          Round to the nearest even number. Then, measure your bust at the fullest point while standing straight. The difference between these 
          measurements determines your cup size: 1" = A, 2" = B, 3" = C, and so on.
        </p>
      )
    },
    { 
      category: "Basic Measurements & Sizing", 
      question: "What do I need to know before measuring my bra size?",
      answer: (
        <p>
          Before measuring, ensure you're standing straight with your arms relaxed at your sides. Wear minimal clothing that won't add bulk 
          to your measurements. The measuring tape should be snug but not tight enough to compress tissue. It's best to measure at a time 
          when you're not experiencing significant hormonal changes that might affect breast size.
        </p>
      )
    },
    { 
      category: "Basic Measurements & Sizing", 
      question: "Should I measure my bra size with or without wearing a bra?",
      answer: (
        <p>
          For the most accurate measurements, measure without a padded bra. You can wear an unlined, non-padded bra if you prefer, but avoid 
          push-up or heavily padded styles as these will affect your measurements. If measuring without a bra, ensure you're standing straight 
          and lift breast tissue to its natural position when measuring the bust.
        </p>
      )
    },
    { 
      category: "Basic Measurements & Sizing", 
      question: "How can I measure my bra size without a measuring tape?",
      answer: (
        <p>
          While not as accurate as using a measuring tape, you can use a piece of string or ribbon and mark the measurements, then measure 
          the string against a ruler. Another method is to use current well-fitting bras as a reference point and try sister sizes (bras 
          with the same cup volume but different band sizes) to find your best fit.
        </p>
      )
    },
    { 
      category: "Basic Measurements & Sizing", 
      question: "What's the difference between band size and cup size?",
      answer: (
        <p>
          The band size is the number in your bra size (like 34, 36, etc.) and represents the measurement around your ribcage under your breasts. 
          The cup size (A, B, C, etc.) represents the difference between your band measurement and your bust measurement. Together, they create 
          your complete bra size, such as 34B.
        </p>
      )
    },
    { 
      category: "Basic Measurements & Sizing", 
      question: "How do I know if I'm wearing the right bra size?",
      answer: (
        <div>
          <p className="mb-4">A properly fitting bra should meet several criteria:</p>
          <ul className="list-disc pl-6 mb-4 space-y-2">
            <li>The band should be snug and level around your body</li>
            <li>The cups should fully contain your breast tissue without spillage or gaping</li>
            <li>The center gore (middle part) should lie flat against your sternum</li>
            <li>The straps shouldn't dig in or slip off</li>
            <li>You should be able to fit two fingers under the band, but no more</li>
            <li>The underwire should follow your breast root completely</li>
          </ul>
        </div>
      )
    },
    { 
      category: "Basic Measurements & Sizing", 
      question: "What do the numbers and letters in bra sizes mean?",
      answer: (
        <p>
          In a bra size like 34B, the number (34) represents your band size in inches, measuring around your ribcage under your breasts. 
          The letter (B) represents your cup size, which is determined by the difference between your band measurement and your bust measurement 
          at its fullest point. Each inch of difference corresponds to a cup size: 1" = A, 2" = B, and so on.
        </p>
      )
    },
    { 
      category: "Basic Measurements & Sizing", 
      question: "How do sister sizes work in bra sizing?",
      answer: (
        <p>
          Sister sizes are bra sizes that have the same cup volume but different band sizes. When you go up a band size, you need to go down 
          a cup size to maintain the same volume (and vice versa). For example, if you wear a 34C, your sister sizes would be 32D and 36B. 
          This is useful when a bra runs small or large in the band, or when between sizes.
        </p>
      )
    },
    // Understanding Cup Sizes
    { 
      category: "Understanding Cup Sizes", 
      question: "How do I determine my correct cup size?",
      answer: (
        <p>
          Your cup size represents the difference between your bust and underbust measurements. To find your cup size, first measure your 
          underbust (right underneath your breasts). Then measure around the fullest part of your bust. Subtract your underbust measurement 
          from your bust measurement – this difference determines your cup size. For example, a 1-inch difference indicates an A cup, 2 inches 
          a B cup, 3 inches a C cup, and so on.
        </p>
      )
    },
    { 
      category: "Understanding Cup Sizes", 
      question: "What does the letter in bra sizing indicate?",
      answer: (
        <p>
          The letter represents the difference between your bust and underbust measurements, indicating the volume of each breast. Think of it 
          as a standardized way to describe how far your breasts project from your chest wall. Each letter represents an inch of difference – 
          however, it's important to understand that a D cup on a 32 band contains a different volume than a D cup on a 36 band, even though 
          they share the same letter.
        </p>
      )
    },
    { 
      category: "Understanding Cup Sizes", 
      question: "How do cup sizes progress (A, B, C, D, etc.)?",
      answer: (
        <div>
          <p className="mb-4">Cup sizes follow a consistent alphabetical progression based on inches of difference between bust and underbust measurements:</p>
          <ul className="list-disc pl-6 mb-4 space-y-2">
            <li>1 inch = A cup</li>
            <li>2 inches = B cup</li>
            <li>3 inches = C cup</li>
            <li>4 inches = D cup</li>
            <li>5 inches = DD/E cup</li>
          </ul>
          <p>This progression continues upward, with each additional inch representing the next cup size.</p>
        </div>
      )
    },
    { 
      category: "Understanding Cup Sizes", 
      question: "What comes after DD in cup sizes?",
      answer: (
        <div>
          <p className="mb-4">After DD (also called E in some systems), the progression continues as:</p>
          <p className="mb-4">DD/E → DDD/F → G → H → I → J → K → L</p>
          <p>Different regions and manufacturers might use varying systems. For example, the US tends to use DDD while the UK uses F for the same size. 
          After DDD/F, most systems switch to single letters.</p>
        </div>
      )
    },
    { 
      category: "Understanding Cup Sizes", 
      question: "How do I measure cup size for asymmetrical breasts?",
      answer: (
        <p>
          When breasts are asymmetrical (which is very common), always measure for the larger breast to ensure proper coverage. Take your bust 
          measurement at the fullest point of your larger breast. This ensures you'll have adequate coverage where you need it most. You can 
          then use padding or inserts in the smaller cup to create balance if desired.
        </p>
      )
    },
    { 
      category: "Understanding Cup Sizes", 
      question: "What's the difference between DDD and F cup?",
      answer: (
        <p>
          DDD and F cup typically represent the same size – a 6-inch difference between bust and underbust measurements. The different labels 
          come from varying sizing systems: US sizing tends to use DDD, while UK and European systems typically use F. These are essentially 
          different names for the same cup volume.
        </p>
      )
    },
    { 
      category: "Understanding Cup Sizes", 
      question: "How do I know if my cup size is correct?",
      answer: (
        <div>
          <p className="mb-4">A correctly fitted cup should contain your entire breast without any spillage or gaping. Check for these signs:</p>
          <ul className="list-disc pl-6 mb-4 space-y-2">
            <li>No "quad boob" or spillage over the top or sides</li>
            <li>No wrinkles or gaps in the cup material</li>
            <li>The underwire should fully encircle your breast tissue</li>
            <li>The center gore (middle part) should lie flat against your sternum</li>
            <li>When you lift your arms, your breasts should stay fully contained</li>
          </ul>
        </div>
      )
    },
    // Band Size Questions
    { 
      category: "Band Size Questions", 
      question: "How do I measure my band size accurately?",
      answer: (
        <div>
          <p className="mb-4">To measure your band size:</p>
          <ul className="list-disc pl-6 mb-4 space-y-2">
            <li>Remove your bra</li>
            <li>Stand straight with arms relaxed</li>
            <li>Wrap a measuring tape snugly around your ribcage just under your breasts</li>
            <li>Keep the tape parallel to the ground</li>
            <li>Take the measurement while breathing normally</li>
            <li>Round to the nearest even number, as band sizes come in even numbers only</li>
          </ul>
        </div>
      )
    },
    { 
      category: "Band Size Questions", 
      question: "What if I'm between band sizes?",
      answer: (
        <div>
          <p className="mb-4">If you're between band sizes, consider these factors:</p>
          <ul className="list-disc pl-6 mb-4 space-y-2">
            <li>Generally, round down if comfort allows, as bands stretch with wear</li>
            <li>Try both sizes and assess which feels more supportive</li>
            <li>Consider using a band extender with the smaller size initially</li>
            <li>Remember that a new bra should fit snugly on the loosest hook, allowing you to tighten it as it stretches</li>
          </ul>
        </div>
      )
    },
    { 
      category: "Band Size Questions", 
      question: "How tight should a bra band be?",
      answer: (
        <div>
          <p className="mb-4">A properly fitted band should be snug but not uncomfortable. You should be able to fit two fingers under the band, but not more. The band should:</p>
          <ul className="list-disc pl-6 mb-4 space-y-2">
            <li>Stay level around your body without riding up</li>
            <li>Feel firmer on the loosest hook when new</li>
            <li>Not restrict breathing or create bulging</li>
            <li>Not slide around when you move or raise your arms</li>
          </ul>
        </div>
      )
    },
    { 
      category: "Band Size Questions", 
      question: "Why is the band size important?",
      answer: (
        <div>
          <p className="mb-4">The band provides about 80-90% of a bra's support, making it the most crucial element of bra fitting. A proper band:</p>
          <ul className="list-disc pl-6 mb-4 space-y-2">
            <li>Creates the foundation for breast support</li>
            <li>Prevents shoulder and neck strain</li>
            <li>Ensures proper cup positioning</li>
            <li>Improves posture and comfort</li>
            <li>Distributes breast weight evenly around the torso</li>
          </ul>
        </div>
      )
    },
    { 
      category: "Band Size Questions", 
      question: "How do I convert my underbust measurement to band size?",
      answer: (
        <div>
          <p className="mb-4">Modern bra fitting typically uses these guidelines:</p>
          <ul className="list-disc pl-6 mb-4 space-y-2">
            <li>If your underbust measurement is even, this is likely your band size</li>
            <li>If it's odd, round to the nearest even number</li>
            <li>If you prefer a looser fit, you might round up</li>
            <li>If you need maximum support, you might round down</li>
          </ul>
          <p>The old method of adding 4-5 inches to your underbust measurement is outdated and typically results in bands that are too loose to provide proper support.</p>
        </div>
      )
    }
    // Special Sizing Considerations
    ,
    { 
      category: "Special Sizing Considerations", 
      question: "How should I measure for a sports bra?",
      answer: (
        <p>
          Sports bras require a slightly different approach to measuring. Take your measurements while wearing a regular, unpadded bra, 
          but expect to need a firmer band fit for adequate support during exercise. The amount of support needed varies by activity 
          level – higher impact activities like running require more compression than low-impact activities like yoga. Your sports bra 
          should feel noticeably snugger than your everyday bra to minimize movement during activity.
        </p>
      )
    },
    { 
      category: "Special Sizing Considerations", 
      question: "How do I determine my nursing bra size?",
      answer: (
        <p>
          Nursing bra sizing is best done during the third trimester of pregnancy. Take your measurements when your breasts feel least 
          full, typically first thing in the morning. Plan for additional growth by allowing for one cup size larger than your measured 
          size. Look for bras with stretchy cup fabric and multiple sets of hooks to accommodate size fluctuations during nursing. 
          Remember that your size may change again once your milk production stabilizes.
        </p>
      )
    },
    { 
      category: "Special Sizing Considerations", 
      question: "How should I measure for a strapless bra?",
      answer: (
        <p>
          Strapless bras depend entirely on the band for support, so proper measuring is crucial. Your band measurement should be 
          particularly snug, as there are no straps to help with support. Many people find they need to go down a band size in strapless 
          styles to ensure the bra stays in place. The cups should fully contain your breast tissue while the band remains level and 
          secure, even when you raise your arms.
        </p>
      )
    },
    { 
      category: "Special Sizing Considerations",
      question: "What size should I choose for a minimizer bra?",
      answer: (
        <div className="space-y-2">
          <p>Start with your regular bra size, but consider going up one cup size as minimizers redistribute breast tissue across a wider area. These bras reduce bust projection by 1-1.5 inches.</p>
          <p>For the best minimizer fit:</p>
          <ul className="list-disc pl-6 space-y-1">
            <li>The band should be firm</li>
            <li>Cups should provide full coverage for proper tissue redistribution</li>
            <li>No bulges or discomfort should be present</li>
            <li>If between sizes, try both – the right fit should feel comfortable while achieving your desired minimizing effect</li>
          </ul>
        </div>
      )
    },
    {
      category: "Special Sizing Considerations",
      question: "How do I measure for a bralette?",
      answer: (
        <div className="space-y-2">
          <p>To find your bralette size:</p>
          <ul className="list-disc pl-6 space-y-1">
            <li>Measure your bust at its fullest point</li>
            <li>Measure your underbust where a bra band would sit</li>
            <li>Most bralettes use S/M/L sizing rather than band/cup measurements</li>
          </ul>
          <p>Sizing tips:</p>
          <ul className="list-disc pl-6 space-y-1">
            <li>For more support, size down</li>
            <li>For more coverage, size up</li>
            <li>Check the brand's size chart and consider your regular bra size</li>
            <li>Look for adjustable features if you need a more customized fit</li>
            <li>For fuller busts, seek out bralettes specifically designed with more support</li>
          </ul>
          <p>Remember that bralettes offer less structure than traditional bras.</p>
        </div>
      )
    },
    // Size Ranges & Standards
    { 
      category: "Size Ranges & Standards", 
      question: "What's considered a small, medium, or large bra size?",
      answer: (
        <div className="space-y-2">
          <p>While bra sizes exist on a spectrum rather than strict categories, here's a general guide:</p>
          <ul className="list-disc pl-6 space-y-1">
            <li>Small: Typically includes 28-32 bands with A-B cups</li>
            <li>Medium: Usually covers 32-36 bands with B-D cups</li>
            <li>Large: Generally includes 36+ bands with D+ cups</li>
          </ul>
          <p>However, these are rough guidelines, and what's considered small, medium, or large can vary significantly by region and manufacturer.</p>
        </div>
      )
    },
    {
      category: "Size Ranges & Standards",
      question: "What's the average bra size?",
      answer: "The average bra size has changed significantly over the years. Currently, in the US, the average bra size is 34DD/E. In the UK, it's 36DD. However, these statistics can be misleading as many women wear incorrect sizes. It's more important to focus on proper fit than comparing to an \"average\" size."
    },
    {
      category: "Size Ranges & Standards",
      question: "What's the full range of available bra sizes?",
      answer: (
        <div className="space-y-2">
          <p>Most mainstream manufacturers offer:</p>
          <ul className="list-disc pl-6 space-y-1">
            <li>Band sizes: 28-44 inches</li>
            <li>Cup sizes: AA-H (US) or AA-K (UK)</li>
          </ul>
          <p>Specialty manufacturers may offer:</p>
          <ul className="list-disc pl-6 space-y-1">
            <li>Band sizes: 24-56 inches</li>
            <li>Cup sizes: extending to N or larger</li>
          </ul>
          <p>The exact range varies by brand and market, with specialty brands offering more extensive size options.</p>
        </div>
      )
    },
    {
      category: "Size Ranges & Standards",
      question: "How do international bra sizes compare?",
      answer: (
        <div className="space-y-2">
          <p>International sizing varies significantly:</p>
          <ul className="list-disc pl-6 space-y-1">
            <li>US: Uses letters (A, B, C, D, DD, DDD)</li>
            <li>UK: Uses letters but differs after D (DD, E, F, FF, G)</li>
            <li>EU: Uses numbers for band (65-95) and letters for cups</li>
            <li>FR: Similar to EU but with different number ranges</li>
            <li>JP: Uses centimeters and different cup progression</li>
          </ul>
        </div>
      )
    },
    {
      category: "Size Ranges & Standards",
      question: "What size corresponds to S, M, L, XL in bras?",
      answer: (
        <div className="space-y-2">
          <p>Traditional letter sizing roughly correlates to bra sizes as follows:</p>
          <ul className="list-disc pl-6 space-y-1">
            <li>Small (S): 32A-B, 34A</li>
            <li>Medium (M): 34B-C, 36A-B</li>
            <li>Large (L): 36C-D, 38B-C</li>
            <li>X-Large (XL): 38D+, 40+ bands</li>
          </ul>
          <p>However, this conversion is imprecise and not recommended for bra shopping, as proper fit requires both band and cup measurements rather than general sizing.</p>
        </div>
      )
    },
    // Professional Fitting
    { 
      category: "Professional Fitting", 
      question: "Where can I get professionally fitted for a bra?",
      answer: (
        <p>
          Professional fittings are available at specialty lingerie boutiques, department stores, and many major lingerie retailers. 
          Look for stores with certified fitters who receive regular training. The best fitters will measure you both standing and 
          seated, observe how different styles fit your shape, and take time to educate you about proper fit.
        </p>
      )
    },
    { 
      category: "Professional Fitting", 
      question: "How often should I get professionally fitted?",
      answer: (
        <p>
          Schedule a professional fitting annually or whenever you experience significant body changes. This includes weight fluctuations, 
          pregnancy, menopause, or starting a new exercise routine. Even if you think your size hasn't changed, a professional fitting 
          can confirm your size and introduce you to new styles that might work better for your current shape.
        </p>
      )
    },
    { 
      category: "Professional Fitting", 
      question: "What should I expect during a professional fitting?",
      answer: (
        <div>
          <p className="mb-4">During a professional fitting, expect the following:</p>
          <ul className="list-disc pl-6 mb-4 space-y-2">
            <li>A brief discussion about your current bras and any fit issues</li>
            <li>Measurements taken over light clothing or a thin bra</li>
            <li>Recommendations for specific styles based on your shape</li>
            <li>The opportunity to try several bras</li>
            <li>Education about proper bra wear and care</li>
          </ul>
        </div>
      )
    },
    { 
      category: "Professional Fitting", 
      question: "Do I need an appointment for a professional fitting?",
      answer: (
        <p>
          While some stores accept walk-ins, scheduling an appointment ensures you'll have dedicated time with a fitter. Appointments 
          typically last 30-45 minutes and provide a more relaxed, thorough experience. Morning appointments often work best as breast 
          tissue tends to be less swollen.
        </p>
      )
    },
    // Troubleshooting Fit Issues
    { 
      category: "Troubleshooting Fit Issues", 
      question: "Why does my bra ride up in the back?",
      answer: (
        <p>
          A bra riding up usually indicates the band is too loose. Your band should remain parallel to the floor throughout the day. 
          If it's riding up, you likely need a smaller band size and possibly a larger cup size to maintain the same cup volume.
        </p>
      )
    },
    { 
      category: "Troubleshooting Fit Issues", 
      question: "Why do my bra straps keep falling down?",
      answer: (
        <p>
          Slipping straps can indicate several issues. Your band might be too loose, forcing the straps to do more work than they 
          should. The straps might be worn out or stretched, or your shoulders might be sloped. Sometimes, the bra style simply 
          doesn't match your shoulder width.
        </p>
      )
    },
    { 
      category: "Troubleshooting Fit Issues", 
      question: "How do I know if the cup size is too big or too small?",
      answer: (
        <p>
          A cup that's too small will cause spillage over the top or sides of the bra. Your breast tissue might also spill out under 
          the band. When a cup is too big, you'll notice gaping or wrinkling in the fabric, even when you lean forward. The underwire 
          might also sit too far below your breast tissue.
        </p>
      )
    },
    { 
      category: "Troubleshooting Fit Issues", 
      question: "Why does my bra create a 'quad-boob' effect?",
      answer: (
        <p>
          The "quad-boob" effect, where breast tissue spills over the top of the cups creating four distinct sections, typically 
          indicates a cup size that's too small. Sometimes it can also mean the bra style doesn't match your breast shape or that 
          you need a different cup style with more coverage.
        </p>
      )
    },
    { 
      category: "Troubleshooting Fit Issues", 
      question: "Why does my underwire poke or feel uncomfortable?",
      answer: (
        <p>
          Uncomfortable underwires often indicate a poor fit. The wire should completely encircle your breast tissue without sitting 
          on it or poking into your armpit. If the wire pokes at the center, the cup might be too small or the style might not suit 
          your breast spacing.
        </p>
      )
    },
    // Size Changes & Variations
    {
      category: "Size Changes & Variations",
      question: "How does pregnancy affect bra size?",
      answer: (
        <p>
          During pregnancy, breasts typically increase by 1-2 cup sizes and may continue changing through breastfeeding. 
          These changes often start in the first trimester and continue throughout pregnancy. The ribcage may also expand 
          to accommodate the growing baby, affecting band size. After pregnancy and breastfeeding, breasts may return to 
          their original size or settle at a new size.
        </p>
      )
    },
    {
      category: "Size Changes & Variations",
      question: "How do hormonal changes impact bra size?",
      answer: (
        <p>
          Monthly hormonal fluctuations can cause breast size to vary by up to a cup size. Many women notice increased 
          fullness before menstruation. Hormonal contraception, menopause, and other hormonal changes can also affect 
          breast size. For these fluctuations, consider having bras in two sizes or choosing styles with flexible fabrics.
        </p>
      )
    },
    {
      category: "Size Changes & Variations",
      question: "When should I go up or down a cup size?",
      answer: (
        <p>
          Consider changing cup sizes when you notice consistent spillage or gaping. Weight changes of 5-10 pounds often 
          necessitate a cup size change. Signs you need a larger cup include quadboob effect, underwire sitting on breast 
          tissue, or center gore not lying flat. Go down a cup size if you notice wrinkles in the cup or gaps at the top.
        </p>
      )
    },
    {
      category: "Size Changes & Variations",
      question: "How do I adjust my bra size for weight fluctuations?",
      answer: (
        <p>
          Generally, for every 10 pounds of weight change, expect a potential band size change. Cup size might change with 
          as little as 5 pounds of weight fluctuation. Focus on fit rather than numbers - your new size should feel 
          comfortable and supportive regardless of the label.
        </p>
      )
    },
    {
      category: "Size Changes & Variations",
      question: "Why does my bra size change throughout the month?",
      answer: (
        <p>
          Monthly hormonal cycles affect breast tissue density and water retention. Many women find their breasts are 
          largest and most sensitive just before menstruation. Consider having a few bras in different sizes to 
          accommodate these regular changes comfortably.
        </p>
      )
    },
    // Shopping Tips
    {
      category: "Shopping Tips",
      question: "How do I convert my bra size between brands?",
      answer: (
        <div>
          <p>
            Size conversions vary between countries and brands. US, UK, and European sizes use different systems:
          </p>
          <p>UK: 34D = US: 34D = EU: 75D</p>
          <p>
            However, actual fit may vary despite these conversions. Always try new brands in multiple sizes, as their 
            sizing might run larger or smaller than what you're used to.
          </p>
        </div>
      )
    },
    {
      category: "Shopping Tips",
      question: "What size should I try first when shopping online?",
      answer: (
        <p>
          Start with your current best-fitting bra size, but order multiple sizes if possible. Read customer reviews for 
          sizing advice specific to that brand or style. Look for free return policies that allow you to try multiple 
          sizes without extra cost.
        </p>
      )
    },
    {
      category: "Shopping Tips",
      question: "Should I buy different sizes for different styles?",
      answer: (
        <div>
          <p>
            Yes, different bra styles may require different sizes. For example:
          </p>
          <ul>
            <li>Plunge bras might need a different cup size than full-coverage styles</li>
            <li>Strapless bras often need a smaller band size for proper support</li>
            <li>Sports bras might need a firmer band for high-impact activities</li>
          </ul>
        </div>
      )
    },
    {
      category: "Shopping Tips",
      question: "How do I know which sister size to try?",
      answer: (
        <div>
          <p>
            Sister sizes maintain the same cup volume with different band sizes. If your usual size is 34D, your sister 
            sizes would be 32DD and 36C. Choose a sister size when:
          </p>
          <ul>
            <li>The band feels too tight or loose but the cups fit well</li>
            <li>A brand runs particularly small or large</li>
            <li>Your size falls between standard sizes</li>
          </ul>
        </div>
      )
    },
    {
      category: "Shopping Tips",
      question: "Why do different bra styles fit differently in the same size?",
      answer: (
        <p>
          Different bra styles are constructed differently and serve different purposes. A demi cup will fit differently 
          than a full coverage bra, even in the same size. Cut, underwire shape, and fabric composition all affect how 
          a bra fits. It's perfectly normal to need different sizes across different styles and brands.
        </p>
      )
    }
  ];

  const filteredFAQs = useMemo(() => {
    const query = searchQuery.toLowerCase();
    return faqItems.filter(
      item => 
        item.question.toLowerCase().includes(query) ||
        item.category.toLowerCase().includes(query) ||
        (typeof item.answer === 'string' && item.answer.toLowerCase().includes(query))
    );
  }, [searchQuery, faqItems]);

  const toggleQuestion = (question: string) => {
    setActiveQuestions(prev => 
      prev.includes(question)
        ? prev.filter(q => q !== question)
        : [...prev, question]
    );
  };

  const groupedFAQs = useMemo(() => {
    return filteredFAQs.reduce((acc, item) => {
      if (!acc[item.category]) {
        acc[item.category] = [];
      }
      acc[item.category].push(item);
      return acc;
    }, {} as Record<string, FAQItem[]>);
  }, [filteredFAQs]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white py-12">
      <Helmet>
        <title>Frequently Asked Questions - Bra Size Calculator</title>
        <meta name="description" content="Find answers to common questions about bra sizing, measurements, and fit. Learn how to measure yourself correctly and find your perfect bra size." />
        <link rel="canonical" href={getCanonicalUrl(location.pathname)} />
      </Helmet>
      <div className="max-w-4xl mx-auto px-4 py-12">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
            Bra Sizing FAQ
          </h1>
          <p className="text-gray-600 mb-8">
            Find answers to your most common bra sizing questions
          </p>

          {/* Search Bar */}
          <div className="relative max-w-2xl mx-auto">
            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
              <svg className="h-5 w-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>
            <input
              type="text"
              className="w-full pl-12 pr-4 py-4 rounded-xl border-2 border-purple-100 focus:border-purple-300 focus:ring focus:ring-purple-200 focus:ring-opacity-50 transition-colors bg-white"
              placeholder="Search questions..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        <div className="space-y-12">
          {Object.entries(groupedFAQs).map(([category, items]) => (
            <div key={category} className="space-y-4">
              <h2 className="text-2xl font-semibold text-gray-800 px-2">
                <HighlightedText text={category} searchQuery={searchQuery} />
              </h2>
              <div className="bg-white rounded-xl shadow-sm overflow-hidden divide-y divide-gray-100">
                {items.map((item) => (
                  <div key={item.question} className="hover:bg-pink-50/50 transition-colors">
                    <button
                      onClick={() => toggleQuestion(item.question)}
                      className="w-full px-6 py-4 text-left flex justify-between items-center"
                    >
                      <span className="text-gray-700 font-medium pr-8">
                        <HighlightedText text={item.question} searchQuery={searchQuery} />
                      </span>
                      <span className={`transform transition-transform duration-200 ${
                        activeQuestions.includes(item.question) ? 'rotate-180' : ''
                      }`}>
                        <svg
                          className="w-5 h-5 text-pink-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </span>
                    </button>
                    <div
                      className={`transition-all duration-200 ${
                        activeQuestions.includes(item.question)
                          ? 'max-h-[1000px] opacity-100'
                          : 'max-h-0 opacity-0'
                      } overflow-hidden`}
                    >
                      <div className="px-6 pb-4 text-gray-600">
                        {typeof item.answer === 'string' ? (
                          <HighlightedText text={item.answer} searchQuery={searchQuery} />
                        ) : (
                          item.answer
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {filteredFAQs.length === 0 && (
          <div className="text-center py-12">
            <p className="text-gray-500">No questions found matching your search.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FAQ;
