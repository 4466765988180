import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslations } from '../hooks/useTranslations';
import { routes } from '../config/routes';
import { useLocation } from 'react-router-dom';
import { getLanguageFromPath } from '../config/routes';
import { getRoute } from '../config/routes';

const Footer: React.FC = () => {
  const { translate } = useTranslations('common');
  const location = useLocation();
  const currentLanguage = getLanguageFromPath(location.pathname);

  return (
    <footer className="bg-gray-50 border-t">
      <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* About Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">{translate('footer.about.title')}</h3>
            <p className="text-gray-600">
              {translate('footer.about.description')}
            </p>
          </div>
          
          {/* Quick Links Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">{translate('footer.quickLinks.title')}</h3>
            <ul className="space-y-2">
              <li>
                <button
                  onClick={() => {
                    // If not on homepage, navigate first
                    if (location.pathname !== '/') {
                      window.location.href = '/#Enhanced Bra Size Calculator';
                    } else {
                      // Add a small delay to ensure the element is available
                      setTimeout(() => {
                        const calculatorSection = document.getElementById('Enhanced Bra Size Calculator');
                        if (calculatorSection) {
                          calculatorSection.scrollIntoView({ behavior: 'smooth' });
                        }
                      }, 100);
                    }
                  }}
                  className="text-gray-600 hover:text-purple-500"
                >
                  {translate('footer.quickLinks.calculator')}
                </button>
              </li>
              <li>
                <Link to={routes.sizeConverter[currentLanguage]} className="text-gray-600 hover:text-purple-500">
                  {translate('footer.quickLinks.converter')}
                </Link>
              </li>
              <li>
                <Link to={routes.sisterSizes[currentLanguage]} className="text-gray-600 hover:text-purple-500">
                  {translate('footer.quickLinks.sisterSizes')}
                </Link>
              </li>
              <li>
                <Link to={routes.sizeCharts[currentLanguage]} className="text-gray-600 hover:text-purple-500">
                  {translate('footer.quickLinks.sizeCharts')}
                </Link>
              </li>
            </ul>
          </div>

          {/* Resources Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">{translate('footer.resources.title')}</h3>
            <ul className="space-y-2">
              <li>
                <Link to={routes.measuringGuide[currentLanguage]} className="text-gray-600 hover:text-purple-500">
                  {translate('footer.resources.measuringGuide')}
                </Link>
              </li>
              <li>
                <Link to={routes.fittingGuide[currentLanguage]} className="text-gray-600 hover:text-purple-500">
                  {translate('footer.resources.fittingGuide')}
                </Link>
              </li>
              <li>
                <Link to={routes.braTypes[currentLanguage]} className="text-gray-600 hover:text-purple-500">
                  {translate('footer.resources.typesGuide')}
                </Link>
              </li>
              <li>
                <Link to={routes.breastShapes[currentLanguage]} className="text-gray-600 hover:text-purple-500">
                  {translate('footer.resources.shapeGuide')}
                </Link>
              </li>
              <li>
                <Link to={routes.fitIssues[currentLanguage]} className="text-gray-600 hover:text-purple-500">
                  {translate('footer.resources.fitIssues')}
                </Link>
              </li>
              <li>
                <Link to={routes.faq[currentLanguage]} className="text-gray-600 hover:text-purple-500">
                  {translate('footer.resources.faq')}
                </Link>
              </li>
            </ul>
          </div>

          {/* Legal Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">{translate('footer.legal.title')}</h3>
            <ul className="space-y-2">
              <li>
                <Link to={routes.legal.privacy[currentLanguage]} className="text-gray-600 hover:text-purple-500">
                  {translate('footer.legal.privacy')}
                </Link>
              </li>
              <li>
                <Link to={routes.legal.terms[currentLanguage]} className="text-gray-600 hover:text-purple-500">
                  {translate('footer.legal.terms')}
                </Link>
              </li>
              <li>
                <Link to={routes.legal.cookies[currentLanguage]} className="text-gray-600 hover:text-purple-500">
                  {translate('footer.legal.cookies')}
                </Link>
              </li>
              <li>
                <Link to={routes.contact[currentLanguage]} className="text-gray-600 hover:text-purple-500">
                  {translate('footer.legal.contact')}
                </Link>
              </li>
              <li>
                <Link
                  to={getRoute('about')}
                  className="text-gray-600 hover:text-purple-500"
                >
                  {translate('footer.legal.aboutUs')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="mt-8 pt-8 border-t border-gray-200">
          <p className="text-center text-gray-400 text-sm">
            {translate('footer.copyright').replace('{year}', new Date().getFullYear().toString())}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
