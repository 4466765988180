import { FitIssue } from '../types/fitTypes';

export const fitIssueIds = [
  'straps-falling',
  'band-rides-up',
  'cup-spillage',
  'cup-gaps',
  'wire-digging',
  'center-gore',
  'band-tight',
  'band-loose'
] as const;

export type FitIssueId = typeof fitIssueIds[number];

const getFitIssues = (t: (key: string, options?: any) => string): FitIssue[] => {
  return fitIssueIds.map(id => ({
    id,
    label: t(`issues.${id}.label`),
    category: id.split('-')[0],
    sizeImplication: getSizeImplication(id),
    solution: t(`issues.${id}.solution`)
  }));
};

function getSizeImplication(id: FitIssueId) {
  const implications: Record<FitIssueId, { bandChange?: 'up' | 'down'; cupChange?: 'up' | 'down' }> = {
    'straps-falling': { bandChange: 'down' },
    'band-rides-up': { bandChange: 'down' },
    'cup-spillage': { cupChange: 'up' },
    'cup-gaps': { cupChange: 'down' },
    'wire-digging': { bandChange: 'up' },
    'center-gore': { cupChange: 'up' },
    'band-tight': { bandChange: 'up' },
    'band-loose': { bandChange: 'down' }
  };
  return implications[id];
}

export { getFitIssues };

// For backward compatibility, export a default array of fit issues in English
const defaultFitIssues: FitIssue[] = getFitIssues(key => {
  const defaultTranslations = {
    'issues.straps-falling.label': 'Straps falling down',
    'issues.band-rides-up.label': 'Band rides up',
    'issues.cup-spillage.label': 'Cup spillage',
    'issues.cup-gaps.label': 'Gaps in cups',
    'issues.wire-digging.label': 'Underwire digging in',
    'issues.center-gore.label': 'Center gore not lying flat',
    'issues.band-tight.label': 'Band feels too tight',
    'issues.band-loose.label': 'Band feels too loose',
    'issues.straps-falling.solution': 'Look for bras with fully adjustable straps and consider a racerback style for better strap stability.',
    'issues.band-rides-up.solution': 'Your band might be too loose. Try fastening it on a tighter hook or consider going down a band size.',
    'issues.cup-spillage.solution': 'Your cups might be too small. Consider going up a cup size or trying a full coverage style.',
    'issues.cup-gaps.solution': 'Your cups might be too large or the style might not match your shape. Try going down a cup size or look for styles with stretch lace tops.',
    'issues.wire-digging.solution': 'Try a bra with flexible underwire or consider wireless options. The band might also be too tight.',
    'issues.center-gore.solution': 'The cups might be too small or the style might not suit your shape. Consider a plunge style or going up a cup size.',
    'issues.band-tight.solution': 'Try going up a band size or look for styles with stretch bands. Make sure to fasten on the loosest hook when new.',
    'issues.band-loose.solution': 'Try going down a band size or fastening on a tighter hook. The band should be snug but not uncomfortable.'
  };
  return defaultTranslations[key as keyof typeof defaultTranslations] || key;
});

export default defaultFitIssues;
