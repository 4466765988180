import { useTranslations } from '../hooks/useTranslations';  // Add this import
import React, { useState, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { sizeConversions, cupSizes } from '../data/sizingConverterData';
import { getCanonicalUrl } from '../utils/seo';
import { routes, getLanguageFromPath } from '../config/routes';

interface SizeChartsTranslations {
  meta: {
    title: string;
    description: string;
    keywords: string;
  };
  hero: {
    title: string;
    subtitle: string;
  };
  tabs: {
    international: string;
    sister: string;
    tips: string;
  };
  bandSizeChart: {
    title: string;
    toggleUnits: {
      showCm: string;
      showInches: string;
    };
    headers: {
      us: string;
      uk: string;
      eu: string;
      frBeEs: string;
      it: string;
      auNz: string;
      measurement: string;
    };
  };
  cupSizeChart: {
    title: string;
    toggleUnits: {
      showCm: string;
      showInches: string;
    };
    headers: {
      usUk: string;
      eu: string;
      frEs: string;
      it: string;
      jp: string;
      auNz: string;
      difference: string;
    };
  };
  sisterSizes: {
    title: string;
    description: string;
    currentSize: string;
    smallerBand: string;
    yourSize: string;
    largerBand: string;
    selectSize: string;
    tryTheseSizes: string;
    fullCalculator: string;
    fullCalculatorDescription: string;
    results: {
      smallerBand: {
        title: string;
      };
      currentSize: {
        title: string;
      };
      largerBand: {
        title: string;
      };
      tip: string;
      selectPrompt: string;
    };
  };
  tips: {
    bandMeasurement: {
      title: string;
      tips: Record<string, {
        title: string;
        description: string;
      }>;
    };
    cupMeasurement: {
      title: string;
      tips: Record<string, {
        title: string;
        description: string;
      }>;
    };
    poorFit: {
      title: string;
      subtitle: string;
      categories: {
        band: {
          title: string;
          signs: Record<string, {
            title: string;
            description: string;
            solution: string;
          }>;
        };
        straps: {
          title: string;
          signs: Record<string, {
            title: string;
            description: string;
            solution: string;
          }>;
        };
        cups: {
          title: string;
          signs: Record<string, {
            title: string;
            description: string;
            solution: string;
          }>;
        };
        underwire: {
          title: string;
          signs: Record<string, {
            title: string;
            description: string;
            solution: string;
          }>;
        };
        fit: {
          title: string;
          signs: Record<string, {
            title: string;
            description: string;
            solution: string;
          }>;
        };
      };
    };
    viewGuide: string;
  };
  measurementTips: {
    bandMeasurement: {
      title: string;
      tips: Record<string, {
        title: string;
        description: string;
      }>;
    };
    cupMeasurement: {
      title: string;
      tips: Record<string, {
        title: string;
        description: string;
      }>;
    };
  };
}

interface TabProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

type TabId = 'international' | 'sister' | 'tips';

interface Tab {
  id: TabId;
  label: string;
}

interface Step {
  title: string;
  description: string;
}

interface Sign {
  title: string;
  description: string;
}

const Tab: React.FC<TabProps> = React.memo(({ label, isActive, onClick }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 text-sm font-medium rounded-lg transition-all duration-200 ${
      isActive
        ? 'bg-purple-600 text-white shadow'
        : 'text-gray-600 hover:bg-purple-50'
    }`}
  >
    {label}
  </button>
));

// Helper function to convert inches to centimeters
const inchesToCm = (inches: number): number => Math.round(inches * 2.54);

// Helper function to get measurement range
const getMeasurementRange = (size: number): { inches: string; cm: string } => {
  return {
    inches: `${size}-${size + 1}"`,
    cm: `${inchesToCm(size)}-${inchesToCm(size + 1)} cm`
  };
};

interface TranslatedData {
  ready: boolean;
  metaData: {
    title: string;
    description: string;
    keywords: string;
  };
  heroData: {
    title: string;
    subtitle: string;
  };
  tabsData: {
    international: string;
    sister: string;
    tips: string;
  };
  bandSizeChartData: {
    title: string;
    toggleUnits: {
      showCm: string;
      showInches: string;
    };
    headers: {
      us: string;
      uk: string;
      eu: string;
      frBeEs: string;
      it: string;
      auNz: string;
      measurement: string;
    };
  };
  cupSizeChartData: {
    title: string;
    toggleUnits: {
      showCm: string;
      showInches: string;
    };
    headers: {
      usUk: string;
      eu: string;
      frEs: string;
      it: string;
      jp: string;
      auNz: string;
      difference: string;
    };
  };
  sisterSizesData: {
    title: string;
    description: string;
    currentSize: string;
    smallerBand: string;
    yourSize: string;
    largerBand: string;
    selectSize: string;
    tryTheseSizes: string;
    fullCalculator: string;
    fullCalculatorDescription: string;
    results: {
      smallerBand: {
        title: string;
      };
      currentSize: {
        title: string;
      };
      largerBand: {
        title: string;
      };
      tip: string;
      selectPrompt: string;
    };
  };
  tipsData: {
    bandMeasurement: {
      title: string;
      tips: Record<string, {
        title: string;
        description: string;
      }>;
    };
    cupMeasurement: {
      title: string;
      tips: Record<string, {
        title: string;
        description: string;
      }>;
    };
    poorFit: {
      title: string;
      subtitle: string;
      categories: {
        band: {
          title: string;
          signs: Record<string, {
            title: string;
            description: string;
            solution: string;
          }>;
        };
        straps: {
          title: string;
          signs: Record<string, {
            title: string;
            description: string;
            solution: string;
          }>;
        };
        cups: {
          title: string;
          signs: Record<string, {
            title: string;
            description: string;
            solution: string;
          }>;
        };
        underwire: {
          title: string;
          signs: Record<string, {
            title: string;
            description: string;
            solution: string;
          }>;
        };
        fit: {
          title: string;
          signs: Record<string, {
            title: string;
            description: string;
            solution: string;
          }>;
        };
      };
    };
    viewGuide: string;
  };
  measurementTipsData: {
    bandMeasurement: {
      title: string;
      tips: Record<string, {
        title: string;
        description: string;
      }>;
    };
    cupMeasurement: {
      title: string;
      tips: Record<string, {
        title: string;
        description: string;
      }>;
    };
  };
}

const useTranslatedData = (): TranslatedData => {
  const {
    ready,
    translateObject,
    translate
  } = useTranslations('sizeCharts');

  // Helper function to safely get text from potentially nested objects
  const safeText = (value: any): string => {
    if (!value) return '';
    if (typeof value === 'string') return value;
    if (typeof value === 'object') {
      if ('description' in value) return value.description as string;
      if ('link' in value) return value.link as string;
      if ('title' in value) return value.title as string;
    }
    return '';
  };

  // Helper function to safely transform translation objects
  const transformTranslation = <T extends Record<string, any>>(obj: T): T => {
    if (!obj) return {} as T;
    
    const result: any = {};
    for (const [key, value] of Object.entries(obj)) {
      if (Array.isArray(value)) {
        result[key] = value.map(item => 
          typeof item === 'object' ? transformTranslation(item) : safeText(item)
        );
      } else if (typeof value === 'object' && value !== null) {
        if ('link' in value && !('title' in value)) {
          result[key] = value.link;
        } else if ('description' in value && !('title' in value)) {
          result[key] = value.description;
        } else if ('title' in value && 'description' in value) {
          result[key] = {
            title: value.title,
            description: value.description,
            ...(value.solution && { solution: value.solution })
          };
        } else if ('title' in value && 'tips' in value) {
          result[key] = {
            title: value.title,
            tips: Object.entries(value.tips).reduce((acc, [tipKey, tipValue]) => ({
              ...acc,
              [tipKey]: {
                title: (tipValue as any).title,
                description: (tipValue as any).description
              }
            }), {})
          };
        } else if ('title' in value && 'signs' in value) {
          result[key] = {
            title: value.title,
            signs: Object.entries(value.signs).reduce((acc, [signKey, signValue]) => ({
              ...acc,
              [signKey]: {
                title: (signValue as any).title,
                description: (signValue as any).description,
                solution: (signValue as any).solution
              }
            }), {})
          };
        } else {
          result[key] = transformTranslation(value);
        }
      } else {
        result[key] = safeText(value);
      }
    }
    return result as T;
  };

  const defaultData = {
    meta: {
      title: "International Bra Size Charts & Conversions",
      description: "Complete bra size charts and conversion tables.",
      keywords: "bra size chart, international bra sizes"
    },
    hero: {
      title: "International Bra Size Charts",
      subtitle: "Find your perfect fit across different sizing systems"
    },
    tabs: {
      international: "International Size Charts",
      sister: "Sister Size Calculator",
      tips: "Sizing Tips"
    },
    bandSizeChart: {
      title: "Band Size Conversion Chart",
      toggleUnits: {
        showCm: "Show Centimeters",
        showInches: "Show Inches"
      },
      headers: {
        us: "US",
        uk: "UK",
        eu: "EU",
        frBeEs: "FR/BE/ES",
        it: "IT",
        auNz: "AU/NZ",
        measurement: "Measurement"
      }
    },
    cupSizeChart: {
      title: "Cup Size Conversion Chart",
      toggleUnits: {
        showCm: "Show Centimeters",
        showInches: "Show Inches"
      },
      headers: {
        usUk: "US/UK",
        eu: "EU",
        frEs: "FR/ES",
        it: "IT",
        jp: "JP",
        auNz: "AU/NZ",
        difference: "Difference"
      }
    },
    sisterSizes: {
      title: "Sister Size Calculator",
      description: "Find alternative bra sizes with the same cup volume",
      currentSize: "Current Size",
      smallerBand: "Smaller Band",
      yourSize: "Your Size",
      largerBand: "Larger Band",
      selectSize: "Select Size",
      tryTheseSizes: "Try These Sizes",
      fullCalculator: "Full Calculator",
      fullCalculatorDescription: "Need more help? Try our detailed calculator",
      results: {
        smallerBand: {
          title: "Smaller Band"
        },
        currentSize: {
          title: "Current Size"
        },
        largerBand: {
          title: "Larger Band"
        },
        tip: "These sizes have similar cup volume but different band sizes",
        selectPrompt: "Select a size to see sister sizes"
      }
    },
    measurementTips: {
      bandMeasurement: {
        title: "Band Measurement Tips",
        tips: {
          measureUnderBust: {
            title: "Measure Under Bust",
            description: "Measure directly under your breasts where the band sits."
          },
          keepLevel: {
            title: "Keep Level",
            description: "Keep the measuring tape level around your body."
          },
          properTension: {
            title: "Proper Tension",
            description: "The tape should be snug but not too tight."
          }
        }
      },
      cupMeasurement: {
        title: "Cup Measurement Tips",
        tips: {
          fullestPoint: {
            title: "Fullest Point",
            description: "Measure at the fullest part of your bust."
          },
          parallelPosition: {
            title: "Parallel Position",
            description: "Keep the tape parallel to the ground."
          },
          gentleMeasurement: {
            title: "Gentle Measurement",
            description: "Don't compress the breast tissue."
          }
        }
      }
    },
    poorFit: {
      title: "Signs of Poor Fit",
      subtitle: "Identifying these signs can help you find a better fitting bra",
      categories: {
        band: {
          title: "Band Issues",
          signs: {
            bandRidesUp: {
              title: "Band Rides Up",
              description: "Band rides up in the back",
              solution: "Try a smaller band size"
            },
            bandTooTight: {
              title: "Band Too Tight",
              description: "Band leaves marks or is uncomfortable",
              solution: "Try a larger band size"
            }
          }
        },
        straps: {
          title: "Strap Issues",
          signs: {
            strapSlipping: {
              title: "Straps Slipping",
              description: "Straps fall off shoulders",
              solution: "Adjust straps or try a different style"
            }
          }
        },
        cups: {
          title: "Cup Issues",
          signs: {
            cupOverflow: {
              title: "Cup Overflow",
              description: "Breast tissue spilling over",
              solution: "Try a larger cup size"
            }
          }
        },
        underwire: {
          title: "Underwire Issues",
          signs: {
            underwirePosition: {
              title: "Incorrect Position",
              description: "Underwire not sitting flat",
              solution: "Try a different size or style"
            }
          }
        },
        fit: {
          title: "General Fit Issues",
          signs: {
            sideSpillage: {
              title: "Side Spillage",
              description: "Tissue escaping at sides",
              solution: "Try a larger cup size"
            }
          }
        }
      }
    }
  };

  const translatedData: TranslatedData = {
    ready,
    metaData: transformTranslation(translateObject('meta', defaultData.meta)),
    heroData: transformTranslation(translateObject('hero', defaultData.hero)),
    tabsData: transformTranslation(translateObject('tabs', defaultData.tabs)),
    bandSizeChartData: transformTranslation(translateObject('bandSizeChart', defaultData.bandSizeChart)),
    cupSizeChartData: transformTranslation(translateObject('cupSizeChart', defaultData.cupSizeChart)),
    sisterSizesData: transformTranslation(translateObject('sisterSizes', defaultData.sisterSizes)),
    tipsData: {
      bandMeasurement: transformTranslation(translateObject('measurementTips.bandMeasurement', defaultData.measurementTips.bandMeasurement)),
      cupMeasurement: transformTranslation(translateObject('measurementTips.cupMeasurement', defaultData.measurementTips.cupMeasurement)),
      poorFit: transformTranslation(translateObject('poorFit', defaultData.poorFit)),
      viewGuide: translate('detailedGuide.link') || ''
    },
    measurementTipsData: transformTranslation(translateObject('measurementTips', defaultData.measurementTips))
  };

  return translatedData;
};

const getSignIcon = (signType: string): JSX.Element => {
  const defaultIcon = (
    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
    </svg>
  );

  const icons: Record<string, JSX.Element> = {
    bandRidesUp: (
      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 10l7-7m0 0l7 7m-7-7v18" />
      </svg>
    ),
    bandTooTight: (
      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
      </svg>
    ),
    strapSlipping: (
      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
      </svg>
    ),
    cupOverflow: (
      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
      </svg>
    ),
    centerGoreFloat: (
      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
      </svg>
    ),
    wireWidth: (
      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 8h16M4 16h16" />
      </svg>
    ),
    wirePressure: (
      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M12 12h.01M12 14h.01M12 16h.01M12 18h.01" />
      </svg>
    ),
    wireDistortion: (
      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.121 14.121L19 19m-7-7l7-7m-7 7l-2.879 2.879M12 12L9.121 9.121m0 5.758a3 3 0 10-4.243-4.243 3 3 0 004.243 4.243z" />
      </svg>
    ),
    mobilityRestriction: (
      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 11.5V14m0-2.5v-6a1.5 1.5 0 113 0m-3 6a1.5 1.5 0 00-3 0v2a7.5 7.5 0 0015 0v-5a1.5 1.5 0 00-3 0m-6-3V11m0-5.5v-1a1.5 1.5 0 013 0v1m0 0V11m0-5.5a1.5 1.5 0 013 0v3m0 0V11" />
      </svg>
    ),
    unbalancedSupport: (
      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
      </svg>
    ),
    gapingArmholes: (
      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
      </svg>
    ),
    strapPlacement: (
      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
      </svg>
    )
  };

  return icons[signType] || defaultIcon;
};

const SizeCharts: React.FC = () => {
  const location = useLocation();
  const {
    metaData,
    heroData,
    tabsData,
    bandSizeChartData,
    cupSizeChartData,
    sisterSizesData,
    tipsData,
    measurementTipsData
  } = useTranslatedData();

  const [activeTab, setActiveTab] = useState<TabId>('international');
  const [showInches, setShowInches] = useState(true);
  const [selectedSize, setSelectedSize] = useState<string | null>(null);
  const [selectedSystem, setSelectedSystem] = useState<'US/UK' | 'EU' | 'FR'>('US/UK');

  const tabs: Tab[] = [
    { id: 'international', label: tabsData.international },
    { id: 'sister', label: tabsData.sister },
    { id: 'tips', label: tabsData.tips }
  ];

  // Size conversion functions
  const convertSize = (size: string, toSystem: 'US/UK' | 'EU' | 'FR'): string => {
    const [band, cup] = [parseInt(size.slice(0, 2)), size.slice(2)];
    
    // Using the exact mappings from sizingSisterData.ts
    const bandMappings: Record<string, { EU: number; FR: number }> = {
      '30': { EU: 65, FR: 80 },
      '32': { EU: 70, FR: 85 },
      '34': { EU: 75, FR: 90 },
      '36': { EU: 80, FR: 95 }
    };

    const cupMappings: Record<string, { EU: string; FR: string }> = {
      'A': { EU: 'A', FR: 'A' },
      'B': { EU: 'B', FR: 'B' },
      'C': { EU: 'C', FR: 'C' },
      'D': { EU: 'D', FR: 'D' },
      'DD': { EU: 'E', FR: 'E' }
    };
    
    switch (toSystem) {
      case 'US/UK':
        return size;
      case 'EU':
        return `${bandMappings[band.toString()]?.EU || band}${cupMappings[cup]?.EU || cup}`;
      case 'FR':
        return `${bandMappings[band.toString()]?.FR || band}${cupMappings[cup]?.FR || cup}`;
      default:
        return size;
    }
  };

  const convertToUS = (size: string, fromSystem: 'US/UK' | 'EU' | 'FR'): string | null => {
    let band = '';
    let cup = '';
    
    // Reverse mappings based on sizingSisterData.ts
    const euToUS: Record<string, string> = {
      '65': '30', '70': '32', '75': '34', '80': '36'
    };
    
    const frToUS: Record<string, string> = {
      '80': '30', '85': '32', '90': '34', '95': '36'
    };
    
    switch (fromSystem) {
      case 'US/UK':
        return size;
      case 'EU':
        const matchEU = size.match(/(\d+)([A-E])/);
        if (!matchEU) return null;
        band = euToUS[matchEU[1]] || String(parseInt(matchEU[1]));
        cup = matchEU[2];
        break;
      case 'FR':
        const matchFR = size.match(/(\d+)([A-E])/);
        if (!matchFR) return null;
        band = frToUS[matchFR[1]] || String(parseInt(matchFR[1]));
        cup = matchFR[2];
        break;
    }
    
    const result = `${band}${cup}`;
    return result;
  };

  const convertCupEU = (cup: string): string => {
    if (cup === 'DD') return 'E';
    return cup;
  };

  const convertCupFR = (cup: string): string => {
    if (cup === 'DD') return 'E';
    return cup;
  };

  // Create band size conversion table from sizeConversions data
  const bandSizeTable = [
    {
      us: 26,
      uk: 26,
      eu: 55,
      fr_es: 70,
      it: -1,
      jp: 55,
      au_nz: 4,
      measurement: { inches: '26-27"', cm: '66-69 cm' }
    },
    {
      us: 28,
      uk: 28,
      eu: 60,
      fr_es: 75,
      it: 0,
      jp: 60,
      au_nz: 6,
      measurement: { inches: '28-29"', cm: '71-74 cm' }
    },
    {
      us: 30,
      uk: 30,
      eu: 65,
      fr_es: 80,
      it: 1,
      jp: 65,
      au_nz: 8,
      measurement: { inches: '30-31"', cm: '76-79 cm' }
    },
    {
      us: 32,
      uk: 32,
      eu: 70,
      fr_es: 85,
      it: 2,
      jp: 70,
      au_nz: 10,
      measurement: { inches: '32-33"', cm: '81-84 cm' }
    },
    {
      us: 34,
      uk: 34,
      eu: 75,
      fr_es: 90,
      it: 3,
      jp: 75,
      au_nz: 12,
      measurement: { inches: '34-35"', cm: '86-89 cm' }
    },
    {
      us: 36,
      uk: 36,
      eu: 80,
      fr_es: 95,
      it: 4,
      jp: 80,
      au_nz: 14,
      measurement: { inches: '36-37"', cm: '91-94 cm' }
    },
    {
      us: 38,
      uk: 38,
      eu: 85,
      fr_es: 100,
      it: 5,
      jp: 85,
      au_nz: 16,
      measurement: { inches: '38-39"', cm: '96-99 cm' }
    },
    {
      us: 40,
      uk: 40,
      eu: 90,
      fr_es: 105,
      it: 6,
      jp: 90,
      au_nz: 18,
      measurement: { inches: '40-41"', cm: '102-104 cm' }
    },
    {
      us: 42,
      uk: 42,
      eu: 95,
      fr_es: 110,
      it: 7,
      jp: 95,
      au_nz: 20,
      measurement: { inches: '42-43"', cm: '107-109 cm' }
    },
    {
      us: 44,
      uk: 44,
      eu: 100,
      fr_es: 115,
      it: 8,
      jp: 100,
      au_nz: 22,
      measurement: { inches: '44-45"', cm: '112-114 cm' }
    },
    {
      us: 46,
      uk: 46,
      eu: 105,
      fr_es: 120,
      it: 9,
      jp: 105,
      au_nz: 24,
      measurement: { inches: '46-47"', cm: '117-119 cm' }
    },
    {
      us: 48,
      uk: 48,
      eu: 110,
      fr_es: 125,
      it: 10,
      jp: 110,
      au_nz: 26,
      measurement: { inches: '48-49"', cm: '122-124 cm' }
    },
    {
      us: 50,
      uk: 50,
      eu: 115,
      fr_es: 130,
      it: 11,
      jp: 115,
      au_nz: 28,
      measurement: { inches: '50-51"', cm: '127-129 cm' }
    },
    {
      us: 52,
      uk: 52,
      eu: 120,
      fr_es: 135,
      it: 12,
      jp: 120,
      au_nz: 30,
      measurement: { inches: '52-53"', cm: '132-134 cm' }
    },
    {
      us: 54,
      uk: 54,
      eu: 125,
      fr_es: 140,
      it: 13,
      jp: 125,
      au_nz: 32,
      measurement: { inches: '54-55"', cm: '137-139 cm' }
    },
    {
      us: 56,
      uk: 56,
      eu: 130,
      fr_es: 145,
      it: 14,
      jp: 130,
      au_nz: 34,
      measurement: { inches: '56-57"', cm: '142-144 cm' }
    },
    {
      us: 58,
      uk: 58,
      eu: 135,
      fr_es: 150,
      it: 15,
      jp: 135,
      au_nz: 36,
      measurement: { inches: '58-59"', cm: '147-149 cm' }
    },
    {
      us: 60,
      uk: 60,
      eu: 140,
      fr_es: 155,
      it: 16,
      jp: 140,
      au_nz: 38,
      measurement: { inches: '60-61"', cm: '152-154 cm' }
    }
  ];

  // Create cup size conversion table
  const cupSizeTable = cupSizes.US.map((usSize, index) => ({
    us: usSize,
    uk: cupSizes.UK[index] || '-',
    eu: convertCupEU(cupSizes.EU[index] || '-'),
    fr_es: convertCupFR(cupSizes.FR_ES[index] || '-'),
    it: cupSizes.IT[index] || '-',
    jp: cupSizes.JP[index] || '-',
    au_nz: cupSizes.AU_NZ[index] || '-',
    difference: getMeasurementRange(index + 0.5)
  }));

  // Sister sizes mapping
  const sisterSizes: Record<string, [string, string]> = {
    "30A": ["28B", "32AA"],
    "30B": ["28C", "32A"],
    "30C": ["28D", "32B"],
    "30D": ["28DD", "32C"],
    "30DD": ["28E", "32D"],
    "32A": ["30B", "34AA"],
    "32B": ["30C", "34A"],
    "32C": ["30D", "34B"],
    "32D": ["30DD", "34C"],
    "32DD": ["30E", "34D"],
    "34A": ["32B", "36AA"],
    "34B": ["32C", "36A"],
    "34C": ["32D", "36B"],
    "34D": ["32DD", "36C"],
    "34DD": ["32E", "36D"],
    "36A": ["34B", "38AA"],
    "36B": ["34C", "38A"],
    "36C": ["34D", "38B"],
    "36D": ["34DD", "38C"],
    "36DD": ["34E", "38D"]
  };

  const handleTabChange = useCallback((tab: TabId) => {
    setActiveTab(tab);
  }, []);

  const handleSizeSelect = useCallback((size: string) => {
    setSelectedSize(size);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <link rel="canonical" href={getCanonicalUrl(location.pathname)} />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-pink-600">
            {heroData.title}
          </h1>
          <p className="text-gray-600 text-xl max-w-3xl mx-auto">
            {heroData.subtitle}
          </p>
        </motion.div>

        {/* Tab Navigation */}
        <div className="mb-8">
          <div className="border-b border-gray-200">
            <nav className="flex justify-center" aria-label="Tabs">
              <div className="flex w-full max-w-2xl">
                {tabs.map((tab) => (
                  <button
                    key={tab.id}
                    onClick={() => handleTabChange(tab.id)}
                    className={`
                      flex-1 py-3 px-2 border-b-2 font-medium text-sm transition-colors duration-200
                      text-center leading-tight min-h-[56px] flex items-center justify-center
                      ${activeTab === tab.id
                        ? 'border-purple-600 text-purple-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                      }
                    `}
                  >
                    <span className="block px-1">{tab.label}</span>
                  </button>
                ))}
              </div>
            </nav>
          </div>
        </div>

        <div className="mt-8">
          {activeTab === 'international' && (
            <div className="space-y-12">
              <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                <div className="p-4 sm:p-8">
                  <div className="flex flex-wrap justify-between items-center gap-4 mb-8">
                    <h2 className="text-xl font-bold text-purple-800 flex-1 min-w-[200px]">{bandSizeChartData.title}</h2>
                    <button
                      onClick={() => setShowInches(!showInches)}
                      className="shrink-0 px-4 py-2 text-sm font-medium rounded-xl bg-purple-100 text-purple-600 hover:bg-purple-200 transition-colors flex items-center gap-2 ml-auto"
                    >
                      <span>{showInches ? bandSizeChartData.toggleUnits.showCm : bandSizeChartData.toggleUnits.showInches}</span>
                    </button>
                  </div>
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-100">
                      <thead>
                        <tr>
                          <th className="py-3 sm:py-4 px-2 sm:px-6 text-center text-purple-600 text-xs sm:text-sm font-semibold border-b-2 border-purple-100">{bandSizeChartData.headers.us}</th>
                          <th className="py-3 sm:py-4 px-2 sm:px-6 text-center text-purple-600 text-xs sm:text-sm font-semibold border-b-2 border-purple-100">{bandSizeChartData.headers.uk}</th>
                          <th className="py-3 sm:py-4 px-2 sm:px-6 text-center text-purple-600 text-xs sm:text-sm font-semibold border-b-2 border-purple-100">{bandSizeChartData.headers.eu}</th>
                          <th className="py-3 sm:py-4 px-2 sm:px-6 text-center text-purple-600 text-xs sm:text-sm font-semibold border-b-2 border-purple-100">{bandSizeChartData.headers.frBeEs}</th>
                          <th className="py-3 sm:py-4 px-2 sm:px-6 text-center text-purple-600 text-xs sm:text-sm font-semibold border-b-2 border-purple-100">{bandSizeChartData.headers.it}</th>
                          <th className="py-3 sm:py-4 px-2 sm:px-6 text-center text-purple-600 text-xs sm:text-sm font-semibold border-b-2 border-purple-100">{bandSizeChartData.headers.auNz}</th>
                          <th className="py-3 sm:py-4 px-2 sm:px-6 text-center text-purple-600 text-xs sm:text-sm font-semibold border-b-2 border-purple-100">{bandSizeChartData.headers.measurement}</th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-100">
                        {bandSizeTable.map((row, i) => (
                          <tr key={i} className={`hover:bg-purple-50 transition-colors ${i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
                            <td className="py-4 px-6 text-center text-sm text-gray-900 font-medium">{row.us}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900">{row.uk}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900">{row.eu}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900">{row.fr_es}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900">{row.it}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900">{row.au_nz}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900 font-medium">
                              {showInches ? row.measurement.inches : row.measurement.cm}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-xl shadow overflow-hidden">
                <div className="p-4 sm:p-8">
                  <div className="flex flex-wrap justify-between items-center gap-4 mb-8">
                    <h2 className="text-xl font-bold text-purple-800 flex-1 min-w-[200px]">{cupSizeChartData.title}</h2>
                    <button
                      onClick={() => setShowInches(!showInches)}
                      className="shrink-0 px-4 py-2 text-sm font-medium rounded-xl bg-purple-100 text-purple-600 hover:bg-purple-200 transition-colors flex items-center gap-2 ml-auto"
                    >
                      <span>{showInches ? cupSizeChartData.toggleUnits.showCm : cupSizeChartData.toggleUnits.showInches}</span>
                    </button>
                  </div>
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-100">
                      <thead>
                        <tr>
                          <th className="py-3 sm:py-4 px-2 sm:px-6 text-center text-purple-600 text-xs sm:text-sm font-semibold border-b-2 border-purple-100">{cupSizeChartData.headers.usUk}</th>
                          <th className="py-3 sm:py-4 px-2 sm:px-6 text-center text-purple-600 text-xs sm:text-sm font-semibold border-b-2 border-purple-100">{cupSizeChartData.headers.eu}</th>
                          <th className="py-3 sm:py-4 px-2 sm:px-6 text-center text-purple-600 text-xs sm:text-sm font-semibold border-b-2 border-purple-100">{cupSizeChartData.headers.frEs}</th>
                          <th className="py-3 sm:py-4 px-2 sm:px-6 text-center text-purple-600 text-xs sm:text-sm font-semibold border-b-2 border-purple-100">{cupSizeChartData.headers.it}</th>
                          <th className="py-3 sm:py-4 px-2 sm:px-6 text-center text-purple-600 text-xs sm:text-sm font-semibold border-b-2 border-purple-100">{cupSizeChartData.headers.jp}</th>
                          <th className="py-3 sm:py-4 px-2 sm:px-6 text-center text-purple-600 text-xs sm:text-sm font-semibold border-b-2 border-purple-100">{cupSizeChartData.headers.auNz}</th>
                          <th className="py-3 sm:py-4 px-2 sm:px-6 text-center text-purple-600 text-xs sm:text-sm font-semibold border-b-2 border-purple-100">{cupSizeChartData.headers.difference}</th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-100">
                        {cupSizeTable.map((row, i) => (
                          <tr key={i} className={`hover:bg-purple-50 transition-colors ${i % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
                            <td className="py-4 px-6 text-center text-sm text-gray-900 font-medium">{row.us}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900">{row.eu}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900">{row.fr_es}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900">{row.it}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900">{row.jp}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900">{row.au_nz}</td>
                            <td className="py-4 px-6 text-center text-sm text-gray-900 font-medium">
                              {showInches ? row.difference.inches : row.difference.cm}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'sister' && (
            <div className="space-y-12">
              <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                <div className="p-8">
                  <h2 className="text-2xl font-bold text-purple-800 mb-4">{sisterSizesData.title}</h2>
                  <p className="text-gray-600 mb-8">{sisterSizesData.description}</p>

                  <div className="grid md:grid-cols-2 gap-8">
                    <div className="bg-purple-50 rounded-xl p-6">
                      <div className="flex justify-between items-center mb-6">
                        <h3 className="text-lg font-semibold text-purple-900">{sisterSizesData.currentSize}</h3>
                        <div className="flex gap-2">
                          {['US/UK', 'EU', 'FR'].map((system) => (
                            <button
                              key={system}
                              onClick={() => {
                                setSelectedSystem(system as 'US/UK' | 'EU' | 'FR');
                                setSelectedSize(null);
                              }}
                              className={`px-3 py-1.5 text-sm font-medium rounded-lg transition-all duration-200 ${
                                selectedSystem === system
                                  ? 'bg-purple-600 text-white shadow-sm'
                                  : 'bg-white hover:bg-purple-100 text-gray-900'
                              }`}
                            >
                              {system}
                            </button>
                          ))}
                        </div>
                      </div>
                      <div className="grid grid-cols-4 sm:grid-cols-5 gap-1 sm:gap-2">
                        {[
                          '30A', '30B', '30C', '30D', '30DD',
                          '32A', '32B', '32C', '32D', '32DD',
                          '34A', '34B', '34C', '34D', '34DD',
                          '36A', '36B', '36C', '36D', '36DD'
                        ].map((size) => (
                          <button
                            key={size}
                            onClick={() => handleSizeSelect(size)}
                            className={`p-1.5 sm:p-2 mb-1 text-xs sm:text-sm font-medium rounded-lg sm:rounded-xl transition-all duration-200 text-center ${
                              selectedSize === size
                                ? 'bg-purple-600 text-white shadow-md'
                                : 'bg-white hover:bg-purple-100 text-gray-900'
                            }`}
                          >
                            {selectedSystem === 'US/UK' 
                              ? size 
                              : convertSize(size, selectedSystem)}
                          </button>
                        ))}
                      </div>
                    </div>

                    <div className="bg-gradient-to-br from-purple-50 to-pink-50 p-6 rounded-xl shadow-sm">
                      <h3 className="text-lg font-semibold text-purple-900 mb-4">{sisterSizesData.title}</h3>
                      {selectedSize ? (
                        <div className="space-y-4">
                          <div className="flex items-center justify-center space-x-2 sm:space-x-4">
                            <div className="text-center border border-purple-300 bg-white rounded-lg sm:rounded-xl p-2 sm:p-3 w-24 sm:w-28">
                              <div className="text-xs sm:text-sm font-medium text-purple-700">{sisterSizesData.results.smallerBand.title}</div>
                              <div className="text-lg sm:text-xl font-semibold text-purple-800 mt-2 sm:mt-3 min-h-[28px] sm:min-h-[32px]">
                                {convertSize(sisterSizes[selectedSize][0], selectedSystem)}
                              </div>
                            </div>
                            <div className="text-center border border-purple-300 bg-white rounded-lg sm:rounded-xl p-3 sm:p-5 w-28 sm:w-32">
                              <div className="text-xs sm:text-sm font-medium text-purple-700">{sisterSizesData.results.currentSize.title}</div>
                              <div className="text-xl sm:text-3xl font-bold text-purple-900 mt-2 sm:mt-3 min-h-[32px] sm:min-h-[40px]">
                                {convertSize(selectedSize, selectedSystem)}
                              </div>
                            </div>
                            <div className="text-center border border-purple-300 bg-white rounded-lg sm:rounded-xl p-2 sm:p-3 w-24 sm:w-28">
                              <div className="text-xs sm:text-sm font-medium text-purple-700">{sisterSizesData.results.largerBand.title}</div>
                              <div className="text-lg sm:text-xl font-semibold text-purple-800 mt-2 sm:mt-3 min-h-[28px] sm:min-h-[32px]">
                                {convertSize(sisterSizes[selectedSize][1], selectedSystem)}
                              </div>
                            </div>
                          </div>
                          <p className="text-sm text-purple-700 text-center mt-6">{sisterSizesData.results.tip}</p>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center h-32">
                          <p className="text-purple-700 text-center">{sisterSizesData.results.selectPrompt}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center">
                <Link
                  to={routes.sisterSizes[getLanguageFromPath(location.pathname)]}
                  className="inline-flex items-center px-8 py-4 text-lg font-medium text-white bg-purple-600 rounded-xl hover:bg-purple-700 transition-colors shadow-lg hover:shadow-xl"
                >
                  {sisterSizesData.fullCalculator}
                  <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                  </svg>
                </Link>
                <p className="text-sm text-gray-500 mt-4">{sisterSizesData.fullCalculatorDescription}</p>
              </div>
            </div>
          )}

          {activeTab === 'tips' && (
            <div className="space-y-8">
              <div className="grid md:grid-cols-2 gap-4 sm:gap-8">
                <div className="bg-white rounded-xl shadow overflow-hidden">
                  <div className="bg-purple-50 px-4 sm:px-8 py-4 sm:py-6 border-b border-purple-100">
                    <h3 className="text-lg sm:text-xl font-semibold text-purple-900 leading-tight">{measurementTipsData.bandMeasurement.title}</h3>
                  </div>
                  <div className="p-4 sm:p-8">
                    <ul className="space-y-4 sm:space-y-6">
                      {Object.entries(measurementTipsData.bandMeasurement.tips).map(([key, tip], index) => (
                        <li key={key} className="flex items-start gap-3 sm:gap-4">
                          <span className="flex-shrink-0 w-6 h-6 sm:w-8 sm:h-8 flex items-center justify-center rounded-full bg-purple-100 text-purple-600 text-sm sm:text-base">{index + 1}</span>
                          <div>
                            <p className="font-medium text-gray-900 text-sm sm:text-base">{tip.title}</p>
                            <p className="mt-1 text-gray-600 text-sm sm:text-base">{tip.description}</p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="bg-white rounded-xl shadow overflow-hidden">
                  <div className="bg-purple-50 px-4 sm:px-8 py-4 sm:py-6 border-b border-purple-100">
                    <h3 className="text-lg sm:text-xl font-semibold text-purple-900 leading-tight">{measurementTipsData.cupMeasurement.title}</h3>
                  </div>
                  <div className="p-4 sm:p-8">
                    <ul className="space-y-4 sm:space-y-6">
                      {Object.entries(measurementTipsData.cupMeasurement.tips).map(([key, tip], index) => (
                        <li key={key} className="flex items-start gap-3 sm:gap-4">
                          <span className="flex-shrink-0 w-6 h-6 sm:w-8 sm:h-8 flex items-center justify-center rounded-full bg-purple-100 text-purple-600 text-sm sm:text-base">{index + 1}</span>
                          <div>
                            <p className="font-medium text-gray-900 text-sm sm:text-base">{tip.title}</p>
                            <p className="mt-1 text-gray-600 text-sm sm:text-base">{tip.description}</p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-xl shadow overflow-hidden">
                <div className="bg-red-50 px-4 sm:px-8 py-4 sm:py-6 border-b border-red-100">
                  <h3 className="text-lg sm:text-xl font-semibold text-red-900 leading-tight">{tipsData.poorFit.title}</h3>
                  <p className="mt-2 text-red-700 text-sm">{tipsData.poorFit.subtitle}</p>
                </div>
                <div className="p-4 sm:p-8">
                  {Object.entries(tipsData.poorFit.categories).map(([categoryKey, category]) => (
                    <div key={categoryKey} className="mb-8 last:mb-0">
                      <h4 className="text-lg font-semibold text-gray-900 mb-4">{category.title}</h4>
                      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        {Object.entries(category.signs).map(([signKey, sign]) => (
                          <div key={signKey} className="bg-white rounded-lg border border-red-100 p-4 hover:shadow-md transition-shadow">
                            <div className="flex items-start gap-3">
                              <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center rounded-full bg-red-100 text-red-600">
                                {getSignIcon(signKey)}
                              </div>
                              <div className="flex-1">
                                <h5 className="font-semibold text-gray-900 text-sm sm:text-base leading-tight mb-2">{sign.title}</h5>
                                <p className="text-gray-600 text-sm leading-relaxed mb-2">{sign.description}</p>
                                <p className="text-purple-600 text-sm font-medium italic">Solution: {sign.solution}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="mt-8 sm:mt-12 text-center">
                <Link
                  to="/bra-size-measuring-guide"
                  className="inline-flex items-center px-6 sm:px-8 py-3 sm:py-4 text-base sm:text-lg font-medium text-white bg-purple-500 rounded-xl hover:bg-purple-400 transition-colors shadow hover:shadow-md"
                >
                  {tipsData.viewGuide}
                  <svg className="w-4 h-4 sm:w-5 sm:h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                  </svg>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SizeCharts;
